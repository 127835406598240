import { call, put } from 'redux-saga/effects'
import { backofficeCommandURL } from '../config'
import { AddCustomer, AddCustomerSubnode, AddMachineToCustomer, ChangeNodeAddress, CustomerActionTypes, RemoveAddressFromNode, RemoveCustomer, RemoveMachineFromNode, RenameNode, UnhideCustomer } from '../state/actionTypes/customer'
import MachineActionTypes, { RenameMachine } from '../state/actionTypes/machine'
import { UserActionTypes, AddUser, RemoveUser, UpdateUser, AssignOrganisations } from '../state/actionTypes/user'
import { getStore } from '../state/configureStore'
import { postAsync } from './ajax'
import { sec } from './auth0Helper'

type CommandResponse = {
  message:string,
  command:any,
  data:any
}

export const PostToBackoffice = (path: string, payload:any): Promise<CommandResponse> => {
  const url = `${backofficeCommandURL()}/${path}`;

  console.info('Posting', path, payload);

  return sec.getAccessTokenSilently()
  .then(token => postAsync(url, JSON.stringify(payload), token))
  .then(r => r.json())
  .then(json => {
    console.log('response', json)
    return json;
  })
  .then(data => data as CommandResponse)
}

export function* assignOrganisations(action:AssignOrganisations ){
  let url = `${backofficeCommandURL()}/cmd/user/assign-organisation`

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify("command"), accessToken)
}

export function* postUser(action: AddUser) {
  let url = `${backofficeCommandURL()}/cmd/user/create`

  if(action.user.organisations){
    
  }

  const command =
  {
    id: action.user.id,
    email: action.user.email,
    givenName: action.user.givenName,
    familyName: action.user.familyName,
    name: action.user.name,
    organisations: action.user.organisations
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    try {
      response.json().then(m => {
        getStore().dispatch({ type: 'USER_ADDED', user: { ...action.user, userId: action.user.id } } as UserActionTypes)
      })

    } catch (e) {
      console.error('Error trying to process response Json:', e)
      yield put({ type: 'ADDING_USER_FAILED', user: action.user } as UserActionTypes)
    }
  }
  else {
    yield put({ type: 'ADDING_USER_FAILED', user: action.user } as UserActionTypes)
  }
}


export function* postUpdatedUser(action: UpdateUser) {
  let url = `${backofficeCommandURL()}/cmd/user/update`

  const command =
  {
    id: action.userData.userId,
    email: action.userData.email,
    givenName: action.userData.givenName,
    familyName: action.userData.familyName,
    name: action.userData.name,
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'USER_UPDATED', userData: action.userData } as UserActionTypes)
  }
  else {
    yield put({ type: 'UPDATING_USER_FAILED', userId: action.userData.userId } as UserActionTypes)
  }
}

export function* postRemoveUser(action: RemoveUser) {
  let url = `${backofficeCommandURL()}/cmd/user/remove`

  const command =
  {
    id: action.userId,
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'USER_REMOVED', userId: action.userId } as UserActionTypes)
  }
  else {
    yield put({ type: 'REMOVING_USER_FAILED', userId: action.userId } as UserActionTypes)
  }
}


export function* postCustomer(action: AddCustomer) {
  let url = `${backofficeCommandURL()}/cmd/company/create`

  const command =
  {
    id: action.customer.id,
    name: action.customer.name,
    invoice_AddressLine1: action.customer.invoice_AddressLine1,
    invoice_AddressLine2: action.customer.invoice_AddressLine2,
    invoice_PostalCode: action.customer.invoice_PostalCode,
    invoice_Locality: action.customer.invoice_locality,
    visiting_AddressLine1: action.customer.visiting_AddressLine1,
    visiting_AddressLine2: action.customer.visiting_AddressLine2,
    visiting_PostalCode: action.customer.visiting_PostalCode,
    visiting_Locality: action.customer.visiting_Locality,
    // location_Longitude: action.customer.locationLongitude,
    // location_Latitude: action.customer.locationLatitude,
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    try {
      response.json().then(m => {
        getStore().dispatch({ type: 'CUSTOMER_ADDED', id: action.customer.id, customer: action.customer } as CustomerActionTypes)
      })

    } catch (e) {
      console.error('Error trying to process response Json:', e)
      yield put({ type: 'ADDING_CUSTOMER_FAILED', customer: action.customer } as CustomerActionTypes)
    }
  }
  else {
    yield put({ type: 'ADDING_CUSTOMER_FAILED', customer: action.customer } as CustomerActionTypes)
  }
}

export function* postRemoveCustomer(action: RemoveCustomer) {
  let url = `${backofficeCommandURL()}/cmd/node/hide`

  const command =
  {
    id: action.nodeId,
    reason: action.reason ?? null
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'CUSTOMER_REMOVED', nodeId: action.nodeId, reason: action.reason } as CustomerActionTypes)
  }
  else {
    yield put({ type: 'REMOVING_CUSTOMER_FAILED', nodeId: action.nodeId } as CustomerActionTypes)
  }
}

export function* postUnhideCustomer(action: UnhideCustomer) {
  let url = `${backofficeCommandURL()}/cmd/node/show`

  const command =
  {
    id: action.nodeId,
    reason: action.reason ?? null
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'CUSTOMER_UNHIDDEN', nodeId: action.nodeId, reason: action.reason } as CustomerActionTypes)
  }
  else {
    yield put({ type: 'UNHIDING_CUSTOMER_FAILED', nodeId: action.nodeId } as CustomerActionTypes)
  }
}

export function* postCustomerSubnode(action: AddCustomerSubnode) {
  let url = `${backofficeCommandURL()}/cmd/store/create`

  const command =
  {
    name: action.customerSubnode.name,
    invoice_AddressLine1: action.customerSubnode.invoice_AddressLine1,
    invoice_AddressLine2: action.customerSubnode.invoice_AddressLine2,
    invoice_PostalCode: action.customerSubnode.invoice_PostalCode,
    invoice_Locality: action.customerSubnode.invoice_Locality,
    visiting_AddressLine1: action.customerSubnode.visiting_AddressLine1,
    visiting_AddressLine2: action.customerSubnode.visiting_AddressLine2,
    visiting_PostalCode: action.customerSubnode.visiting_PostalCode,
    visiting_Locality: action.customerSubnode.visiting_Locality,
    location_Longitude: 0,//action.customerSubnode.location_Longitude,
    location_Latitude: 0,//action.customerSubnode.location_Latitude,
    companyId: action.customerSubnode.parentId
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    try {
      response.json().then(m => {
        const id: string = m?.Message as string
        getStore().dispatch({ type: 'CUSTOMER_SUBNODE_ADDED', id: id, customerSubnode: action.customerSubnode } as CustomerActionTypes)
      })

    } catch (e) {
      console.error('Error trying to process response Json:', e)
      yield put({ type: 'ADDING_CUSTOMER_SUBNODE_FAILED', customerSubnode: action.customerSubnode } as CustomerActionTypes)
    }
  }
  else {
    yield put({ type: 'ADDING_CUSTOMER_SUBNODE_FAILED', customerSubnode: action.customerSubnode } as CustomerActionTypes)
  }
}

export function* postMachineToCustomer(action: AddMachineToCustomer) {
  let url = `${backofficeCommandURL()}/cmd/machine/connect`

  const command =
  {
    id: action.data.machine.serialId,
    nodeId: action.data.customerId
  }

  if (!command.id || !command.nodeId) {
    console.error('Could not send command. Missing information:', 'id', command.id, 'nodeId', command.nodeId)
    yield put({ type: 'ADDING_MACHINE_TO_CUSTOMER_FAILED', data: action.data } as CustomerActionTypes)
    return
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'MACHINE_ADDED_TO_CUSTOMER', data: action.data } as CustomerActionTypes)
  }
  else {
    yield put({ type: 'ADDING_MACHINE_TO_CUSTOMER_FAILED', data: action.data } as CustomerActionTypes)
  }
}

export function* postUpdatedMachineName(action: RenameMachine) {
  let url = `${backofficeCommandURL()}/cmd/machine/rename`

  const command =
  {
    id: action.serialId,
    name: action.newName
  }

  if (!command.id || !command.name) {
    console.error('Could not send command. Missing information:', 'id', command.id, 'name', command.name)
    yield put({ type: 'RENAMING_MACHINE_FAILED', serialId: action.serialId, newName: action.newName } as MachineActionTypes)
    return
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'MACHINE_RENAMED', serialId: action.serialId, newName: action.newName } as MachineActionTypes)
  }
  else {
    yield put({ type: 'RENAMING_MACHINE_FAILED', serialId: action.serialId, newName: action.newName } as MachineActionTypes)
  }
}

export function* postDisconnectMachineFromNode(action: RemoveMachineFromNode) {
  let url = `${backofficeCommandURL()}/cmd/machine/disconnect`

  const command =
  {
    id: action.serialId,
    nodeId: action.nodeId
  }

  if (!command.id || !command.nodeId) {
    console.error('Could not send command. Missing information:', 'serialId', command.id, 'nodeId', command.nodeId)
    yield put({ type: 'REMOVING_MACHINE_FROM_NODE_FAILED', serialId: action.serialId, nodeId: action.nodeId } as CustomerActionTypes)
    return
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'MACHINE_REMOVED_FROM_NODE', serialId: action.serialId, nodeId: action.nodeId } as CustomerActionTypes)
  }
  else {
    yield put({ type: 'REMOVING_MACHINE_FROM_NODE_FAILED', serialId: action.serialId, nodeId: action.nodeId } as CustomerActionTypes)
  }
}

export function* postRemoveAddressFromNode(action: RemoveAddressFromNode) {
  let url = `${backofficeCommandURL()}/cmd/node/address/${action.addressType === 'visiting' ? 'visit' : 'invoice'}/remove`

  const command =
  {
    id: action.nodeId,
  }

  if (!command.id) {
    yield put({ type: 'REMOVING_ADDRESS_FROM_NODE_FAILED', nodeId: action.nodeId, addressType: action.addressType, message: 'Could not send command. Missing information: nodeId = ' + command.id } as CustomerActionTypes)
    return
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'ADDRESS_REMOVED_FROM_NODE', nodeId: action.nodeId, addressType: action.addressType } as CustomerActionTypes)
  }
  else {
    try {
      response.json().then(m => {
        const message: string = m?.Message as string
        getStore().dispatch({ type: 'REMOVING_ADDRESS_FROM_NODE_FAILED', nodeId: action.nodeId, addressType: action.addressType, message: message } as CustomerActionTypes)
      })

    } catch (e) {
      yield put({ type: 'REMOVING_ADDRESS_FROM_NODE_FAILED', nodeId: action.nodeId, addressType: action.addressType, message: 'Error trying to process response Json:' + e } as CustomerActionTypes)
    }
  }
}

export function* postChangeNodeAddress(action: ChangeNodeAddress) {
  let url = `${backofficeCommandURL()}/cmd/node/address/${action.newAddress.addressType === 'visiting' ? 'visit' : 'invoice'}/change`

  const command =
  {
    id: action.id,
    addressLine1: action.newAddress.addressLine1,
    addressLine2: action.newAddress.addressLine2 ?? null,
    postalCode: action.newAddress.postalCode,
    locality: action.newAddress.locality,
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'NODE_ADDRESS_CHANGED', id: action.id, newAddress: action.newAddress } as CustomerActionTypes)
  }
  else {
    try {
      response.json().then(m => {
        const message: string = m?.Message as string
        getStore().dispatch({ type: 'CHANGING_NODE_ADDRESS_FAILED', id: action.id, newAddress: action.newAddress, message: message } as CustomerActionTypes)
      }).catch(e => {
        getStore().dispatch({ type: 'CHANGING_NODE_ADDRESS_FAILED', id: action.id, newAddress: action.newAddress, message: e } as CustomerActionTypes)
      })

    } catch (e) {
      console.log('error', e)
      yield put({ type: 'CHANGING_NODE_ADDRESS_FAILED', id: action.id, newAddress: action.newAddress, message: 'Error trying to process response Json:' + e } as CustomerActionTypes)
    }
  }
}

export function* postRenameNode(action: RenameNode) {
  let url = `${backofficeCommandURL()}/cmd/node/name/change`

  const command =
  {
    id: action.id,
    name: action.newName
  }

  const accessToken: string = yield sec.getAccessTokenSilently()
  const response: Response = yield call(postAsync, url, JSON.stringify(command), accessToken)

  if (response && response.ok) {
    yield put({ type: 'NODE_RENAMED', id: action.id, newName: action.newName } as CustomerActionTypes)
  }
  else {
    try {
      response.json().then(m => {
        const message: string = m?.Message as string
        getStore().dispatch({ type: 'RENAMING_NODE_FAILED', id: action.id, newName: action.newName, message: message } as CustomerActionTypes)
      }).catch(e => {
        getStore().dispatch({ type: 'RENAMING_NODE_FAILED', id: action.id, newName: action.newName, message: e } as CustomerActionTypes)
      })

    } catch (e) {
      console.log('error', e)
      getStore().dispatch({ type: 'RENAMING_NODE_FAILED', id: action.id, newName: action.newName, message: 'Error trying to process response Json:' + e } as CustomerActionTypes)
    }
  }
}



