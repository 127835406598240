import { Typography } from '@mui/material'
import React from 'react'

export type TextProps = {
  children?: React.ReactNode,
  center?: boolean,
  description?: boolean
}

export const Text =  ({ children, center, description } : TextProps) : React.ReactElement =>  {
  return (
    <Typography
      sx={[
        center && { textAlign: "center" },
        description && { color: "#808080" },
      ]}
      variant="body1"
    >
      {children}
    </Typography>
  );
}

export const KpiText = ({ children, center, description } : TextProps) : React.ReactElement =>  {
  return (
    <Typography
      sx={[
        center && { textAlign: "center" },
       { textTransform: "uppercase"},
        description && { color: "#808080", fontWeight: 600 },
      ]}
      variant="body1"
    >
      {children}
    </Typography>
  );
}
