import { DateTime } from "luxon";
import { AddCustomerSubnodeType, AddCustomerType, NodeAddressType, AddMachineToCustomerType, ADD_MACHINE_TO_CUSTOMER, CustomerActionTypes } from "../actionTypes/customer";

export const fetchCustomers = (): CustomerActionTypes => ({
  type: 'FETCH_CUSTOMERS'
})

export const fetchCustomer = (id: string): CustomerActionTypes => ({
  type: 'FETCH_CUSTOMER',
  id,
})

export const addCustomer = (customerData: AddCustomerType): CustomerActionTypes => ({
  type: 'ADD_CUSTOMER',
  customer: customerData
})

export const addCustomerSubnode = (customerSubnodeData: AddCustomerSubnodeType): CustomerActionTypes => ({
  type: 'ADD_CUSTOMER_SUBNODE',
  customerSubnode: customerSubnodeData
})

export const addMachineToCustomer = (data: AddMachineToCustomerType): CustomerActionTypes => ({
  type: ADD_MACHINE_TO_CUSTOMER,
  data: data
})

export const removeMachineFromCustomer = (serialId: string, nodeId: string): CustomerActionTypes => ({
  type: 'REMOVE_MACHINE_FROM_NODE',
  serialId: serialId,
  nodeId: nodeId
})

export const fetchCustomerInvoice = (nodeId: string, from: DateTime, to: DateTime): CustomerActionTypes => ({
  type: 'FETCH_CUSTOMER_INVOICE',
  nodeId: nodeId,
  from: from,
  to: to
})

export const removeCustomer = (nodeId: string, reason?: string): CustomerActionTypes => ({
  type: 'REMOVE_CUSTOMER',
  nodeId: nodeId,
  reason: reason,
})

export const unhideCustomer = (nodeId: string, reason?: string): CustomerActionTypes => ({
  type: 'UNHIDE_CUSTOMER',
  nodeId: nodeId,
  reason: reason,
})

export const removeAddressFromNode = (nodeId: string, addressType: 'invoice' | 'visiting'): CustomerActionTypes => ({
  type: 'REMOVE_ADDRESS_FROM_NODE',
  nodeId: nodeId,
  addressType: addressType
})

export const addressRemovedFromNode = (nodeId: string, addressType: 'invoice' | 'visiting'): CustomerActionTypes => ({
  type: 'ADDRESS_REMOVED_FROM_NODE',
  nodeId: nodeId,
  addressType: addressType
})

export const removingAddressFromNodeFailed = (nodeId: string, addressType: 'invoice' | 'visiting', message: string): CustomerActionTypes => ({
  type: 'REMOVING_ADDRESS_FROM_NODE_FAILED',
  nodeId: nodeId,
  addressType: addressType,
  message: message
})

export const changeNodeAddress = (nodeId: string, newAddress: NodeAddressType): CustomerActionTypes => ({
  type: 'CHANGE_NODE_ADDRESS',
  id: nodeId,
  newAddress: newAddress
})

export const renameNode = (nodeId: string, newName: string): CustomerActionTypes => ({
  type: 'RENAME_NODE',
  id: nodeId,
  newName: newName
})
