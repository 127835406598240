import { useEffect, useState } from "react"
import { GetCommandResponse, useCommandBackend } from "../../../utils/requestHelpers"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React from "react";
import { IconButton } from "@mui/material";
import { PriceModelIdentity } from "./types";
import { ComponentCallback } from "../../types";
import { CommandBase } from "../../../common-types/commandingTypes";

interface RemovePriceModelCommand extends CommandBase {
    $schemaId: "RemovePriceModel"
    validFrom: string
    id: string
}

export const PriceModelRemoveButton = ({nodeId, fromDate, onDeleted}:{nodeId: string, fromDate: string, onDeleted: ComponentCallback<PriceModelIdentity>}) => {
    const [enabled, setEnabled] = useState<boolean>(false)
    const [command, setCommand] = useState<RemovePriceModelCommand | undefined>()
    const commandApi = useCommandBackend("Backoffice")

    useEffect(() => {
        commandApi.Get<GetCommandResponse<RemovePriceModelCommand>>(`/cmd/node/price-model/delete/${nodeId}/${fromDate}-01`)
            .Data(c => {
                setEnabled(!c.command)
                setCommand(c.command)
            })
            .Empty(() => setEnabled(false))
            .Rejected(r => setEnabled(false))
    }, [nodeId, fromDate])

    const deletePriceModel = () => {
        if(command)
            commandApi.PostCommand(`/cmd/node/price-model/delete`, command)
                .Data((c) => onDeleted({...c.command, nodeId: c.command.id}))
    }


    return (
        <IconButton
            disabled={!enabled}
            onClick={deletePriceModel}
        >
            <DeleteForeverIcon  />
        </IconButton>
    )
}
