import React from "react"
import { DeleteModal } from "../../../../components/molecules/DeleteModal"
import { Typography } from "@mui/material"
import { AddressTypes, RemoveAddressCommand } from "../editAddress/types"


export const DeleteAddressForm = ({command, addressType, onSave, onClose}:{command: RemoveAddressCommand | undefined, addressType: AddressTypes, onClose: () => void, onSave:(command: RemoveAddressCommand) => void}) => {
    const title = `Remove ${addressType} address`

    return(
        <DeleteModal
        title={title}
        savingFailedMessage={'Address could not be removed'}
        open={true}
        closeModal={onClose}
        onSave={() => command ? onSave(command):{}}
        saving={"ready"}
      >
        <Typography variant='body1'>{`Are you sure you want to remove ${addressType} address?`}</Typography>
      </DeleteModal>
  
    )
}