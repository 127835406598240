import React from "react"
import { InvoiceResponse, NodeInvoiceSummary, RecipeExecutions } from "./types"

export const InvoiceSpecifications = ({invoice}:{invoice?:InvoiceResponse}) => {
    const recipes = invoice?.recipesUsed ?? new Array<RecipeExecutions>()
    const nodes = invoice?.nodes ?? new Array<NodeInvoiceSummary>()
    const recipesCount = recipes?.length ?? 0;

    return (
        <table className="invoice_table">
            <thead>
                <tr>
                    <th>Store</th>
                    <th>Machine</th>
                    {recipes.map((r, idx) => (<th className="right" key={idx}>{r.name}</th>))}
                    <th className="right">Quantity</th>
                    <th className="right">Unit Price</th>
                    <th className="right">Total</th>
                </tr>
            </thead>
            <tbody>
                {nodes.map((n, idx) => <StoreView key={idx} node={n} recipesCount={recipesCount} />)}
            </tbody>
            <tfoot>
                <SummaryView invoice={invoice} recipesCount={recipesCount} />
            </tfoot>
        </table>
    )
}

const SummaryView = ({invoice, recipesCount}:{invoice?:InvoiceResponse, recipesCount: number}) => {

    return(
        <>
        <tr className="summary">
            <td></td>
            <td></td>
            <EmptyColumns count={recipesCount} />
            <td></td>
            <td className="right"><span className="bold">Total</span></td>
            <td className="right"><span className="bold">{invoice?.price?.totalString}</span></td>
        </tr>
        </>
    )
}

const EmptyColumns = ({count}:{count:number}) => {
    return (
        <>
            {Array.from({length: count}).map((_, idx) => (<td key={idx}></td>))}
        </>
    )
}

const StoreView = ({node, recipesCount}:{node:NodeInvoiceSummary, recipesCount: number}) => {
    return (
        <tr>
            <td>{node?.nodeName}</td>
            <td>{node?.machines}</td>
            {node?.executions.recipes.map((r, idx) => (<td className="right" key={idx}>{r.executions}</td>))}
            <td className="right">{node?.executions.recipes.reduce((n, r) => n + r.executions, 0)}</td>
            <td className="right">{node?.price.averageString}</td>
            <td className="right">{node?.price.totalString}</td>
        </tr>
    )
}


