export const APP_STARTED = 'APP_STARTED'
export interface appStarted {
  type: typeof APP_STARTED,
  token: string,
  user: any,
}
export const APP_ENDED = 'APP_ENDED'
interface appEnded {
  type: typeof APP_ENDED,
}

export type AppActionTypes = appStarted | appEnded


export const STOP_SIGNALR_CONNECTION = 'STOP_SIGNALR_CONNECTION'
export interface stopSignalRConnection {
  type: typeof STOP_SIGNALR_CONNECTION,
  connection
}

export const START_SIGNALR_CONNECTION = 'START_SIGNALR_CONNECTION'
export interface startSignalRConnection {
  type: typeof START_SIGNALR_CONNECTION,
  accessToken: string
}

export const SIGNALR_CONNECTION_STARTED = 'SIGNALR_CONNECTION_STARTED'
export interface signalRConnectionStarted {
  type: typeof SIGNALR_CONNECTION_STARTED,
  data: object
}

export const SIGNALR_CONNECTION_STOPPED = 'SIGNALR_CONNECTION_STOPPED'
export interface signalRConnectionStopped {
  type: typeof SIGNALR_CONNECTION_STOPPED
}

export const SET_SHOW_HIDDEN = 'SET_SHOW_HIDDEN'
export interface setShowHidden {
  type: typeof SET_SHOW_HIDDEN,
  value: boolean
}

export type SignalRConnectionActionTypes = stopSignalRConnection
  | startSignalRConnection
  | signalRConnectionStarted
  | signalRConnectionStopped


export type AppSettingActionTypes = setShowHidden

export const VIEW_DIMENSIONS_UPDATED = 'VIEW_DIMENSIONS_UPDATED'
export interface viewDimensionsUpdated {
  type: typeof VIEW_DIMENSIONS_UPDATED,
  device: "mobile" | "tablet" | "desktop",
  orientation: "landscape" | "portrait"
}
export type DimensionsActionTypes = viewDimensionsUpdated