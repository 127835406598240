import * as React from 'react'
import './App.css'
import {
  ListItemIcon,
  List,
  ListItem,
  Drawer,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from './routes'
import logo from './assets/expro_logo_standing.svg';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react'
import { StoreState } from './state/configureStore'
import { loggedOut } from './state/actions/user'
import { AnyAction, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Logout } from '@mui/icons-material'
import theme, {breakpoints} from './theme'
import { redirectUri_logout } from './config'


const drawerWidth = 90
const scaleModifier = 0.025
const logoHeight = 2943 * scaleModifier
const logoWidth = 2412 * scaleModifier

export const topDrawerHeight = '65px'

const styles = {
  logoContainer: {
    width: '100%',
    height: drawerWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '> img': {
      filter: 'invert(1)'
    },
    [breakpoints.renderNavbarOnSide]: {borderBottom: '1px solid #2249376b'},

    [breakpoints.renderNavbarOnTop]: {
      width: topDrawerHeight,
      height: '100%',
    }
  },
  avatarContainer: {
    width: '100%',
    height: drawerWidth,
    [breakpoints.renderNavbarOnTop]: {height: '100%', width: topDrawerHeight},
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [breakpoints.renderNavbarOnTop]: {
      width: topDrawerHeight,
      height: '100%',
    }
  },
  menu: {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      [breakpoints.renderNavbarOnSide]: {
        marginLeft: 10,
      },
      [breakpoints.renderNavbarOnTop]: {
        marginRight: 10,
      },
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        [breakpoints.renderNavbarOnSide]: {
          bottom: 28,
          left: -5,
          top: 'auto',
          right: 'auto'
        },
        [breakpoints.renderNavbarOnTop]: {
          top: 28,
          right: 5,
          bottom: 'auto',
          left: 'auto'
        },

        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(135deg)',
        zIndex: 0,
      },
    },
  },
  menuItem: {
    minWidth: 200
  }
}

const ExproLogo = () => {
  return (
    <React.Fragment>
      <Box sx={{...styles.logoContainer, [breakpoints.renderNavbarOnTop]: {display: "none"}}}>
          <img src={logo} height={logoHeight} width={logoWidth} alt="Logo" />
      </Box>

      <Box sx={{...styles.logoContainer, [breakpoints.renderNavbarOnSide]: {display: "none"}}}>
          <img src={logo} height={topDrawerHeight} width={logoWidth} alt="Logo" />
      </Box>

    </React.Fragment>
  )
}

export function Navbar(state: ReturnType<typeof mapDispatchToProps>): React.ReactElement {
  const auth0 = useAuth0()
  
  const navigate = useNavigate()
  const location = useLocation();

  

  const MenuListItem = styled(ListItem)(
    ({ theme }) => `
      &:hover {
        background: #eff2f4;
        cursor: pointer;
      }

      &:hover svg {
        color: ${theme.palette.primary.main} !important;
      }

      & span {
        color: ${theme.palette.primary.contrastText};
      }

      & svg {
        color: ${theme.palette.primary.contrastText};
      }

      &.Mui-standard {
        // background: ${theme.palette.primary.light};
        color: #fff;
        height: 100px
      }

      &.selectedMenu {
        background: #eff2f4;
        color: ${theme.palette.secondary.main};
      }

      &.selectedMenu svg {
        color: ${theme.palette.primary.main};
      }

      &.Mui-selected:hover {
        color: #fff;
      }
  
      &.Mui-selected span {
        color: #fff;
      }
     
      &.Mui-selected svg {
        color: #fff;
      }
  `,
  );

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clickedLogout = () => {
    state.loggedOut()
    auth0.logout(
      {
        returnTo: redirectUri_logout()
      }
    )
  }

  const isSelected = (link: string) => {
    if (link === '/') { // dashboard, will match will all routes -> should only be selected when we really are on the landing page
      return location?.pathname === link
    }
    return location?.pathname?.toLowerCase().indexOf(link) !== -1
  }

  return (
    <Drawer
      variant='permanent'
      sx={{
        [breakpoints.renderNavbarOnSide]: {width: drawerWidth},
        [breakpoints.renderNavbarOnTop]: {width: 'auto', height: topDrawerHeight},
        flexShrink: 0,

        [`& .MuiDrawer-paper`]: { 
          width: drawerWidth,
          [breakpoints.renderNavbarOnTop]: {height: topDrawerHeight, width: '100%'},
          boxSizing: 'border-box', 
          backgroundColor: 'primary.main', 
          // background: 'linear-gradient(0deg, rgba(20,19,20,1) 0%, rgba(25,24,25,1) 35%, rgba(52,52,52,1) 100%);´' 
        },
        [`& .MuiPaper-root`]: { 
          display: 'flex',
          [breakpoints.renderNavbarOnTop]: {flexDirection: 'row', overflow: 'clip',},
          justifyContent: 'space-between',
          boxShadow: 'none',
          border: 0,
        },
        [`& .MuiList-root`]: { 
          [breakpoints.renderNavbarOnTop]: {
            paddingTop: 0,
            paddingBottom: 0,
            display: 'flex',
            flexDirection: 'row',
          }
        },
        [`& .MuiListItem-root`]: { 
          paddingBottom: 3,
          paddingLeft: 4,
          paddingTop: 3,

          [breakpoints.renderNavbarOnTop]: {            
            height: topDrawerHeight,
            width: topDrawerHeight,


            display: 'flex',
            justifyContent: 'center',
            padding: 0,
            paddingLeft: 0.5
          }
        },
        [`& .MuiSvgIcon-root`]: {
          [breakpoints.renderNavbarOnTop]: {
            height: topDrawerHeight * 0.5,
            width: topDrawerHeight * 0.5,
          }
        }
      }}
    >
      <ExproLogo />

      <Box sx={{ overflow: 'auto' }}>
        <List>
          {routes.filter(route => route.navbar).map(route => (
            <MenuListItem        
              key={route.url}
              sx={{  }}
              className={isSelected(route.url) ? 'selectedMenu' : ''}
              onClick={() => navigate(route.url)}>
              {route.icon &&
                <ListItemIcon>
                  <route.icon/>
                </ListItemIcon>
              }
              {/* <ListItemText primary={route.name} /> */}
            </MenuListItem>
          ))}
        </List>
      </Box>

      <IconButton
          sx={styles.avatarContainer}
          onClick={event => handleClick(event)}
          size='small'
          edge='end'
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar alt={auth0.user?.given_name} src={auth0.user?.picture} sx={{ width: 32, height: 32 }} />
      </IconButton>
      {<Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={styles.menu}
          transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <Typography>{auth0.user?.name}</Typography>
            <Typography variant="body2" style={{ color: theme.palette.text.secondary }}>{auth0.user?.email}</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={clickedLogout}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>}
    </Drawer>
  )
}

const mapStateToProps = (state: StoreState) => ({})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  loggedOut: () => dispatch(loggedOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)