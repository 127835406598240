import React, { useCallback, useEffect, useRef } from 'react'
import mapboxgl from "mapbox-gl";
import { getBoundingBox, getCenter, Location, mapboxToken, mapStyle } from '../../../../utils/map';
import { Box } from '@mui/system';
import theme from '../../../../theme';
import CustomButton from '../../button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

type InteractiveMapProps = {
  locations: Location[],
  markerColors?: any[]
}

const defaultMarkerColor = theme.palette.primary.main

const InteractiveMap = ({
  locations,
  markerColors
}: InteractiveMapProps) => {
  const center: Location = getCenter(locations)

  const mapContainer = useRef(null);
  const map = useRef<any>(null);
  const zoom = 9;

  useEffect(() => {
    return () => { map.current.remove(); }
  }, [])

  useEffect(() => {
    if (map.current) return; // initialize map only once

    // init map
    mapboxgl.accessToken = mapboxToken;
    map.current = new mapboxgl.Map({
      container: mapContainer.current, // container ID 
      style: 'mapbox://styles/seerefine/' + mapStyle, // style URL
      center: [center.longitude, center.latitude], // starting position [lng, lat]
      zoom: zoom // starting zoom
    });

    map.current.addControl(new mapboxgl.NavigationControl({showCompass: false}))

    // set bounding box, so all locations are in view
    if (map && map.current && locations?.length > 1) {
      const boundingBox = getBoundingBox(locations, 2) // TODO: more adaptive padding
      map.current.fitBounds([
        [boundingBox[0].longitude, boundingBox[0].latitude],
        [boundingBox[1].longitude, boundingBox[1].latitude]
      ])
    }

    // Add locations as markers on the map
    locations?.forEach((l, index) => {
      const marker = new mapboxgl.Marker({
        color: (markerColors && markerColors.length > index) ? (markerColors[index] ?? defaultMarkerColor) : defaultMarkerColor
      }).setLngLat([l.longitude, l.latitude])

      marker.addTo(map.current);
    });
  })

  const resetView = useCallback(() => {
    const boundingBox = getBoundingBox(locations, 2) // TODO: more adaptive padding
    if (map.current) map.current.fitBounds([
      [boundingBox[0].longitude, boundingBox[0].latitude],
      [boundingBox[1].longitude, boundingBox[1].latitude]
    ])
  }, [locations])

  return (
    <Box style={{
      height: '100%',
      width: '100%',
    }}>
      {/* <CustomButton endIcon={<RestartAltIcon />} style={{ position: 'absolute', zIndex: 100 }} onClick={resetView}>{'Reset view'}</CustomButton> */}
      <Box style={{
        height: '100%',
        width: '100%',
      }} ref={mapContainer} className="map-container" />
    </Box>
  );
}

export default InteractiveMap