import React, { useEffect, useState } from "react"
import { InvoiceHeader } from "./invoiceHeader"
import { InvoiceSpecifications } from "./invoiceSpecifications"
import { InvoiceResponse } from "./types"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"
import { useQueryBackend } from "../../../utils/requestHelpers"
import { Box, Paper } from "@mui/material"
import { HorizontalSpacer } from "../../../components/atoms/spacer"


export const InvoicePage = () => {
    const [invoice, setInvoice] = useState< InvoiceResponse | undefined>()
    const {customerId} = useParams()
    const [year, setYear] = useState<number>(new Date().getFullYear()) 
    const [month, setMonth] = useState<number>(new Date().getMonth()) 
    const queryApi = useQueryBackend("Backoffice")

    useEffect(() => {
        if(customerId) {
            queryApi.Get<InvoiceResponse>(`customers/${customerId}/invoice/pricemodel/year/${year}/month/${month}`)
                .Data(i => {
                    setInvoice(i)
                })
        }
    }, [customerId, year, month])

    return(
        <Box>
            <Paper>
                <MonthSelector year={year} month={month} onChangeMonth={(year, month) => {setYear(year);setMonth(month)}}/>
            </Paper>
            <HorizontalSpacer size={0.5} />
            <Paper>
                <InvoiceHeader address={invoice?.invoiceAddress} nodeName={invoice?.nodeName}  billingPeriod={invoice?.invoiceFor} />
            </Paper>
            <HorizontalSpacer size={0.5} />
            <Paper>
                <InvoiceSpecifications invoice={invoice} />
            </Paper>
        </Box>
    )
}

export const MonthSelector = ({year, month, onChangeMonth = (year, month) => {}}:{year:number, month:number, onChangeMonth?: (year:number, month:number) => void}) => {
    const currentMonth = DateTime.fromObject({year:year, month:month})
    const prevMonth = currentMonth.minus({month:1})
    const nextMonth = currentMonth.plus({month:1})
    const dateFormat = 'yyyy MMMM'

    return (
        <div>
            <span style={{display:"inline-block", cursor:"pointer"}} onClick={() => onChangeMonth(prevMonth.year, prevMonth.month)}>&lt;&lt;</span>
            <span style={{width:"150px", display:"inline-block", textAlign:"center"}}>{currentMonth.toFormat(dateFormat)}</span>
            <span style={{display:"inline-block", cursor:"pointer"}} onClick={() => onChangeMonth(nextMonth.year, nextMonth.month)}>&gt;&gt;</span>
        </div>
    )
}