import React, { useCallback, useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const colorMapping = {
  'Cotton': '#01204E',
  'Polyester': '#028391',
  'Suede': '#F6DCAC',
  'Leather': '#FEAE6F',
  // Add more recipes and their corresponding colors here
};


const differentRecipes = [
    {name:'Cotton'},
    {name:'Polyester'},
    {name:'Suede'},
    {name:'Leather'}
]


const transformData = (data) => {
    const result = {};
    data?.forEach(item => {
      const { day, recipe , cycle_count } = item;
      if (!result[day]) {
        result[day] = { day };
      }
      result[day][recipe] = cycle_count;
    });
    return Object.values(result);
  };


const CyclesBarChart = ({recipes,}) => {

  const formatXAxis = (tickItem) => {
    return new Date(tickItem).getDate(); // Extract and return only the day from the date
  };
  const data = transformData(recipes) 

  return (
    <div style={{ width: '100%', height: '400px' }}>
     <ResponsiveContainer width="100%" height="100%">
      <BarChart

        data={data}
        margin={{
          top: 20, right: 30, left: 0, bottom: 5,
        }}
      >
        <CartesianGrid stroke={ "#ccc" } strokeDasharray="3" />
        <XAxis dataKey="day" tickFormatter={formatXAxis} />
        <YAxis />
        <Tooltip />
        <Legend layout="horizontal" verticalAlign="bottom" align={"center"}  />
        {differentRecipes.map((r, index) => (
          <Bar
            key={index}
            dataKey={r?.name}
            stackId="day"
            name={r.name}
            fill={colorMapping[r.name] || '#000000'} // Default to black if no color defined
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
    </div>
  );
};

export default CyclesBarChart;
