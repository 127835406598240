import React, { useEffect, useState } from "react"
import { Nullable } from "../../../common-types"
import { NumberInput } from "../../atoms/inputs"
import { ValidatableInputProps } from "./types"
import { haveErrors, isRequired } from "./functions"
import { CommandBase } from "../../../common-types/commandingTypes"



export const ValidatableNumberInput = <TCommand extends CommandBase, TKey extends keyof TCommand & string,>({
    command,
    property,
    schema,
    update,
    title
}: ValidatableInputProps<TCommand, TKey>
) => {
    const [validate, setValidate] = useState<boolean>(false)
    const [error, setError] = useState<boolean>()
    title = title ?? schema.properties[property]?.title ?? "Missing proprty title"
    const value = command[property] as Nullable<Number>
    const required = isRequired(schema, property)

    useEffect(() => {
        if(validate) {
            const errors = haveErrors(schema, command, property)
            setError(errors.length > 0)
        }
    }, [value, required, validate])

    return (
        <NumberInput 
            label={title}
            value={value}
            onChange={v => update(c => ({...c, [property]: v}))}
            required={required}
            error={error}
            onBlur={() => setValidate(true)}
            />
    )
}