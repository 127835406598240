export const OPENED_MAP_POPUP = 'OPENED_MAP_POPUP'
export interface openedMapPopup {
  type: typeof OPENED_MAP_POPUP,
  id: string,
  popup: any
}

export const CLOSED_MAP_POPUP = 'CLOSED_MAP_POPUP'
export interface closedMapPopup {
  type: typeof CLOSED_MAP_POPUP,
  id: string,
}

export const CLOSE_ALL_MAP_POPUPS = 'CLOSE_ALL_MAP_POPUPS'
export interface closeAllMapPopups {
  type: typeof CLOSE_ALL_MAP_POPUPS,
}

export type MapActionTypes = openedMapPopup | closedMapPopup | closeAllMapPopups
