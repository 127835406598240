import { TextField } from "@mui/material"
import React from "react"
import { Nullable } from "../../../common-types"
import { InputStyle } from "./style"

export const NumberInput = ({
    label,
    value,
    required,
    error,
    onChange,
    onBlur
}:{
    label:string
    value: Nullable<Number>
    required?: boolean
    error?: boolean
    onChange: (val: Nullable<number>) => void 
    onBlur?: () => void
}) => {

    const toNumber = (val: string | null | undefined): number | null => {
        if(val) {
            return parseInt(val)
        }

        return null
    }

    return (
        <TextField
            label={label}
            type="number"
            {...InputStyle}
            required={required}
            error={error}
            fullWidth
            value={value}
            onChange={(e) => onChange(toNumber(e.target.value))}
            onBlur={onBlur}
            />
    )
}