import { Delete, Edit, RestoreFromTrash, VisibilityOff } from '@mui/icons-material'
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useEffect, useRef } from 'react'
import theme from '../../theme'
import { VerticalSpacer } from '../atoms/spacer'
import CustomButton from './button'
import { useSelector } from 'react-redux'

type TableProps = TablePropsWithoutPaging | TablePropsWithPaging

export type TablePropsWithoutPaging = ({
  columnNames: {
    displayName: string,
    serverName: string | null
  }[],
  rows: Object[],
  onClickRow?: Function,
  onClickDelete?: Function,
  onClickEdit?: Function,
  onClickRestore?: Function,

  noPaging: true // needs to be set explicitly to prevent accidentally forgetting to give table paging info
  noHover?: boolean,
  animateTable?: boolean // can cause graphical issues when refreshing the table, if the structure isn't exactly right. Need to find out why that is. Eventually want  all the tables to be animated.

  sortingOn: string | null,
  descending: boolean,
  setSortingOn: ((newSortingOn: any) => any) | null,
  setDescending: (newDescending: boolean) => any,
  selectedRows?: string[]
})

export type TablePropsWithPaging = ({
  columnNames: {
    displayName: string,
    serverName: string | null
  }[],
  rows: Object[],
  onClickRow?: Function,
  onClickDelete?: Function,
  onClickEdit?: Function,
  onClickRestore?: Function,
  total: number,

  noHover?: boolean,
  animateTable?: boolean // can cause graphical issues when refreshing the table, if the structure isn't exactly right. Need to find out why that is. Eventually want  all the tables to be animated.

  countPerPage: number,
  page: number,
  sortingOn: string | null,
  descending: boolean,
  setCountPerPage?: (newCount: number) => any,
  setPage?: (newPage: number) => any,
  setSortingOn?: ((newSortingOn: any) => any) | null,
  setDescending?: (newDescending: boolean) => any,

  selectedRows?: string[],
})

const hiddenRowColor = theme.palette.grey[600]
const hiddenFirstCellStyle = {
  color: hiddenRowColor,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 65 // slightly magic number, seems to be the height of cell. without, the cell becomes slightly smaller than the others
}

const TableComponent = (props: TableProps) => {
  const columnNamesConst = [...props.columnNames.map(cn => cn.displayName)] as const

  type SortOn = typeof columnNamesConst[number];

  type RowDataObject = ({
    'id': 'string',
    [key: SortOn]: string
  })

  const rowsData = props.rows as RowDataObject[]

  const onClickColumnName = (sortOn: SortOn) => {
    if (!props.sortingOn || props.sortingOn === sortOn) props.setDescending(!props.descending)
    else if (props.setSortingOn) props.setSortingOn(sortOn)
  }

  const roles  =   useSelector(state => state?.authenticated_user?.organisationRoles)

  const hasWriteAccess = useCallback((id) =>  {
          return roles.find(a => a.orgId === id && a.role === "NodeEditor")?.length > 0 
        }, 
    [ roles] ) 
  return (
    <Table>
      <TableHead>
        <TableRow>
          {props.columnNames.map((columnName, index) => {
            return (
              <TableCell sx={{fontFamily: 'DIN'}} key={'cell' + index}>
                <TableSortLabel
                  hideSortIcon={columnName.serverName ? false : true}
                  active={props.sortingOn ? (props.sortingOn === columnName.serverName) : false}
                  direction={props.descending ? 'desc' : 'asc'}
                  onClick={columnName.serverName && props.setSortingOn ?
                    () => onClickColumnName(columnName.serverName!)
                    : undefined
                  }
                  sx={!columnName.serverName || !props.setSortingOn ?
                    {
                      ":hover": { cursor: 'default' }
                    } : null
                  }
                >
                  {columnName.displayName}
                </TableSortLabel>
              </TableCell>)
          })
          }
          {
            props.onClickEdit ? <TableCell sx={{ width: 25, fontFamily: 'DIN' }} key={'edit_button'}></TableCell> : null
          }
          {
            props.onClickDelete ? <TableCell sx={{ width: 25, fontFamily: 'DIN' }} key={'delete_button'}></TableCell> : null
          }
        </TableRow>
      </TableHead>

      <TableBody>
        {
          rowsData.map((row, index) => {
            const tableRowContent = <React.Fragment>{
              Object.keys(row).filter((key: string) => { return key !== 'id' && key !== 'hidden' })
                .map((key: string, index) => {
                  return index === 0 && row.hidden ? <TableCell onClick={props.onClickRow ? () => props.onClickRow!(row['id']) : undefined} key={key + '_' + index} sx={hiddenFirstCellStyle}>
                    <VisibilityOff sx={{ color: hiddenRowColor }} fontSize='small' />
                    <VerticalSpacer />
                    {row[key]}
                  </TableCell> : (
                    <TableCell sx={row.hidden ? { color: hiddenRowColor } : undefined} onClick={props.onClickRow ? () => props.onClickRow!(row['id']) : undefined} key={key + '_' + index}>{row[key]}</TableCell>
                  )
                })
            }

              {props.onClickEdit && hasWriteAccess(row['id']) ? <TableCell sx={{fontFamily: 'DIN'}} key={'cell_actions_edit'}>
                <CustomButton onClick={props.onClickEdit ? () => (props.onClickEdit)!(row['id']) : undefined} noBg endIcon={<Edit />} />
              </TableCell> : null}

              {props.onClickDelete && !row.hidden && hasWriteAccess(row['id']) ? <TableCell sx={{fontFamily: 'DIN'}} key={'cell_actions_delete'}>
                <CustomButton onClick={props.onClickDelete ? () => props.onClickDelete!(row['id']) : undefined} noBg endIcon={<Delete />} />
              </TableCell> : null}

              {props.onClickRestore && row.hidden  && hasWriteAccess(row['id']) ? <TableCell sx={{fontFamily: 'DIN'}} key={'cell_actions_restore'}>
                <CustomButton onClick={props.onClickRestore ? () => props.onClickRestore!(row['id']) : undefined} noBg endIcon={<RestoreFromTrash />} />
              </TableCell> : null}
            </React.Fragment>

            return (props.animateTable ?
              <AnimatePresence key={'row_' + index}>
                <TableRow
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  selected={(props.selectedRows && props.selectedRows.includes(row.id))}
                  key={index + '_' + row['id']}
                  hover={!props.noHover}

                  sx={{
                    cursor: props.noHover && !props.onClickRow ? 'default' : 'pointer'
                  }}
                >
                  {tableRowContent}
                </TableRow>
              </AnimatePresence>
              : <TableRow
                selected={(props.selectedRows && props.selectedRows.includes(row.id))}
                key={index + '_' + row['id']}
                hover={!props.noHover}

                sx={{
                  cursor: props.noHover && !props.onClickRow ? 'default' : 'pointer'
                }}
              >
                {tableRowContent}
              </TableRow>)
          })
        }
      </TableBody>

      {
        (props as TablePropsWithPaging).setPage ?  // checks: did type conversion work?
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{fontFamily: 'DIN'}}
                count={(props as TablePropsWithPaging).total}
                page={(props as TablePropsWithPaging).page}
                rowsPerPage={(props as TablePropsWithPaging).countPerPage}
                onPageChange={(_, page) => (props as TablePropsWithPaging).setPage(page)}
                onRowsPerPageChange={e => (props as TablePropsWithPaging).setCountPerPage(Number.parseInt(e.target.value))}
              />
            </TableRow>
          </TableFooter> : null
      }
    </Table >
  )
}

export default TableComponent