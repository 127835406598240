
import React from 'react';
import { Box, Typography } from '@mui/material';

export type LogTextObject = {
  text: string,
  style?: Object
}

interface LogTextProps {
  textPieces: LogTextObject[],
}

const LogText: React.FC<LogTextProps> = ({ textPieces = [] }) => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '1%',
      paddingBottom: '2%',
      flexWrap: 'wrap'
    }}>
      { textPieces?.map((textPiece, index) => {
        return <Typography key={index} noWrap style={{...(textPiece?.style ? textPiece.style : {}), whiteSpace: 'break-spaces'}} variant="body2" >{textPiece.text}</Typography>
      })
      }
    </Box>
  );
}

export default LogText;