import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, Link as RouterLink } from 'react-router-dom'
import { AnyAction, Dispatch } from 'redux'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import Page from '../../components/molecules/page'
import { fetchMachines } from '../../state/actions/machine'
import { StoreState } from '../../state/configureStore'
import React from 'react'
import { Dvr, Edit, Info, ReceiptLongOutlined } from '@mui/icons-material'
import { Box, Link, Typography } from '@mui/material'
import EditMachineModal from './editMachineModal'
import LogTable from './logTable'
import InteractiveMap from '../../components/molecules/maps/interactive/interactiveMap'
import { padding } from '../../theme'
import { TextTitle } from '../../components/atoms/text/titles'
import { MachineState } from '../../state/reducers/machine'
import UptimeTable from './uptimeTable'
import { MachineDetailsView } from './machineDetails'
import  MachineErrorList from './machineErrorList'
import { RecipesList } from './recipes'
import { SoldRecipes } from '../customers/customer'


const infoRowWidth = 800
const style = {
  infoSection: {
    paddingBottom: padding * 2
  },
  paddingTop: {
    paddingTop: padding * 2
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    width: infoRowWidth,
    justifyContent: 'space-between'
  }
}


export const Machine = (
  state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
  const { serialId } = useParams()

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [machine, setMachine] = useState<MachineState | undefined>(undefined)


  useEffect(() => {
    setMachine(state.machines.find(machine => machine.serialId === serialId))
  }, [serialId, JSON.stringify(state.machines)])

  useEffect(() => {
    // this can be the case if we got here via refresh or direct link
    const machineNotFetched = state.machines.findIndex(d => d.serialId === serialId) === -1
    if (machineNotFetched) state.fetchMachines()
  }, [serialId])

  useEffect(() => {
    if (state.savingState === 'ready') setEditModalOpen(false)
  }, [state.savingState])

  const LogsSection = useCallback(() => {
    return (
      <LogTable serialId={serialId} />
    )
  }, [serialId])

  const UptimeSection = useCallback(() => {
    return  (
      <Box style={{ ...style.infoSection, ...style.paddingTop }}>
        <TextTitle title={'Uptime'} />
        <UptimeTable serialId={serialId} />
      </Box>
    )
  }, [serialId])

  const MachineInfo = useCallback(() => {
    const ownerCompanyName = machine?.parents?.length ? machine?.parents[0].name : undefined
    const ownerCompanyId = machine?.parents?.length ? machine?.parents[0].id : undefined


    const nodeUrl = '/customers/' + (machine?.parents?.length ? (machine?.parents.map(p => p.id)?.join('/') + '/' + machine?.nodeId) : '')
    return (
      <Box>
        {
          ((!machine || !ownerCompanyName) && state.isLoading_machineInfoBox) ? <DataLoadingText showSpinner text={'Loading machine information ...'} />
            : <React.Fragment>
              <Box style={style.infoSection}>
                <TextTitle title={'Overview'} />
                <Box style={style.infoRow}>
                  <Typography style={{ flex: 1 }} variant='body1'>{'Owner:'}</Typography>
                  {(ownerCompanyName && ownerCompanyId) ? <Link style={{ flex: 1 }} component={RouterLink} to={`/customers/${ownerCompanyId}`}>{ownerCompanyName}</Link> : <Typography style={{ flex: 1 }} variant='body1'>{'Nobody'}</Typography>}
                </Box>
                <Box style={style.infoRow}>
                  <Typography style={{ flex: 1 }} variant='body1'>{'Store:'}</Typography>
                  <Typography style={{ flex: 1 }} variant='body1'>{(machine?.nodeName ? <Link style={{ flex: 1 }} component={RouterLink} to={nodeUrl}>{machine?.nodeName}</Link> : 'Not in any store')}</Typography>
                </Box>
              </Box>
              <MachineDetailsView MachineId={machine?.serialId} />
              <MachineErrorList SerialId={machine?.serialId ?? ''} />
              <Box style={{ ...style.infoSection, ...style.paddingTop }}>
                <TextTitle title={'Location'} />
                {machine?.location ? <Box style={{
                  width: '100%',
                  height: 300,
                  display: 'flex',
                  alignSelf: 'center',
                }}><InteractiveMap locations={[machine.location]} /></Box> : null}
              </Box>
            </React.Fragment>
        }
      </Box>
    )
  }, [machine, state.isLoading_machineInfoBox])

  return (
    <React.Fragment>
      {machine && editModalOpen ? <EditMachineModal machine={machine} open={editModalOpen} closeModal={() => setEditModalOpen(false)} /> : null}
      <Page
        title={'Machine: ' + machine?.name ? machine?.name : machine?.serialId}
        Menu={{
          Id:'machine-page-menu',
          ButtonTitle:'Actions',
          MenuItems:[{
            Label:'Edit machine name',
            Icon:<Edit/>,
            onClick: () => setEditModalOpen(true)
          }]
        }}
        tabs={[
          {
            name: 'Overview',
            icon: <Info />,
            pageSections: [
                // <Box>
                  <MachineInfo />,
                  <SoldRecipes id={machine?.serialId} queryString={"recipes-run-daily-machine"}/>,
                  <UptimeSection />
                // </Box>
              ]
          },
          {
            name: 'Sales',
            icon: <ReceiptLongOutlined/>,
            pageSections:[<RecipesList MachineId={serialId} />]
          },
          {
            name: 'Machine Logs',
            icon: <Dvr />,
            pageSections: [<LogsSection />]
          }
        ]}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state: StoreState) => ({
  machines: state.machines,
  isLoading_machineInfoBox: state.machine_fetching === 'loading',
  savingState: state.machine_saving,
  // machine_twins: state.machine_twins,
  customers: state.customers
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchMachines: () => dispatch(fetchMachines()),
  // fetchMachineTwin: (serialId: string) => dispatch(fetchMachineTwin(serialId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Machine)