import { Typography } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { DeleteModal } from '../../components/molecules/DeleteModal';
import { removeUser } from '../../state/actions/user';
import { StoreState } from '../../state/configureStore';
import { UserState } from '../../state/reducers/user';

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  user: UserState
}

function RemoveUserModal(props: IComponentProps): React.ReactElement {
  return (
    <DeleteModal
      title={'Remove User'}
      savingFailedMessage={'The user could not be removed. Please refresh the page and try again.'}
      open={props.open}
      closeModal={props.closeModal}
      onSave={() => props.removeUser(props.user.userId)}
      saving={props.user_saving}
    >
      <Typography variant='body1'>{'Are you sure you want to remove this user?'}</Typography>
    </DeleteModal>
  )
}

const mapStateToProps = (state: StoreState) => ({
  user_saving: state.user_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  removeUser: (userId: string) => dispatch(removeUser(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoveUserModal)