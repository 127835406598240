import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { AddModal, Subheader } from '../../../components/molecules/AddModal'
import { StoreState } from '../../../state/configureStore'
import { AnyAction, Dispatch } from 'redux'
import { renameNode } from '../../../state/actions/customer'
import { capitalizeFirstLetter } from '../../../utils/text'
import { CustomerNode } from '../../../state/reducers/customer'

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  node: CustomerNode;
  nodeTypeName: 'customer' | 'store',
  nameOnly?: boolean // if we ever use this modal to edit more things, can use this to turn it into a name-change modal
}

function EditNodeModal(props: IComponentProps): React.ReactElement {
  const [nameError, setNameError] = useState(false)

  const [name, setName] = useState(props.node.name)

  const onSave = () => {
    if (!name) setNameError(true)
    else setNameError(false)

    if (name) {      
      props.renameNode(props.node.id, name)
    }
  }

  const resetModal = () => {
    setNameError(false)

    setName('')
  }

  return (
    <AddModal
      title={`Edit ${capitalizeFirstLetter(props.nodeTypeName)}`}
      saving={props.savingState}
      open={props.open}
      savingFailedMessage={'Saving failed. Please check your inputs below and your internet connection and try again.'}
      closeModal={() => { resetModal(); props.closeModal() }} onSave={onSave}>

      <Subheader title={`${capitalizeFirstLetter(props.nodeTypeName)} Name`}/>
      <TextField
        autoFocus
        margin="normal"
        id="name_input_field"
        label={`${capitalizeFirstLetter(props.nodeTypeName)} Name`}
        defaultValue={props.node.name}
        type="text"
        fullWidth
        onChange={(event) => setName(event.target.value)}
        error={nameError}
        helperText={nameError ? "Please enter a name." : null}
      />
    </AddModal>
  )
}

const mapStateToProps = (state: StoreState) => ({
  savingState: state.customer_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  renameNode: (id: string, name: string) => dispatch(renameNode(id, name))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditNodeModal)