import React, { useEffect, useState } from "react";
import { AddModal, Subheader } from "../../../components/molecules/AddModal";
import { CreateCustomerCommand} from "./types";
import { ValidatableSelectInput, ValidatableTextInput } from "../../../components/molecules/validatable-inputs";
import { isValid } from "../../../components/molecules/validatable-inputs/functions";
import { CommandWithSchema } from "../../../common-types/commandingTypes";


export const CreateCustomerForm = ({command, onClose, onSave}:{command:CommandWithSchema<CreateCustomerCommand>, onClose: () => void, onSave: (command: CreateCustomerCommand) => void}) => {
    const [editCommand, setEditCommand] = useState<CreateCustomerCommand>(command.command)
    const [validationError, setValidationError] = useState<boolean>(true)
    const schema = {
            required: ["id", "invoice_AddressLine1", "invoice_Country", "invoice_Locality", "invoice_PostalCode", "name"],
            type: "object",
                properties: {
                    id: {
                        minLength: 1,
                        type: "string"
                    },
                    name: {
                        minLength: 1,
                        type: "string"
                    },
                    invoice_AddressLine1: {
                        minLength: 1,
                        type: "string"
                    },
                    invoice_AddressLine2: {
                        type: "string",
                        nullable: true
                    },
                    invoice_PostalCode: {
                        minLength: 1,
                        pattern: "^(\\d+\\s?\\d+)$",
                        type: "string"
                    },
                    invoice_Locality: {
                        minLength: 1,
                        type: "string"
                    },
                    invoice_Country: {
                        enum: ["SWEDEN", "NORWAY", "FINLAND"],
                        type: "string"
                    },
                    visiting_AddressLine1: {
                        type: "string",
                        nullable: true
                    },
                    visiting_AddressLine2: {
                        type: "string",
                        nullable: true
                    },
                    visiting_PostalCode: {
                        pattern: "^(\\d+\\s?\\d+)$",
                        type: "string",
                        nullable: true
                    },
                    visiting_Locality: {
                        type: "string",
                        nullable: true
                    },
                    visiting_Country: {
                        enum: ["SWEDEN", "NORWAY", "FINLAND"],
                        type: "string"
                    }
            }
    }
    
    useEffect(()=>{
        if(editCommand) {
            setValidationError(!isValid(schema, editCommand))
        }
    }, [editCommand])

    return (
        <AddModal
            title="Add Customer"
            savingFailedMessage={'The customer could not be saved.'}
            saving={"ready"}
            savingDisabled={validationError}
            open={true}
            closeModal={onClose}
            onSave={() => onSave(editCommand)}
            >

            <ValidatableTextInput title="Name" property="name" command={editCommand} schema={schema} update={setEditCommand} />

            <Subheader title={'Invoice Address'} />

            <ValidatableTextInput title="Street Address" command={editCommand} property="invoice_AddressLine1" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="Extra Address" command={editCommand} property="invoice_AddressLine2" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="Postal Code" command={editCommand} property="invoice_PostalCode" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="City" command={editCommand} property="invoice_Locality" schema={schema} update={setEditCommand} />
            <ValidatableSelectInput title="Country" command={editCommand} property="invoice_Country" schema={schema} update={setEditCommand} />

            <Subheader title={'Visiting Address'} />

            <ValidatableTextInput title="Street Address" command={editCommand} property="visiting_AddressLine1" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="Extra Address" command={editCommand} property="visiting_AddressLine2" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="Postal Code" command={editCommand} property="visiting_PostalCode" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="City" command={editCommand} property="visiting_Locality" schema={schema} update={setEditCommand} />
            <ValidatableSelectInput title="Country" command={editCommand} property="visiting_Country" schema={schema} update={setEditCommand} />

        </AddModal>           
    )
}