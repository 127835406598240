import React, { useState } from "react"

import { EditButton } from "../../../../components/atoms/buttons"
import { AddressTypes, ChangeAddressCommand } from "./types"
import { EditAddressForm } from "./editAddressForm"
import { useCommandBackend } from "../../../../utils/requestHelpers"
import { CommandWithSchema } from "../../../../common-types/commandingTypes"


export const EditAddressView = ({addressType, command, onSaved = () => {}}:{
    addressType: AddressTypes, 
    command: CommandWithSchema<ChangeAddressCommand> | undefined, 
    onSaved?: (command: ChangeAddressCommand) => void
}
) => {
    const title = `Edit ${addressType} address`
    const [showModal, setShowModal] = useState<boolean>()
    const commandApi = useCommandBackend("Backoffice")
    

    const save = (command: ChangeAddressCommand) => {
        commandApi.PostCommand('/cmd/node/address/change', command)
            .Data(result => {
                onSaved(result.command)
                setShowModal(false)
            })
    }

    return(
        <div>
            <EditButton 
                title={title} 
                onClick={() => setShowModal(command !== undefined)} 
                enabled={command !== undefined} 
                />

            {showModal && <EditAddressForm addressType={addressType} command={command!} onClose={() => setShowModal(false)} onSave={save}/>}
        </div>
    )
}

