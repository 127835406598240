import { Action } from "redux"

export const MACHINE_ERROR_REPORTED = 'MACHINE_ERROR_REPORTED'
export interface MachineErrorReported extends Action {
    type: typeof MACHINE_ERROR_REPORTED,
    message: {
        machineId: string,
        code: number,
        name: string,
        text: string,
        reportedAt: string,
        blocking: boolean,
    }
}

export const MACHINE_ERROR_REMOVED = 'MACHINE_ERROR_REMOVED'
export interface MachineErrorRemoved extends Action {
    type: typeof MACHINE_ERROR_REMOVED,
    message: {
        machineId: string,
        code: number,
        name: string,
        text: string,
        reportedAt: string,
        blocking: boolean,
    }
}

type MachineErrorTypes = 
      MachineErrorReported
    | MachineErrorRemoved


export default MachineErrorTypes