import React from 'react'
import theme, { connectionStateColors } from '../../theme'
import Tag from '../atoms/tag'

export function ConnectionStateTag({ connectionState }) {
  const isConnected = connectionState !== 'Disconnected'

  return (
    <Tag
      color={isConnected ? connectionStateColors.connected : connectionStateColors.not_connected}
      title={connectionState ?? 'Unknown'} />
  )
}