import { Checkbox } from "@mui/material"
import React , { Dispatch, SetStateAction } from "react"

export interface CheckedItem {
    name: string
    id: string,
    isChecked: boolean
  }
 export const CheckedList = ({list, setList}: {list: CheckedItem[], setList: Dispatch<SetStateAction<CheckedItem[]| undefined>>}) => {
    const amnt = list.length
    const handleChange = (item:CheckedItem, event:React.ChangeEvent<HTMLInputElement> ) =>{
       setList((prevState?: CheckedItem[] )=> {
        return prevState?.map((l: CheckedItem) => {
            return l.id === item.id ? {...l, isChecked: event.target.checked} : l 
        }) 
    })
    }  
    return(
      <div style={{display: "flex", flex: 1, borderColor:"red", borderWidth: 2,  flexDirection: "column"}}>
     { list.map(( o, idx) => {return (
        <CheckedListItem key={idx} item={o} handleChange={handleChange} amnt = {amnt}/>
        )})
     }
      </div>
    )
  } 
  
  const CheckedListItem = ({item, handleChange, amnt}:{
      item:CheckedItem,
      handleChange: (item:CheckedItem, event:React.ChangeEvent<HTMLInputElement>) => void,
      amnt:number 
    }) => {
  
    return(
      <div>
        <Checkbox
          value={item.id}
          checked={item.isChecked }
          onChange={(event) => handleChange( item, event)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
       {item.name}
       </div>
    )
  }