import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { PagedResponse, useCommandBackend, useQueryBackend } from "../../../utils/requestHelpers"

import { InitialFeeWithPerExecutionThresholdsCommand, PriceModelEnvelope, PriceModelIdentity } from "./types"

import { PriceModelRemoveButton } from "./priceModelRemoveButton"
import { ComponentCallback, logImpl } from "../../types"
import { InitialFeeWithPerExecutionThresholdsAddButton } from "./InitalFeeWithPerExecution/initialFeeWithPerExecutionThresholdsAddButtonAddButton"
import { InitialFeeWithPerExecutionThresholdsView } from "./InitalFeeWithPerExecution/initialFeeWithPerExecutionThresholdsView"

export const PriceModelView = () => {
    const {customerId} = useParams()
    const [model, setModel] = useState<Array<PriceModelEnvelope>>([])
    const [addCommand, setAddCommand] = useState<InitialFeeWithPerExecutionThresholdsCommand | undefined>()
    const query = useQueryBackend("Backoffice");
    const commandApi = useCommandBackend("Backoffice")

    useEffect(() =>{
        if(customerId) {
            query.Get<PagedResponse<PriceModelEnvelope>>(`customers/${customerId}/price_models`)
                .Data(m => {
                    setModel(m.items)
                })
            fetchCommand(customerId)
        }

    }, [customerId])

    const fetchCommand = (nodeId:string) => 
        commandApi.Get<InitialFeeWithPerExecutionThresholdsCommand>(`/cmd/node/price-model/add/initialfeewithperexecutionthresholds/${nodeId}`)
            .Data(c => {
                setAddCommand(c)
            })
            .Empty(() => setAddCommand(undefined))
            .Rejected((r) => setAddCommand(undefined))

    const added = (m:PriceModelEnvelope) => {
        setModel(c => [m,...c])
        if(customerId)
            fetchCommand(customerId)
    }

    const deleted = (m:PriceModelIdentity) => {
        setModel(c => {
            return [...c.filter((p) => p.nodeId !== m.nodeId || !isSameMonth(p.validFrom, m.validFrom))]
        })
        if(customerId)
            fetchCommand(customerId)
    }

    return (
        <div>
            <div><InitialFeeWithPerExecutionThresholdsAddButton command={addCommand} onAdd={added} /></div>
            <PriceModelsList items={model} onDeleted={deleted} />
        </div>
    )
}

const isSameMonth = (dateLike1: string, dateLike2: string):boolean => {
    const date1 = new Date(Date.parse(dateLike1))
    const date2 = new Date(Date.parse(dateLike2))

    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth()
}

const PriceModelsList = ({items, onDeleted = logImpl<PriceModelEnvelope>()}:{items:Array<PriceModelEnvelope>, onDeleted?: ComponentCallback<PriceModelIdentity>}) => {
    return (
        <table>
            <thead>
            <tr>
                <th>Prices</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                {items.map((m, idx) => <PriceModelListItem key={idx} model={m} onDeleted={onDeleted} />)}
            </tbody>
        </table>
    )
}


const PriceModelListItem = ({model, onDeleted}:{
    model: PriceModelEnvelope
    onDeleted: ComponentCallback<PriceModelIdentity>
}) => {
    return (
        <tr>
            <td>
                <InitialFeeWithPerExecutionThresholdsView model={model.model} validFrom={model.validFrom} />
            </td>
            <td>
                <PriceModelRemoveButton nodeId={model.nodeId} fromDate={model.validFrom} onDeleted={onDeleted} />
            </td>
        </tr>
    )
}


