import { useEffect, useState } from "react"
import { AddRecommendedPrice } from "./types"
import { CommandWithSchema } from "../../../common-types/commandingTypes"
import { isValid } from "../../../components/molecules/validatable-inputs/functions"
import { AddModal } from "../../../components/molecules/AddModal"
import React from "react"
import { ValidatableNumberInput, ValidatableSelectInput } from "../../../components/molecules/validatable-inputs"
import { MonthSelectorInput } from "../../../components/atoms/inputs"


export const AddPriceForm = ({command, onClose, onSave}:{
    command: CommandWithSchema<AddRecommendedPrice>
    onClose: () => void
    onSave: (cmd: AddRecommendedPrice) => void

}) => {
    const [editCommand, setEditCommand] = useState<AddRecommendedPrice>(command.command)
    const [error, setError] = useState<boolean>()
    const schema = {
        required: ["currency", "id", "price", "validFrom"],
        type: "object",
            properties: {
                id: {
                    minLength: 1,
                    type: "string"
                },
                currency: {
                    minLength: 1,
                    type: "string",
                    enum: ["SEK", "NOK", "EUR"]
                },
                price: {
                    type: "number",
                },
                validFrom: {
                    type: "string",
                }
            }
        }
        
    useEffect(()=> {
        if(editCommand)
            setError(!isValid(schema, editCommand))
    }, [editCommand])

    return (
        <AddModal
            title="Add Recommended Price"
            open={true}
            saving={'Saving'}
            savingFailedMessage="Failed"
            savingDisabled={error}
            closeModal={onClose}
            onSave={() => onSave(editCommand)}
            >
                <MonthSelectorInput label="From" value={command.command.validFrom} minDate={command.command.validFrom} onChange={(v) => setEditCommand(c =>  ({...c,validFrom:v! }))} />
                <ValidatableNumberInput title="Price" command={editCommand} property="price" schema={schema} update={setEditCommand} />
                <ValidatableSelectInput title="Currency" command={editCommand} property="currency" schema={schema} update={setEditCommand} />
        </AddModal>
    )
}