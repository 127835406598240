import {
  AttachMoneyRounded,
  BugReportOutlined,
  CheckCircleRounded,
  Done,
  ErrorOutlineOutlined,
  InfoOutlined,
  MiscellaneousServicesRounded,
  PriorityHigh,
  WarningAmber,
  ArrowCircleUpOutlined,
  ArrowCircleDownOutlined
} from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { LevelType } from './logLevelDisplay'

const roundTagSize = '18px'

const styles = {
  icon_container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

const getLevelColor = (level: string) => {
  switch (level) {
    case 'Info': return 'info'


    case 'Error':
    case 'Down':
    case 'Critical': return 'error'

    case 'Success':
    case 'Debiting':
    case 'Up': return 'success'

    case 'Warning': return 'warning'

    case 'Debug':
    case 'Recipe':
    default: return 'primary'
  }
}

export function LogLevelIcon({ level, small = false }: { level: LevelType, small?: boolean }) {
  const theme = useTheme()

  return (
    <Box sx={small ? null : {
      backgroundColor: theme.palette[getLevelColor(level)].main ?? theme.palette.primary.main,
      color: 'white',
      width: small ? '20px' : roundTagSize,
      height: small ? '20px' : roundTagSize,
      borderRadius: 18,
      marginRight: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Box sx={small ? null : { ...styles.icon_container }}>
        {
          level === 'Info' ? (small ? <InfoOutlined color={getLevelColor(level)} /> : <InfoOutlined />)
            : level === 'Warning' ? (small ? <WarningAmber color={getLevelColor(level)} /> : <ErrorOutlineOutlined />)
              : level === 'Error' ? (small ? <ErrorOutlineOutlined color={getLevelColor(level)} /> : <ErrorOutlineOutlined />)
                : level === 'Debug' ? (small ? <BugReportOutlined color={getLevelColor(level)} /> : <BugReportOutlined />)
                  : level === 'Debiting' ? (small ? <AttachMoneyRounded color={getLevelColor(level)} /> : <AttachMoneyRounded />)
                    : level === 'Critical' ? (small ? <PriorityHigh color={getLevelColor(level)} /> : <PriorityHigh />)
                      : level === 'Success' ? (small ? <Done color={getLevelColor(level)} /> : <CheckCircleRounded />)
                        : level === 'Recipe' ? (small ? <MiscellaneousServicesRounded color={getLevelColor(level)} /> : <MiscellaneousServicesRounded />)
                          : level === 'Up' ? (small ? <ArrowCircleUpOutlined color={getLevelColor(level)} /> : <ArrowCircleUpOutlined />)
                            : level === 'Down' ? (small ? <ArrowCircleDownOutlined color={getLevelColor(level)} /> : <ArrowCircleDownOutlined />)
                              : null
        }
      </Box>
    </Box>
  )
}