import { takeEvery } from 'redux-saga/effects'
import { postChangeNodeAddress, postCustomer, postCustomerSubnode, postMachineToCustomer, postDisconnectMachineFromNode, postRemoveAddressFromNode, postRemoveCustomer, postRenameNode, postUnhideCustomer, postPriceModel} from '../../utils/commands'
import { fetchCustomers, fetchCustomer, fetchCustomerInvoice} from '../../utils/request'
import { FETCH_CUSTOMERS, FETCH_CUSTOMER, ADD_CUSTOMER, ADD_CUSTOMER_SUBNODE, ADD_MACHINE_TO_CUSTOMER, REMOVE_MACHINE_FROM_NODE, FETCH_CUSTOMER_INVOICE, REMOVE_CUSTOMER, UNHIDE_CUSTOMER, REMOVE_ADDRESS_FROM_NODE, CHANGE_NODE_ADDRESS, RENAME_NODE, SAVE_PRICE_MODEL, FETCH_CUSTOMER_PRICE_MODELS } from '../actionTypes/customer'

export function* customerSagas() {
  yield takeEvery(FETCH_CUSTOMERS, fetchCustomers)
  yield takeEvery(FETCH_CUSTOMER, fetchCustomer)
  yield takeEvery(ADD_CUSTOMER, postCustomer)
  yield takeEvery(ADD_CUSTOMER_SUBNODE, postCustomerSubnode)
  yield takeEvery(ADD_MACHINE_TO_CUSTOMER, postMachineToCustomer)
  yield takeEvery(REMOVE_MACHINE_FROM_NODE, postDisconnectMachineFromNode)
  yield takeEvery(FETCH_CUSTOMER_INVOICE, fetchCustomerInvoice)
  yield takeEvery(REMOVE_CUSTOMER, postRemoveCustomer)
  yield takeEvery(UNHIDE_CUSTOMER, postUnhideCustomer)
  yield takeEvery(REMOVE_ADDRESS_FROM_NODE, postRemoveAddressFromNode)
  yield takeEvery(CHANGE_NODE_ADDRESS, postChangeNodeAddress)
  yield takeEvery(RENAME_NODE, postRenameNode)

}