let getAccessTokenSilently: () => Promise<string>


export const adminRoles = [
  'UserAdmin',
  'SystemAdmin'

] as const

export type AdminRoles = typeof adminRoles[number]

export const userRoles =[
  "UserViewer",
  "OrganisationViewer"
]

export type UserRoles =  typeof adminRoles[number]



export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently(),
  setAccessTokenSilently: (func: () => Promise<string>) => (getAccessTokenSilently = func)
}