
import React from "react"
import { SubpageTitle } from "../atoms/text/titles"
import {Text} from "../atoms/text/text"
import { padding } from "../../theme";

export const KPIDisplay = ({value, description}:{value:string, description: string}) => {

    return (
      <view
        style={{
          paddingRight: padding,
          flex: 1,
          flexDirection: "column",
          alignItems: "start",
        }}
      >
          <SubpageTitle noPadding title={value} />
          <Text description >{description}</Text>
      </view>
    );
}