import React, { useCallback } from "react"
import { PricesResponse } from "../types"
import { DateTime } from "luxon"
import TableComponent from "../../../components/molecules/table"

export const columnNames = [{
    displayName: 'From',
    serverName: 'valid_from'
  }, {
    displayName: 'To',
    serverName: 'valid_until'
  },{
    displayName: 'Consumer Price',
    serverName: 'price'
  },{
    displayName: 'Currency',
    serverName: 'currency'
  }
  ]

export const ConsumerPricesListTable = ({prices}:{
    prices: Array<PricesResponse>
}) => {

    const PriceListTable = useCallback(() => {
        return (
          <TableComponent
            // countPerPage={countPerPage}
            // page={page}
            // sortingOn={sortingOn}
            // descending={descending}
            // setCountPerPage={setCountPerPage}
            // setPage={setPage}
            // setSortingOn={setSortingOn}
            // setDescending={setDescending}
            // onClickRow={(id: string) => navigate(id)}
            columnNames={columnNames}
            rows={prices.map((price) => {
              return {
                'From': DateTime.fromISO(price.valid_from).toLocaleString(DateTime.DATE_MED),
                'To': price.valid_until ? DateTime.fromISO(price.valid_until).toLocaleString(DateTime.DATE_MED) : 'ongoing',
                'Price': price.price,
                'Currency': price.currency,
              }
            })}
            total={prices.length ?? 0} />
        )
    }) //, [sortedMachines, countPerPage, page, sortingOn, descending, navigate])

    return (
        // state.fetchingState !== 'failed' ?
        //   (state.fetchingState === 'loading' ? <DataLoadingText showSpinner text={'Loading machines ...'} />
        //     : sortedMachines.total > 0
        //       ? 
              <PriceListTable /> 
              //: <DataLoadingText text={'No machines available'} />)
        //   : <DataLoadingText text={'Failed fetching machines'} />
        // )
    //}, [state.fetchingState, sortedMachines, MachinesTable]))
    )
}


