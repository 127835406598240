import * as React from 'react'
import { useCallback } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import Page from '../../components/molecules/page'
import { StoreState } from '../../state/configureStore'
import { fetchCustomers } from '../../state/actions/customer'
import { useNavigate } from 'react-router-dom'
import CustomersTable from './customersTable'
import DeleteCustomerDialog from './modals/deleteCustomerDialog'
import ShowHiddenSwitch from '../../components/atoms/showHiddenSwitch'
import { CreateCustomerView } from './createCustomer'


function Customers(
  state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {

  const navigate = useNavigate()

  React.useEffect(() => {
    state.fetchCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    navigate("68f2cdaa-d6c1-4208-ab11-91bb6cdb7fe7")
  })

  const CustomerTableSection = () => {
    const [deleteDialogOpenFor, setDeleteDialogOpenFor] = React.useState<string>('')

    const CustomerTable = useCallback(({ onClickDelete, showHiddenCustomers }) => {
      return <CustomersTable onClickDelete={onClickDelete} customers={state.customers.customers.filter(c => showHiddenCustomers || !c.hidden)} onClickRow={(id: string) => navigate(id)} />
    }, [state.customers, navigate, state.settings.showHidden])

    const hasHiddenCustomers = state.customers.customers?.findIndex(c => c.hidden) > -1
    // TODO: soft transition when hidden customers come into table
    return (
      <React.Fragment>
        <DeleteCustomerDialog
          nodeTypeName='customer'
          closeModal={() => { setDeleteDialogOpenFor('') }}
          open={deleteDialogOpenFor ? true : false}
          customer={state.customers.customers.find(u => u.id === deleteDialogOpenFor)!}
        />
        {state.fetchingState !== 'failed' ?
          state.fetchingState === 'loading' ? <DataLoadingText showSpinner text={'Loading customers ...'} />
            : <React.Fragment>
              <CreateCustomerView />
              {
                state.customers.total > 0 ?
                  <CustomerTable showHiddenCustomers={state.settings.showHidden} onClickDelete={(id) => { setDeleteDialogOpenFor(id) }} />
                  : <DataLoadingText text={'No customers available.'} />
              }
              {hasHiddenCustomers ? <ShowHiddenSwitch /> : null}
            </React.Fragment>
          : <DataLoadingText text={'Failed to fetch customers from the server.'} />}
      </React.Fragment>
    )
  }

  return (
    <Page title={'Customers'} pageSections={[<CustomerTableSection />]} />
  )
}

const mapStateToProps = (state: StoreState) => ({
  fetchingState: state.customers_fetching,
  customers: state.customers,
  settings: state.settings
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchCustomers: () => dispatch(fetchCustomers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Customers)