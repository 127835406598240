import { User } from '@auth0/auth0-react'
import { AddUserType, UpdateUserType, UserActionTypes } from '../actionTypes/user'

export const loggedIn = (user: User) : UserActionTypes => ({
  type: 'LOGGED_IN',
  user
})

export const loggedOut = () : UserActionTypes => ({
  type: 'LOGGED_OUT'
})

export const fetchUsers = (countPerPage?: number, page?: number, sortBy?: string, nameFilter?: string) : UserActionTypes => ({
 type: 'FETCH_USERS',
 countPerPage,
 page,
 sortBy,
 nameFilter
})

export const addUser = (userData: AddUserType) : UserActionTypes => ({
  type: 'ADD_USER',
  user: userData
})

export const removeUser = (userId: string) : UserActionTypes => ({
  type: 'REMOVE_USER',
  userId: userId,
})

export const updateUser = (userData: UpdateUserType) : UserActionTypes => ({
  type: 'UPDATE_USER',
  userData
})


export const assignOrganisations = (organisations: string[], userId: string): UserActionTypes =>({
  type: "ASSIGN_ORGANISATION",
  organisations: organisations,
  userId: userId 
})
