import { useAuth0 } from "@auth0/auth0-react"
import { CommandServices, QueryServices } from '../config'
import { CommandBase, Schema } from "../common-types/commandingTypes"


export interface PagedResponse<T> {
    total: number
    paging: {count:number, page:number}
    items: Array<T>
}

type RejectReason = 'Unauthorized' | 'Forbidden' | 'InputValidation' | 'StateInvalid' | 'EntityNotFound'

interface RequestPromise<T> {
  Data: (model: (model:T) => void) => RequestPromise<T>
  Empty: (onEmpty: () => void) => RequestPromise<T>
  Rejected: (onRejected: (reason: RejectReason, result?: T) => void) => RequestPromise<T>
  Catch: (onCatch:(e: Error) => void) => RequestPromise<T>
}

interface GetInterface {
    Get: <ResultT>(path: string) => RequestPromise<ResultT>
}

export interface GetCommandResponse<T extends CommandBase> {
    command: T
    schema: Schema<T>
    
}

interface CommandResponse<T> {
    message: string
    command: T
    data?: any
}

interface CommandPostInterface {
    PostCommand: <CommandT>(path: string, command: CommandT) => RequestPromise<CommandResponse<CommandT>>
}

interface CommandBackendInterface extends GetInterface, CommandPostInterface {}
interface QueryBackendInterface extends GetInterface {}

export interface CommandServicesType {
    [key:string]: (tokenTask:() => Promise<string>) => CommandBackendInterface
}

export interface QueryServiceType {
    [key:string]: (tokenTask:() => Promise<string>) => QueryBackendInterface
}


export const useCommandBackend = (service: keyof typeof CommandServices ): CommandBackendInterface => {
    const auth0 = useAuth0()
    return CommandServices[service](auth0.getAccessTokenSilently)
}

export const useQueryBackend = (service: keyof typeof QueryServices): QueryBackendInterface => {
    const auth0 = useAuth0()
    return QueryServices[service](auth0.getAccessTokenSilently)
}

export class BackenAccess implements GetInterface, CommandPostInterface
{
    private tokenTask: () => Promise<string>
    private hostAddress: string

    constructor(hostAddress: string, getToken: () => Promise<string>) {
        this.tokenTask = getToken
        this.hostAddress = hostAddress
    }
    PostCommand = <CommandT>(path: string, command: CommandT) => {
        const url = new URL(path, this.hostAddress)
        return new RequestHandler<CommandResponse<CommandT>>(url, () => 
            this.tokenTask()
                .then(token => 
                     fetch(url, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify(command)
                    })
        ))
    }

    Get = <ResultT>(path: string) => {
        const url = new URL(path, this.hostAddress)
        return new RequestHandler<ResultT>(url, () => 
            this.tokenTask()
                .then(token => 
                     fetch(url, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                    })
        ))
    }
}

class RequestHandler<TResult> implements RequestPromise<TResult> {
    url: URL
    constructor(url: URL, executor: () => Promise<Response>) {
        this.url = url
        executor()
            .then(this.onResolve)
            .catch(this.onCatch)
    }

    private onResolve = (data: Response) => {
        console.info('onResolve', data)
        if(data.status === 200)
            data.json()
                    .then(json => this.onData(json as TResult))

        else if(data.status === 202) 
            data.json()
                .then(json => this.onData(json as TResult))

        else if(data.status === 204)
            this.onEmpty()
        
        else if(data.status === 401)
            this.onRejected("Unauthorized")

        else if(data.status === 403)
            this.onRejected("Forbidden")

        else if(data.status === 404) 
            this.onCatch(new Error(`404 Not found`))

        else if(data.status === 417)
            data.json()
                .then(json => this.onRejected("InputValidation", json as TResult))
            
        else if(data.status === 409)
            data.json()
                .then(json => this.onRejected("StateInvalid", json as TResult))

        else if(data.status === 422)
            data.json()
                .then(json => this.onRejected("EntityNotFound", json as TResult))
                        
        else
            this.onCatch(new Error(data.statusText))
    }


    private onData : (model: TResult) => void = (model) => { console.log(`Request to ${this.url} returned`, model) }
    public Data = (onResolve: (model:TResult) => void) => {
        this.onData = onResolve
        return this
    }

    private onEmpty: () => void = () => { console.warn(`Request to ${this.url} returned an empty response`) }
    public Empty = (onEmpty: () => void) => {
        this.onEmpty = onEmpty
        return this
    }

    private onRejected: (reason: RejectReason, result?: TResult) => void = (reason, result) => { console.warn(`Request rejected with ${reason}. ${result}`) }
    public Rejected = (onRejected:(reason: RejectReason) => void) => {
        this.onRejected = onRejected
        return this
    }

    private onCatch: (e: Error) => void = (e) => {console.error(`Request (${this.url}) failed with ${e}`)}
    public Catch = (onCatch:(e: Error) => void) => {
        this.onCatch = onCatch
        return this
    }
}

