export type TableList<T> = {
  list: T[],
  total: number
}

export function sortListForTable<T> (
  list: T[],
  countPerPage: number,
  page: number,
  sortingOn: string,
  descending: boolean): TableList<T> {
  const toSort = [...list] // sort sorts IN PLACE -> this prevents modifying the original array
  if (!toSort?.length) return { list: [], total: 0 }
  toSort.sort((a, b) => {
    return descending ? a[sortingOn] < b[sortingOn] ? 1 : -1
      : a[sortingOn] > b[sortingOn] ? 1 : -1
  })

  const startIndex = countPerPage * page
  const endIndex = startIndex + countPerPage

  return {
    list: toSort.slice(startIndex, endIndex),
    total: toSort.length
  }
}