import React from "react"
import { DateTime } from "luxon"

export const MonthStepControl = ({year, month, onChangeMonth = (year, month) => {}}:{year:number, month:number, onChangeMonth?: (year:number, month:number) => void}) => {
    const currentMonth = DateTime.fromObject({year:year, month:month})
    const prevMonth = currentMonth.minus({month:1})
    const nextMonth = currentMonth.plus({month:1})
    const dateFormat = 'yyyy MMMM'

    return (
        <div>
            <span style={{display:"inline-block", cursor:"pointer"}} onClick={() => onChangeMonth(prevMonth.year, prevMonth.month)}>&lt;&lt;</span>
            <span style={{width:"150px", display:"inline-block", textAlign:"center"}}>{currentMonth.toFormat(dateFormat)}</span>
            <span style={{display:"inline-block", cursor:"pointer"}} onClick={() => onChangeMonth(nextMonth.year, nextMonth.month)}>&gt;&gt;</span>
        </div>
    )
}