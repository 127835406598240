import { DateTime } from 'luxon'
import React, { Dispatch, useEffect, useState } from 'react'
import CustomButton from '../../components/molecules/button'
import TableComponent from '../../components/molecules/table'
import { UserState } from '../../state/reducers/user'
import { sortListForTable, TableList } from '../../utils/lists'
import AddUserModal from './addUserModal'
import { StoreState } from '../../state/configureStore'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { AdminRoles, adminRoles } from '../../utils/auth0Helper'
import { User } from '@auth0/auth0-react'

type SortOn = 'created' | 'name' | 'email'

const columnNames = [
  {
    displayName: 'Name',
    serverName: 'name'
  },
  {
    displayName: 'Email',
    serverName: 'email'
  },
  {
    displayName: 'Created',
    serverName: 'created'
  }
]

const UserTable = ({authenticated_user, users, removeUser, updateUser}: {authenticated_user: User | null, users: UserState[], removeUser: Function, updateUser: Function}) => {
  const [countPerPage, setCountPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [sortingOn, setSortingOn] = useState<SortOn>('created')
  const [descending, setDescending] = useState<boolean>(false)
  const hasUserAdminPermission = authenticated_user?.organisationRoles?.filter(o => adminRoles.includes(o.role) )
  const [sortedUsers, setSortedUsers] = useState<TableList<UserState>>({list: users, total: users.length})

  useEffect(() => {
    setSortedUsers(sortListForTable(users, countPerPage, page, sortingOn, descending))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countPerPage, page, sortingOn, descending])

  return (
    <React.Fragment>
      {hasUserAdminPermission &&
      <AddUserButton />
}
      <TableComponent
        countPerPage={countPerPage}
        page={page}
        sortingOn={sortingOn}
        descending={descending}
        setCountPerPage={setCountPerPage}
        setPage={setPage}
        setSortingOn={setSortingOn}
        setDescending={setDescending}
        columnNames={columnNames}
        onClickDelete={removeUser}
        onClickEdit={updateUser}
        rows={sortedUsers.list.map((user) => {
          return {
            'id': user.userId,
            'Name': user.name,
            'Email': user.email,
            'Created': DateTime.fromISO(user.created).toLocaleString(DateTime.DATETIME_MED)
          }
        })}
        total={sortedUsers?.total ?? 0} />
    </React.Fragment>
  )
}

const AddUserButton = () => {
  const [addUserOpen, setAddUserOpen] = useState<boolean>(false)
  return (
    <React.Fragment>
      <CustomButton noBg onClick={(event) => {
        setAddUserOpen(true)
      }}>
        {'+ Add User'}
      </CustomButton>

      <AddUserModal open={addUserOpen} closeModal={() => setAddUserOpen(false)} />
    </React.Fragment>
  )
}

const mapStateToProps = (state: StoreState) => ({
  authenticated_user: state.authenticated_user
})


const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(UserTable)