import React, { useEffect, useState } from "react"
import { CustomButton } from "../../../components/atoms/buttons"
import { GetCommandResponse, useCommandBackend } from "../../../utils/requestHelpers"
import { CreateCustomerCommand } from "./types"
import { CreateCustomerForm } from "./createCustomerForm"
import { CommandWithSchema } from "../../../common-types/commandingTypes"


export const CreateCustomerView = ({onCreated = () => {}}:{onCreated?: (command: CreateCustomerCommand) => void}) => {
    const commandApi = useCommandBackend("Backoffice")
    const [showDialog, setShowDialog] = useState<boolean>()
    const [command, setCommand] = useState<CommandWithSchema<CreateCustomerCommand> | undefined>()

    useEffect(() => {
        refreshCommad()
    }, [])

    const refreshCommad = () => 
        commandApi.Get<GetCommandResponse<CreateCustomerCommand>>('/cmd/company/create/')
            .Data(setCommand)
            .Empty(() => setCommand(undefined))
            .Rejected(() => setCommand(undefined))


    const handleSave = (command: CreateCustomerCommand) => {
        commandApi.PostCommand('/cmd/company/create', command)
            .Data(result => {
                onCreated(result.command)
                setShowDialog(false)
                refreshCommad()
            })
            .Rejected((r, result) => console.log('Rejected', r, result))
    }

    return (
        <>
            <CustomButton noBg onClick={() => {setShowDialog(command !== undefined)}} disabled={command === undefined}>
                {'+ Add Customer'}
            </CustomButton>
            {showDialog && <CreateCustomerForm command={command!} onClose={() => setShowDialog(false)} onSave={handleSave} /> }
        </>
    )
}