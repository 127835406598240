import React, { useEffect, useState } from "react"
import { DateTime } from 'luxon'
import { FetchFromBackoffice } from "../../../utils/request"
import { IPage, IPagedResponse } from "../../../common-types"
import { Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material"
import { ContentMenu, MenuItemProps } from "../../../components/atoms/menu"
import { ExcludeFromInvoice } from "./exclude-from-invoice"
import { SavingReducer } from "../../../state/reducers/general"
import { PostToBackoffice } from "../../../utils/commands"
import { toReadableTime } from '../../../functions'
import { SoldRecipes } from "../../customers/customer"

type RecipeItem = {
    transactionId:string,
    serialNumber:string,
    machineName:string,
    startedAt: string,
    endedAt: string | undefined,
    status: string,
    recipeName: string,
    excludedFromInvoicing: boolean,
    excludedFromInvoicingReason: string | undefined,
}


export const RecipesList = ({MachineId}:{MachineId:string|undefined}) => {
    const [excludeFromInvoice, setExcludeFromInvoice] = useState<{transactionId:string, reasonText:string} | undefined>()
    const [recipes, setRecipes] = useState<RecipeItem[]>([])
    const [savingState, setSavingstate] = useState<SavingReducer>('ready')
    const [paging, setPaging] = useState<IPage>({count:10, page:0})
    const [total, setTotal] = useState<number>(0)

    useEffect(() => {
        FetchFromBackoffice<IPagedResponse<RecipeItem>>(`machines/${MachineId}/recipes?page=${paging.page}&count=${paging.count}`)
            .then(data => {
                setRecipes(data.items)
                setTotal(data.total)
            })
    }, [MachineId, paging])


    const ExcludeFromInvoicing = (reason:string) => {
        if(excludeFromInvoice) {
            setSavingstate('saving')
            PostToBackoffice('/cmd/machine/exclude/run/invoice', {
                id: MachineId,
                transactionId: excludeFromInvoice.transactionId,
                reason: reason,
            })
                .then(r => {
                    setRecipes(c => c.map(r => r.transactionId === excludeFromInvoice.transactionId ? {...r, excludedFromInvoicingReason: reason} : r))
                    setSavingstate('ready')
                    setExcludeFromInvoice(undefined)
                })
                .catch(e => setSavingstate('failed'))
        }
    } 

    return (
        <>
        <SoldRecipes id={MachineId} queryString={"recipes-run-daily-machine"}/>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Started at</TableCell>
                    <TableCell>Recipe</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {recipes.map((item, idx) => <RecipeItemView key={idx} Index={idx} {...item} excludeFromInvoicing={(transactionId, reasonText) => setExcludeFromInvoice({transactionId, reasonText})} />)}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        count={total}
                        page={paging.page}
                        rowsPerPage={paging.count}
                        onPageChange={(_, page) => setPaging({count: paging.count, page:page})}
                        onRowsPerPageChange={(e) => setPaging({count: Number.parseInt(e.target.value) ?? 10, page:0})}
                    />
                 </TableRow>
            </TableFooter>
        </Table>
        <ExcludeFromInvoice onSave={(s) => ExcludeFromInvoicing(s)} Open={excludeFromInvoice ? true : false} ReasonText={excludeFromInvoice?.reasonText} SavingState={savingState} onClose={() => setExcludeFromInvoice(undefined)} />
        </>
    )
}

const RecipeItemView = ({transactionId, startedAt, endedAt, status, recipeName, excludedFromInvoicingReason, Index, excludeFromInvoicing} : RecipeItem & {Index:number, excludeFromInvoicing: (transactionId:string, reasonText:string) => void}) => {
    let duration = '-'
    if(endedAt) {
        duration = DateTime.fromISO(endedAt).diff(DateTime.fromISO(startedAt)).toFormat("mm:ss")
    }
    const started = toReadableTime(DateTime.fromISO(startedAt), DateTime.now())//DateTime.fromISO(startedAt).toFormat("yyyy-MM-dd hh:mm")
    
    let classes = '';
    switch(status){
        // case 'done':
        //     classes += ` highlight-ok`
        //     break;
        case 'canceled':
            classes += ` highlight-fail`
            break;
    }

    if(excludedFromInvoicingReason){
        classes += ` invoice-excluded`
    }

    const menus:MenuItemProps[] = [{Label: 'No invoicing', onClick: () => excludeFromInvoicing(transactionId, excludedFromInvoicingReason??'')}]

    return (
        <TableRow className={classes}>
            <TableCell>{started}</TableCell>
            <TableCell>{recipeName}</TableCell>
            <TableCell>{duration}</TableCell>
            <TableCell>{excludedFromInvoicingReason}</TableCell>
            <TableCell><ContentMenu Layout='Row' Id={`recipe_menu_${Index}`} MenuItems={menus} /></TableCell>
      </TableRow>
    )
}