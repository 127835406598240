import React, { useEffect, useState } from "react";
import { SelectInput } from "../../atoms/inputs";
import { ValidatableInputProps } from "./types";
import { haveErrors, isRequired } from "./functions";
import { CommandBase } from "../../../common-types/commandingTypes";
import { Nullable } from "../../../common-types";


export const ValidatableSelectInput = <TCommand extends CommandBase, TKey extends keyof TCommand & string,>({
    property,
    command,
    update,
    schema,
    title,
    items,
}:{items?:Array<string>} & ValidatableInputProps<TCommand, TKey>) => {
    const [validate, setValidate] = useState<boolean>(false)
    const [error, setError] = useState<boolean>()
 
    title = title ?? schema.properties[property]?.title ?? 'missing title'
    items = items ?? schema.properties[property]?.enum ?? new Array<string>()
    const value = command[property] as Nullable<string>
    const required = isRequired(schema, property)
 
    useEffect(() => {
        if(validate) {
            const errors = haveErrors(schema, command, property)
            setError(errors.length > 0)
        }
    }, [value, required, validate])


    return (
        <SelectInput 
            title={title}
            value={value}
            required={required}
            onChange={v => update(c => ({...c, [property]: v}))}
            items={items}
            
            />
    )
}