import React, { Fragment, useEffect, useState } from "react"
import { Box } from "@mui/material"
import { SubpageTitle } from "../../../components/atoms/text/titles"
import { useQueryBackend } from "../../../utils/requestHelpers"
import { Nullable } from "../../../common-types"
import { getDistinctPropertyValues } from "../../../functions"
import { MonthStepControl } from "../../atoms/navigations"
import { BarChartView, IDataItem } from "../../atoms/charts"
import { DateTime } from "luxon"
import { breakpoints } from "../../../theme"


interface CyclesItem {
    day: string,
    store: Nullable<string>
    cycles: number
}

export const StoresDailySales = ({nodeId}:{nodeId?:string | undefined}) => {
    const queryApi = useQueryBackend("Backoffice")
    const [year, setYear] = useState<number>(DateTime.now().year)
    const [month, setMonth] = useState<number>(DateTime.now().month)
    const [data, setData] = useState<Array<IDataItem>>([])

    useEffect(() => {
        const from = DateTime.fromObject({year, month})
        const to = from.plus({month:1})

        const path = nodeId ? 
                      `customer/nodes/${nodeId}/stores/daily/between/${from.toFormat("yyyy-MM-dd")}/and/${to.toFormat("yyyy-MM-dd")}`
                     :`customer/nodes/stores/daily/between/${from.toFormat("yyyy-MM-dd")}/and/${to.toFormat("yyyy-MM-dd")}`
            

        queryApi.Get<Array<CyclesItem>>(path)
            .Data(d => {
                const allDays = getDistinctPropertyValues(d, "day")
                const allStores = getDistinctPropertyValues(d, "store")

                const items = allDays.map(day => {
                    const item:IDataItem = {name:day}
                    allStores.filter(s => !!s).forEach(k => {
                        item[k!] = d.find(d => d.day === day && d.store === k)?.cycles ?? 0
                    })
                    return item
                })
                setData(items)
            })
            
    }, [nodeId, month, year])


    const formatX = s => s.substring(s.lastIndexOf('-') + 1)
    return (
        <Box>
            <SubpageTitle title={'Sales per store'}  />
            <MonthStepControl year={year} month={month} onChangeMonth={(year, month) => {setYear(year); setMonth(month)}}/>
            
            <Fragment>
              <Box sx={{ width: '100%', height: '400px', [breakpoints.fromWidth(1200)]: { display: "none" }}}>
                <BarChartView mobileStyling items={data} formatX={formatX} />
              </Box>

              <Box sx={{ width: '100%', height: '400px', [breakpoints.untilWidth(1200)]: { display: "none" } }}>
                <BarChartView items={data} formatX={formatX} />
              </Box>
            </Fragment>
        </Box>
    )
}