import React from "react"
import { Delete } from "@mui/icons-material"
import { ButtonProperties } from "./types"
import { IconButtonBase } from "./button"

export const DeleteButton = ({title, enabled, color = "primary" , onClick}: ButtonProperties) => {

    return (
        <IconButtonBase 
            title={title}
            onClick={onClick} 
            enabled={enabled}
            >
            <Delete color={color} />
        </IconButtonBase>        
    )
}