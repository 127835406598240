import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import Page from '../../components/molecules/page'
import { StoreState } from '../../state/configureStore'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {  fetchCustomer, removeMachineFromCustomer } from '../../state/actions/customer'
import { CustomerNode } from '../../state/reducers/customer'
import { SubpageTitle } from '../../components/atoms/text/titles'
import AddressDisplay from './address'
import { Box } from '@mui/system'
import { findNodeForId } from '../../utils/customerUtils'
import CustomerOrganisationsTable from './customerOrganisationsTable'
import CustomerMachinesTable from './customerMachinesTable'
import AddCustomerSubnodeModal from './modals/addCustomerSubnodeModal'
import CustomButton from '../../components/molecules/button'
import { Divider, Paper, Typography } from '@mui/material'
import InteractiveMap from '../../components/molecules/maps/interactive/interactiveMap'
import AddMachineToCustomerModal from './modals/addMachineToCustomerModal'
import { Edit, Alarm, Info, Receipt, RequestQuote, VisibilityOff, ReceiptOutlined } from '@mui/icons-material'
import InvoiceCreation from './invoiceCreation'
import InvoiceDisplay from './invoiceDisplay'
import UnhideCustomerDialog from './modals/unhideCustomerDialog'
import { setShowHidden } from '../../state/actions/app'
import EditNodeModal from './modals/editNodeModal'
import { Subscriptions } from './alertsubscriptions'
import { PostToBackoffice } from '../../utils/commands'
import { EditInvoiceCustomerNumber } from './modals/editInvoiceCustomerNumber'
import { SavingReducer } from '../../state/reducers/general'
import PriceModelView from './pricemodel'
import { InvoicePage, MonthSelector } from './invoice/invoicePage'
import SelectableBarChart from '../../components/barchart'
import { DateTime } from 'luxon'
import { useQueryBackend } from '../../utils/requestHelpers'
import { InvoiceResponse } from './invoice/types'
import CyclesBarChart from '../../components/barchart'
import { UsersView } from './users'
import { padding } from '../../theme'

const getOrgType = (org: string) => {
  if (org === 'Offices') return 'Office'
  else if (org === 'Companies') return 'Company'
  else if (org === 'Stores') return 'Store'
  else return 'Suborganisation'
}

const getChildOrgType = (org: string) => {
  if (org === 'Companies') return 'Store'
  else return 'Suborganisation'
}

type AddSubnodeButtonProps = { customer: CustomerNode }
const AddSubnodeButton = ({ customer }: AddSubnodeButtonProps) => {
  const [addSubnodeOpen, setAddSubnodeOpen] = React.useState<boolean>(false)

  return (
    <React.Fragment>
      <CustomButton noBg onClick={(event) => {
        setAddSubnodeOpen(true)
      }}>
        {'+ Add ' + getChildOrgType(customer.levelName)}
      </CustomButton>

      <AddCustomerSubnodeModal parentId={customer.id} locationIs={'visiting'} open={addSubnodeOpen} closeModal={() => setAddSubnodeOpen(false)} />
    </React.Fragment>
  )
}

type AddMachineToCustomerButtonProps = { customer: CustomerNode }
const AddMachineToCustomerButton = ({ customer }: AddMachineToCustomerButtonProps) => {
  const [addSubnodeOpen, setAddSubnodeOpen] = React.useState<boolean>(false)

  return (
    <React.Fragment>
      {!customer?.hidden ? <CustomButton noPadding noBg onClick={(event) => {
        setAddSubnodeOpen(true)
      }}>
        {'+ Add Machine'}
      </CustomButton> : false}

      <AddMachineToCustomerModal isCustomerRootNode={customer.levelName === 'Companies'} customerId={customer.id} customerName={customer.name} open={addSubnodeOpen} closeModal={() => setAddSubnodeOpen(false)} />
    </React.Fragment>
  )
}

function Customer(
  state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {

  const navigate = useNavigate()
  //let { customerId } = useParams(); // top node in node tree
  let { pathname } = useLocation()

  const nodeIds = pathname?.split('/')?.filter(x => x !== '' && x !== 'customers') ?? []
  const currentNodeId = nodeIds?.length ? nodeIds[nodeIds.length - 1] : null // current node in node tree

  const [customer, setCustomer] = useState<CustomerNode | undefined>(undefined)
  const customerParentHidden = customer?.parents?.find(p => p.hidden) ? true : false

  const [unhideCustomerDialogOpen, setUnhideCustomerDialogOpen] = useState<boolean>(false)
  const [editNameModalOpen, setEditNameModalOpen] = useState<boolean>(false)
  const [editInvoiceNumberOpen, setEditInvoiceNumberOpen] = useState<boolean>(false)
  const [editInvoiceNumberState, setEditInvoiceNumberState] = useState<SavingReducer>('ready')

  const UpdateCustomerInvoiceNumber = (newNumber:string | undefined) => {
    setEditInvoiceNumberState('saving')
    PostToBackoffice('/cmd/node/invoicenumber', {id: customer?.id, number: newNumber})
     .then(d => {
        setCustomer(c => c ? {...c, invoiceNumber:newNumber }:undefined)
        setEditInvoiceNumberOpen(false)
    })
     .catch(e => setEditInvoiceNumberState('failed'))
  }

  useEffect(() => {
    if (state.savingState === 'ready') setEditNameModalOpen(false)
  }, [state.savingState])

  useEffect(() => {
    if (currentNodeId) {
      state.fetchCustomer(currentNodeId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNodeId])

  const stringifiedCustomers = JSON.stringify(state.customers?.customers) // so we notice changes deepter into the structure
  useEffect(() => {
    if (currentNodeId) {
      setCustomer(findNodeForId(state.customers?.customers, currentNodeId))
    }
    setUnhideCustomerDialogOpen(false)
  }, [state.customers?.customers, stringifiedCustomers, currentNodeId])

  // if we opened a hidden custome, we want to see its hidden children, too
  useEffect(() => {
    if (customer?.hidden && !state.settings.showHidden) {
      state.setShowHidden(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  const SuborganisationsTable = useCallback(() => {
    return customer ? <CustomerOrganisationsTable showHidden={state.settings.showHidden} customers={customer?.childs?.nodes ?? []} onClickRow={(id: string) => {
      navigate(pathname + '/' + id)
    }} /> : null
  }, [customer, navigate, pathname, state.settings.showHidden])

  const removeMachineFromCustomer = state.removeMachineFromCustomer
  const MachinesTable = useCallback(() => {
    return customer ? <CustomerMachinesTable
      machines={customer?.machines ?? []}
      deleteMachineFromCustomer={(id) => {
        const nodeId = customer?.machines?.find(d => d.id === id)?.nodeId
        if (nodeId) removeMachineFromCustomer(id, nodeId)
        else console.error('No customerId available, cannot remove machine.')
      }}
      onClickRow={(id) => {
        navigate('/machines/' + id)
      }} /> : null
  }, [customer, navigate, removeMachineFromCustomer])

  const SuborganisationsTableSection = useCallback(() => {
    return (
      state.fetchingState !== 'failed' ?
        (state.fetchingState === 'loading' ? null
          : <React.Fragment>
            <SubpageTitle title={customer?.childs?.levelName ?? 'Suborganisations'} />
            {/* {
              customer?.childs?.nodes?.filter(n => n.location !== undefined && n.location !== null)?.length ?
                <Box style={{
                  width: '100%',
                  height: 300,
                  display: 'flex',
                  alignSelf: 'center',
                }}>
                  <InteractiveMap locations={customer.childs?.nodes?.filter(
                    n => (n.location !== undefined) && (n.location !== null) &&
                      (state.settings.showHidden || !n.hidden)
                  ).map(n => n.location!) ?? []} />
                </Box> : null
            } */}
            {customer && !customer?.hidden ? <AddSubnodeButton customer={customer} /> : false}
            {
              customer?.childs?.nodes && customer.childs.nodes.length > 0
                ? <SuborganisationsTable />
                : <Typography variant="body1">{'There are no suborganisations connected to this organisational unit.'}</Typography>
            }
          </React.Fragment>)
        : <DataLoadingText text={'Failed to fetch customer information from the server.'} />
    )
  }, [state.fetchingState, JSON.stringify(customer), SuborganisationsTable])

  const MachineTableSection = useCallback(() => {
    return (
      state.fetchingState !== 'failed' ?
        state.fetchingState === 'loading' ? null
          : <React.Fragment>
            <SubpageTitle title={'Machines'} />
            {/** disable for root customer for now, because we don't have a good way of getting the org structure */}
            {/** once we have the server endpoint: put this back in */}
            {customer && customer.parentId ? <AddMachineToCustomerButton customer={customer} /> : false}
            {
              customer?.machines && customer.machines.length > 0
                ? <MachinesTable />
                : <Typography variant="body1">{'There are no machines connected to this organisational unit.'}</Typography>
            }
          </React.Fragment>
        : <DataLoadingText text={'Failed to fetch customer information from the server.'} />
    )
  }, [state.fetchingState, customer, MachinesTable])

  const isRootCustomerNode = customer?.levelName === "Companies"
  const showLocationMap = !isRootCustomerNode
  const dividerComponent = <Divider orientation='vertical' variant="middle" flexItem />
  
  const OrganisationInformationSection = useCallback(() => {
    const invoiceAddress = customer?.invoiceAddress?({...customer?.invoiceAddress, streetAddress: customer.invoiceAddress?.addressLine1??'', extraAddress:customer.invoiceAddress?.addressLine2}):undefined
    const visitAddress = customer?.visitingAddress?({...customer?.visitingAddress, streetAddress: customer.visitingAddress?.addressLine1??'', extraAddress:customer.visitingAddress?.addressLine2}):undefined

    return (
      <React.Fragment>
        <SubpageTitle title={(customer && customer.levelName ? getOrgType(customer.levelName) + ' ' : '') + 'Information'}  />

        {customer ?
          <Box style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}>
            <Box style={{ paddingRight: '2.5%' }}>
              <AddressDisplay nodeId={customer.id} addressType="Invoice" isRootNode={isRootCustomerNode} address={invoiceAddress} invoiceNumber={customer.invoiceNumber} />
            </Box>

            {dividerComponent}

            <Box style={{ paddingRight: '2.5%', paddingLeft: '2.5%' }}>
              <AddressDisplay nodeId={customer.id} addressType="Visit" isRootNode={isRootCustomerNode} address={visitAddress} />
            </Box>

            {
              showLocationMap ? customer?.location ? <React.Fragment>
                <Box style={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  height: 300,
                }}>
                  <InteractiveMap locations={customer.location ? [customer.location] : []} />
                </Box>
              </React.Fragment> : <Typography variant="body1">{"This store's location can not be rendered on the map. Please check that you entered it correctly."}</Typography>
                : null
            }
          </Box>
          
          : <Typography variant="body1">{'There is no customer information to display.'}</Typography>

        }
      </React.Fragment>
    )
  }, [customer])

  const pageSections = [state.fetchingState === 'loading' ?
    <DataLoadingText showSpinner text={'Loading customer information ...'} />
    : state.fetchingState === 'failed' ? <DataLoadingText showSpinner text={'Could not fetch customer information.'} />
      : <OrganisationInformationSection />
  ]

  // in this iteration, we don't allow an organisation depth deepter than 2
  if(currentNodeId) pageSections.push(<SoldRecipes id={currentNodeId} queryString={"recipes-run-daily"} showTitle={true}/>)
  if (customer?.levelName === "Companies" && state.fetchingState !== 'loading') pageSections.push(<SuborganisationsTableSection />)
  if (state.fetchingState !== 'loading') pageSections.push(<MachineTableSection />)

  return (
    <Page
      greyOut={customer?.hidden || customerParentHidden}
      hideBreadcrumbs={state.fetchingState === 'loading'}
      breadcrumbNames={customer?.parents}
      title={customer?.name ? customer.name + (customer?.hidden ? ' (hidden)' : '') : ''}
      titleIcon={customer?.hidden ? <VisibilityOff color='primary' fontSize='large' /> : undefined}
      Menu={{
        Id:'customer-page-menu',
        ButtonTitle:'Actions',
        MenuItems: [
        {
          Label:'Edit customer name',
          Icon: <Edit />,
          onClick: () => setEditNameModalOpen(true),
        },
        {
          Label:'Unhide',
          Enabled: customer?.hidden,
          onClick: () => setUnhideCustomerDialogOpen(true)
        },
        {
          Label:'Invoice customer number',
          Icon: <ReceiptOutlined/>,
          onClick: () => {
            setEditInvoiceNumberState('ready')
            setEditInvoiceNumberOpen(true)
          }
        }
      ]
      }}

      tabs={customer?.invoiceAddress ? [
        {
          name: 'Overview',
          icon: <Info />,
          pageSections: pageSections
        },
        {
          name: 'Alerts',
          icon: <Alarm/>,
          pageSections: [<Subscriptions nodeId={customer.id} />]
        },
        {
          name: 'Invoices',
          icon: <Receipt />,
          pageSections: [<InvoicePage />]
        },
        {
          name: 'Price model',
          icon: <RequestQuote />,
          pageSections:  [<PriceModelView />]
        },
        // {
        //   name: 'Users',
        //   icon: <RequestQuote />,
        //   pageSections: [<UsersView nodeId={customer.id} />]
        // }
      ] : undefined}
      pageSections={!customer?.invoiceAddress ? pageSections : undefined}
    >
      
      {customer && editNameModalOpen ? <EditNodeModal nameOnly nodeTypeName={customer.levelName === "Companies" ? 'customer' : 'store'} node={customer} open={editNameModalOpen} closeModal={() => setEditNameModalOpen(false)} /> : null}
      {customer ? <UnhideCustomerDialog nodeTypeName={getOrgType(customer.levelName).toLowerCase()} customer={customer} open={unhideCustomerDialogOpen} closeModal={() => { setUnhideCustomerDialogOpen(false) }} /> : null}
      
      {customer &&
        <EditInvoiceCustomerNumber 
            InvoiceNumber={customer.invoiceNumber} 
            Open={editInvoiceNumberOpen} 
            SavingState={editInvoiceNumberState}
            onClose={() => setEditInvoiceNumberOpen(false)} 
            onSave={(text) => UpdateCustomerInvoiceNumber(text)} 
            />
        }
    </Page>
  )
}

export const SoldRecipes = ({id, queryString, showTitle}) => {
  const [year, setYear] = useState<number>(new Date().getFullYear()) 
  const [month, setMonth] = useState<number>(new Date().getMonth() +1) 

  const queryApi = useQueryBackend("Backoffice")
  const [recipes, setRecipes] = useState< any| undefined>()
  const  createDateFromComponents = useCallback((year, month, day) => {
      // JavaScript months are 0-based, so January is 0, February is 1, etc.
      const date = new Date(year, month-1, day);
      return date.toISOString();
    },[])
  const getLastDayofMonth = useCallback((year, month)=> {
          const a = new Date(year, month)
          a.setDate(0)
          return a.toISOString()
  },[])
  useEffect(() => {
      const to = getLastDayofMonth(year, month )
      const from = createDateFromComponents(year, month, 1)
        queryApi.Get<any>(`${id ? id + "/" : ""}customer/${queryString}/${from}_${to}`)
            .Data(i => {
                setRecipes(i)
            })
    
}, [id,queryString, setRecipes, year,month])
  return(
    recipes ? 
    <Box style={{flex: 1}} >
            {showTitle  && 
      <SubpageTitle title='Recipies sold' />}
      <Box sx={{paddingLeft: padding * 2, paddingTop: showTitle ?? padding}}>

      <MonthSelector year={year} month={month} onChangeMonth={(year, month) => {setYear(year); setMonth(month)}}/>
      </Box>
      <CyclesBarChart recipes={recipes}/>
    </Box>: <></>
  )
}

const mapStateToProps = (state: StoreState) => ({
  fetchingState: state.customer_fetching,
  customers: state.customers,
  settings: state.settings,
  savingState: state.customer_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchCustomer: (id: string) => dispatch(fetchCustomer(id)),
  removeMachineFromCustomer: (serialId: string, nodeId: string) => dispatch(removeMachineFromCustomer(serialId, nodeId)),
  setShowHidden: (value: boolean) => dispatch(setShowHidden(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Customer)