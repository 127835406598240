import React from 'react';
import {
  Button
} from '@mui/material'

type ButtonProps = ({
  children?: React.ReactNode | null,
  disabled?: boolean,
  outlined?: boolean,
  noBg?: boolean,
  issue?: boolean,
  onClick?: any,
  style?: Object,
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode,
  noPadding?: boolean
})

const CustomButton = ({
  children,
  disabled,
  noBg,
  outlined,
  issue,
  onClick,
  style,
  endIcon,
  startIcon,
  noPadding
}: ButtonProps) => {

  return (
    <Button
      endIcon={endIcon}
      startIcon={startIcon}
      onClick={onClick}
      color={issue ? 'error' : 'primary'}
      variant={noBg ? 'text' : outlined ? 'outlined' : 'contained'}
      disabled={disabled}
      className="print-invisible"
      sx={noPadding ? {
        padding: 0, 
        minWidth:  0,
        ...style,
      } : style}>
      {children}
      
    </Button>
  )
}

export default CustomButton;
