import { KnownErrorCodes, numberToKnownError } from "../../common-types"
import MachineErrorTypes, { MACHINE_ERROR_REMOVED, MACHINE_ERROR_REPORTED, MachineErrorRemoved } from "../actionTypes/machine-errors"

export type MachineErrorsReducer = MachineError[]

export type MachineError = {
    serialId : string,
    machineName: string,
    text : string,
    reportedAt : Date,
    blocking : boolean,
    errorType: KnownErrorCodes | undefined,
}


export const initialMachineErrors : MachineErrorsReducer = []

const isEqual = (err : MachineError, action: MachineErrorRemoved) : boolean => {
    if(err.serialId !== action.message.machineId) {
        return false
    }
    const knownError = numberToKnownError(action.message.code)
    if(err.errorType !== knownError) {
        return false
    }
    return true
}

export const machine_errors = (state: MachineErrorsReducer = initialMachineErrors, action: MachineErrorTypes): MachineErrorsReducer => {
    switch(action.type) {
        case MACHINE_ERROR_REPORTED:
            return [...state, {
                serialId: action.message.machineId,
                machineName: action.message.machineId,
                text: action.message.text,
                blocking: action.message.blocking,
                reportedAt: new Date(action.message.reportedAt),
                errorType: numberToKnownError(action.message.code) 
            }]
        case MACHINE_ERROR_REMOVED:
            return [...state.filter(e =>  isEqual(e, action))]
        default:
            return state
    }
}