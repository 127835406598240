import { Machine } from "../../state/reducers/customer"
import React, { useCallback, useEffect, useState } from 'react'
import TableComponent from "../../components/molecules/table"
import { sortListForTable, TableList } from "../../utils/lists"
import { DateTime } from "luxon"
import { lastActivityToReadableTime } from "../../utils/text"
import { ConnectionStateTag } from "../../components/molecules/connectionStateTag"
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import CustomButton from "../../components/molecules/button"
import { StoreState } from "../../state/configureStore"
import { connect } from "react-redux"
import { LoadingButton } from "@mui/lab"
import { runningStateColors } from "../../theme"
import Tag from "../../components/atoms/tag"

type SortOn = 'name'

type CustomerMachinesTableProps = {
  machines: Machine[],
  onClickRow: Function,
  deleteMachineFromCustomer: Function,
}

const columnNames = [{
  displayName: 'Machine Id',
  serverName: 'serialId'
}, {
  displayName: 'Name',
  serverName: 'name'
}, {
  displayName: 'Last Activity',
  serverName: 'lastActivityTime'
}, {
  displayName: 'Connection State',
  serverName: null // not sortable
}
// , {
//   displayName: 'Running State',
//   serverName: null
// }
]

function CustomerMachinesTable({
  machines,
  onClickRow,
  deleteMachineFromCustomer,
}: CustomerMachinesTableProps): React.ReactElement {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean | string>(false)
  const [hasPressedDelete, setHasPressedDelete] = useState<boolean>(false)

  const TableSection = useCallback(() => {
    return <Table machines={machines}  setDeleteDialogOpen={setDeleteDialogOpen} setHasPressedDelete={setHasPressedDelete} />
  }, [machines, onClickRow])

  return (
    <React.Fragment>
      <DeleteCustomerDialog
        hasPressedDelete={hasPressedDelete}
        deleteDialogOpen={deleteDialogOpen}
        setHasPressedDelete={setHasPressedDelete}
        deleteMachineFromCustomer={deleteMachineFromCustomer}
        setDeleteDialogOpen={setDeleteDialogOpen} />
      <TableSection />
    </React.Fragment>
  )
}

type DeleteCustomerDialogProps = {
  hasPressedDelete: boolean,
  deleteDialogOpen: boolean | string,
  setDeleteDialogOpen: Function,
  deleteMachineFromCustomer: Function,
  setHasPressedDelete: Function,
  savingState: string
}
const DeleteCustomerDialog = connect((state: StoreState) => ({
  savingState: state.customer_saving,
}))(({
  hasPressedDelete,
  deleteDialogOpen,
  setDeleteDialogOpen,
  savingState,
  deleteMachineFromCustomer,
  setHasPressedDelete
}: DeleteCustomerDialogProps) => {
  return (<Dialog
    open={deleteDialogOpen ? true : false}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {hasPressedDelete && savingState === 'failed' ? <Alert severity="error">Failed to remove the machine. Please refresh the page and try again.</Alert> : null}
    <DialogTitle id="alert-dialog-title">
      {"Remove machine from customer"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to remove this machine from the customer? You will then be able to add it to another store from that store's page.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <CustomButton outlined onClick={() => { setDeleteDialogOpen(false) }}>Cancel</CustomButton>
      <LoadingButton variant='contained' color='error' loading={savingState === 'saving'} onClick={() => {
        deleteMachineFromCustomer(deleteDialogOpen)
        setHasPressedDelete(true)
      }}>
        Remove
      </LoadingButton>
    </DialogActions>
  </Dialog>)
})

type TableProps = { machines: Machine[], setDeleteDialogOpen: Function, setHasPressedDelete: Function, onClickRow: Function }
const Table = ({ machines, setDeleteDialogOpen, setHasPressedDelete, onClickRow }: TableProps) => {
  const [countPerPage, setCountPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [sortingOn, setSortingOn] = useState<SortOn>('name')
  const [descending, setDescending] = useState<boolean>(false)

  const [sortedMachines, setSortedMachines] = useState<TableList<Machine>>({ list: machines, total: machines.length })

  useEffect(() => {
    setSortedMachines(sortListForTable(machines, countPerPage, page, sortingOn, descending))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countPerPage, page, sortingOn, descending])

  return <TableComponent
    countPerPage={countPerPage}
    page={page}
    sortingOn={sortingOn}
    descending={descending}
    setCountPerPage={setCountPerPage}
    setPage={setPage}
    setSortingOn={setSortingOn}
    setDescending={setDescending}
    columnNames={columnNames}
    onClickRow={onClickRow}
    onClickDelete={(id) => {
      setDeleteDialogOpen(id)
      setHasPressedDelete(false)
    }}
    rows={sortedMachines?.list.map((d) => {
      const now = DateTime.now()
      const timestamp = lastActivityToReadableTime(d.connectionState, DateTime.fromISO(d.lastActivityTime), now)

      return {
        'id': d.id,
        'Serial Id': d.id,
        'name': d.name ?? d.id,
        'Last Activity': timestamp,
        'Connection State': <ConnectionStateTag connectionState={d.connectionState} />,
        // 'Running State': <Tag key={'running_tag_' + d.id} title={d.runningState} color={d.runningState === 'running' ? runningStateColors.running : runningStateColors.idle} />,
      }
    }) ?? []}
    total={sortedMachines?.total ?? 0} />
}

export default CustomerMachinesTable