import * as React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import Page from '../../components/molecules/page'
import { fetchUsers } from '../../state/actions/user'
import { StoreState } from '../../state/configureStore'
import DeleteUserDialog from './deleteUserDialog'
import UpdateUserModal from './updateUserModal'
import UserTable from './usersTable'

function Users(
  state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {

  useEffect(() => {
    state.fetchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const UserTableSection = () => {
    const [deleteDialogOpenFor, setDeleteDialogOpenFor] = React.useState<string>('')
    const [updateDialogOpenFor, setUpdateDialogOpenFor] = React.useState<string>('')

    return (
      <React.Fragment>
        <DeleteUserDialog
          closeModal={() => { setDeleteDialogOpenFor('') }}
          open={deleteDialogOpenFor ? true : false}
          user={state.users.users.find(u => u.userId === deleteDialogOpenFor)!}
        />
        <UpdateUserModal
          closeModal={() => { setUpdateDialogOpenFor('') }}
          open={updateDialogOpenFor ? true : false}
          user={state.users.users.find(u => u.userId === updateDialogOpenFor)!}
        />
        {state.fetchingState !== 'failed' ?
          (state.fetchingState === 'loading' ? <DataLoadingText showSpinner text={'Loading users ...'} />
            : state.users.total > 0
              ? <UserTable
                updateUser={(id) => setUpdateDialogOpenFor(id)}
                removeUser={(id) => { setDeleteDialogOpenFor(id) }}
                users={state.users.users}
              />
              : <DataLoadingText text={'No users available.'} />)
          : <DataLoadingText text={'Failed to fetch users from the server.'} />}
      </React.Fragment>
    )
  }

  return (
    <Page title={'Users'} pageSections={[<UserTableSection />]} />
  )
}

const mapStateToProps = (state: StoreState) => ({
  users: state.users,
  fetchingState: state.user_fetching
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchUsers: (countPerPage?: number, page?: number, sortBy?: string, nameFilter?: string) =>
    dispatch(fetchUsers(countPerPage, page, sortBy, nameFilter))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)