import { TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { RestoreModal } from '../../../components/molecules/RestoreModal';
import { unhideCustomer } from '../../../state/actions/customer';
import { StoreState } from '../../../state/configureStore';
import { CustomerNode } from '../../../state/reducers/customer';

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  customer: CustomerNode;
  nodeTypeName: string;
}

function UnhideCustomerDialog(props: IComponentProps): React.ReactElement | null {
  const [reason, setReason] = useState<string>('')

  const resetModal = () => {
    setReason('')
  }

  return props.customer ? (
    <RestoreModal
      title={`Restore "${props.customer.name}"`}
      savingFailedMessage={'The ' + props.nodeTypeName + ' could not be restored. Please refresh the page and try again.'}
      open={props.open}
      closeModal={() => { resetModal(); props.closeModal() }}
      onSave={() => props.unhideCustomer(props.customer.id, reason ? reason : undefined)}
      saving={props.customer_saving}
    >
      <Typography variant='body1'>{'Are you sure you want to restore this ' + props.nodeTypeName + '?'}</Typography>
      <TextField
        autoFocus
        margin="normal"
        id="restore_reason_input_field"
        label="Reason (optional)"
        type="text"
        fullWidth
        onChange={(event) => setReason(event.target.value)}
      />
    </RestoreModal>
  ) : null
}

const mapStateToProps = (state: StoreState) => ({
  customer_saving: state.customer_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  unhideCustomer: (nodeId: string, reason?: string) => dispatch(unhideCustomer(nodeId, reason))
})

export default connect(mapStateToProps, mapDispatchToProps)(UnhideCustomerDialog)