import React, { ComponentType } from "react"

import { Box, Typography } from "@mui/material"
import { padding } from '../../../theme'

const infoRowWidth = 800
const style = {
    infoSection: {
      paddingBottom: padding * 2
    },
    paddingTop: {
      paddingTop: padding * 2
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row' as 'row',
      width: infoRowWidth,
      justifyContent: 'space-between'
    }
  }

type ValueViewProps = {
  value : string | undefined,
  raw : any,
}
  
type Properties = {
    Values: Array<{Label:string, Path:string, CustomComponent?: ComponentType<ValueViewProps> }>,
    Data: any,
}

const DefaultView = ({ value }:ValueViewProps) => {
  return (
    <span>{value}</span>
  )
}


const PropertyRow = ({Label, Value, Raw, CustomComponent}:{Label:string, Value: any | undefined, Raw: any, CustomComponent: ComponentType<ValueViewProps>}) =>{

    return (
    <Box style={style.infoRow}>
        <Typography style={{ flex: 1 }} variant='body1'>{Label}</Typography>
        <Typography style={{ flex: 1}} variant='body1'><CustomComponent value={Value} raw={Raw} /></Typography>
    </Box>
    )
}

const valueFromPath = (data:any, path:string) => {
    return path.split('.').reduce((o, k) => o && o[k], data)
}

const PropertyView:React.FC<Properties> = ({Values, Data}) => {
    return (
        <Box style={style.infoSection}>
            {Values.map((i, idx) => <PropertyRow key={idx} Label={i.Label} Value={valueFromPath(Data, i.Path)} Raw={Data} CustomComponent={ i.CustomComponent ?? DefaultView } />)}
        </Box>
    )
}

export { PropertyView }