import { TextFieldVariants } from "@mui/material"

interface CommonInputStyles {
    variant: TextFieldVariants
    margin: "normal" | "dense" | "none" | undefined
    size: "small" | "medium" | undefined
}

export const InputStyle: CommonInputStyles = {
    margin: "normal",
    variant: "outlined",
    size: "small"
}