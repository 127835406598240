type HttpMethod = 'GET' | 'POST'

export const getAsync = async (url: string, accessToken?: string) =>
  await requestAsync(url, 'GET', accessToken)

export const postAsync = async (url: string, payload: string, accessToken?: string) =>
  await requestAsync(url, 'POST', accessToken, payload)

async function requestAsync(url: string, method: HttpMethod, accessToken?: string, body?: BodyInit) {
  let headers: HeadersInit | undefined = undefined
  if (accessToken) headers = {
    Authorization: `Bearer ${accessToken}`
  }
  if (body) headers = {
    ...headers,
    'Content-Type': 'application/json'
  }
  const requestInit: RequestInit = {
    method,
    headers,
    body
  }
  try {
    return await fetch(url, requestInit)
  }
  catch {
    return new Response(undefined, {status: 500, statusText: 'error'})
  }
}

