import { createStore, applyMiddleware, compose, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from './sagas'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'
import { persistStore } from 'redux-persist'
import { UserActionTypes } from './actionTypes/user'
import { UserReducer, initialUserState, initialUserFetchingState, initialUserSavingState } from './reducers/user'
import { User } from '@auth0/auth0-react'
import { MachineLogsReducer, MachineOnlineHistoryReducer, MachineReducer, MachineState, initialMachineFetchingState, initialMachineOnlineHistoryReducer, initialMachineLogsReducer, initialMachineSavingState, initialMachineState, initialOrphanedMachinesState, initialSignalRLogsState, SignalRLogReducer } from './reducers/machine'
import MachineActionTypes from './actionTypes/machine'
import { CustomerInvoiceReducer, CustomerReducer, initialCustomerFetchingState, initialCustomerInvoiceFetchingState, initialCustomerInvoiceReducer, initialCustomerSavingErrorState, initialCustomerSavingState, initialCustomersFetchingState, initialCustomersState } from './reducers/customer'
import { ErrorReducer, FetchingReducer, SavingReducer } from './reducers/general'
import { CustomerActionTypes } from './actionTypes/customer'
import { AppSettingsReducer, initialAppSettingsState, initialSignalRConnectionState, SignalRConnectionReducer } from './reducers/app'
import { AppSettingActionTypes, SignalRConnectionActionTypes } from './actionTypes/app'
import { initialMapReducer, MapReducer } from './reducers/map'
import { MapActionTypes } from './actionTypes/map'
import { MachineErrorsReducer, initialMachineErrors } from './reducers/machine-errors'
import MachineErrorTypes from './actionTypes/machine-errors'
import thunk from "redux-thunk" 


const logger = createLogger({
    collapsed: true
})

export type StoreState = {
    authenticated_user: User | null
    users: UserReducer
    machines: MachineReducer
    machine_fetching: FetchingReducer
    machine_saving: SavingReducer
    orphaned_machines: MachineState[],
    orphaned_machines_fetching: FetchingReducer
    user_fetching: FetchingReducer,
    user_saving: SavingReducer,
    customers: CustomerReducer,
    customers_fetching: FetchingReducer,
    customer_fetching: FetchingReducer,
    customer_saving: SavingReducer,
    customer_saving_error: ErrorReducer,
    machine_logs: MachineLogsReducer
    machine_logs_fetching: FetchingReducer,
    machine_online_history: MachineOnlineHistoryReducer
    machine_online_history_fetching: FetchingReducer,
    // machine_twins: MachineTwinReducer,
    // machine_twins_fetching: FetchingReducer,
    signal_r_connection: SignalRConnectionReducer,
    signal_r_logs: SignalRLogReducer,
    map_state: MapReducer,
    customer_invoice: CustomerInvoiceReducer,
    customer_invoice_fetching: FetchingReducer,
    settings: AppSettingsReducer,
    machine_errors: MachineErrorsReducer
}

export type StoreAction =
    | UserActionTypes
    | MachineActionTypes
    | MachineErrorTypes
    | CustomerActionTypes
    | SignalRConnectionActionTypes
    | MapActionTypes
    | AppSettingActionTypes

let currentStore: Store<StoreState, StoreAction>

export const getStore = () => currentStore

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = /*window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||*/ compose

const createProdStore = (initialState: StoreState) => (
    createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                thunk
            ),
        )
    )
)

const createDevStore = (initialState: StoreState) => (
    createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                logger,
                thunk,
                sagaMiddleware
            )
        )
    )
)

const startupState: StoreState = {
    authenticated_user: null,
    users: initialUserState,
    machines: initialMachineState,
    machine_fetching: initialMachineFetchingState,
    machine_saving: initialMachineSavingState,
    orphaned_machines: initialOrphanedMachinesState,
    orphaned_machines_fetching: initialMachineFetchingState,
    user_fetching: initialUserFetchingState,
    user_saving: initialUserSavingState,
    customers: initialCustomersState,
    customers_fetching: initialCustomersFetchingState,
    customer_fetching: initialCustomerFetchingState,
    customer_saving: initialCustomerSavingState,
    customer_saving_error: initialCustomerSavingErrorState,
    machine_logs: initialMachineLogsReducer,
    machine_logs_fetching: initialMachineFetchingState,
    machine_online_history: initialMachineOnlineHistoryReducer,
    machine_online_history_fetching: initialMachineFetchingState,
    // machine_twins: initialMachineTwinsState,
    // machine_twins_fetching: initialMachineFetchingState,
    signal_r_connection: initialSignalRConnectionState,
    signal_r_logs: initialSignalRLogsState,
    map_state: initialMapReducer,
    customer_invoice: initialCustomerInvoiceReducer,
    customer_invoice_fetching: initialCustomerInvoiceFetchingState,
    settings: initialAppSettingsState,
    machine_errors: initialMachineErrors,
}

const configureStore = (initialState: StoreState = startupState) => {
    const store = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
        createDevStore(initialState) :
        createProdStore(initialState)

    sagaMiddleware.run(rootSaga)
    let persistor = persistStore(store)

    currentStore = store
    return { store, persistor }
  }

export default configureStore