// FloatingWrapper.jsx
import React from 'react';
import './FloatingWrapper.css'; // Import the CSS file

const FloatingWrapper = ({ children, onPress }) => {
  return (
    <div className="floating-wrapper" onClick={() => onPress ? onPress() : console.warn("Not implememnted")
    }>
      {children}
    </div>
  );
};

export default FloatingWrapper;
