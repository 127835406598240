import React, { ReactNode } from "react";
import { ButtonProperties } from "./types";
import { IconButton } from "@mui/material";

export const IconButtonBase = ({title, enabled, onClick, children, size = "small"}: ButtonProperties & {children: ReactNode}) => {
    return(
        <IconButton 
            className="print-invisible" 
            title={title}
            disabled={!enabled}
            onClick={onClick} 
            size={size}
            >
            {children}
        </IconButton>
    )
}
