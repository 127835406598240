import { CustomerNode } from "../../state/reducers/customer"
import React, { Fragment, useEffect, useState } from 'react'
import TableComponent from "../../components/molecules/table"
import { sortListForTable, TableList } from "../../utils/lists"
import UnhideCustomerDialog from "./modals/unhideCustomerDialog"
import EditNodeModal from "./modals/editNodeModal"

type SortOn = 'name' | 'machineCount'

const columnNames = [
  {
    displayName: 'Name',
    serverName: 'name'
  },
  {
    displayName: 'Number Of Machines',
    serverName: 'machineCount'
  },
]

type CustomersTableProps = {
  customers: CustomerNode[],
  onClickRow: Function,
  onClickDelete: Function
}

function CustomersTable({
  customers,
  onClickRow,
  onClickDelete
}: CustomersTableProps): React.ReactElement {

  const [countPerPage, setCountPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [sortingOn, setSortingOn] = useState<SortOn>('name')
  const [descending, setDescending] = useState<boolean>(false)

  const [sortedCustomers, setSortedCustomers] = useState<TableList<CustomerNode>>({ list: customers, total: customers.length })

  const [restoreCustomerWithId, setRestoreCustomerWithId] = useState<string>('')
  // const [editCustomerWithId, setEditCustomerWithId] = useState<string>('')

  useEffect(() => {
    setSortedCustomers(sortListForTable(customers, countPerPage, page, sortingOn, descending))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countPerPage, page, sortingOn, descending])

  const customerToRestore = sortedCustomers.list.find(c => c.id === restoreCustomerWithId)
  // const customerToEdit = sortedCustomers.list.find(c => c.id === editCustomerWithId)
  return (
    <Fragment>
      {/* {editCustomerWithId && customerToEdit ? <EditNodeModal nodeTypeName="customer" closeModal={() => setEditCustomerWithId('')} open={editCustomerWithId ? true : false} node={customerToEdit} /> : null} */}
      {restoreCustomerWithId && customerToRestore ? <UnhideCustomerDialog nodeTypeName="customer" closeModal={() => setRestoreCustomerWithId('')} open={restoreCustomerWithId ? true : false} customer={customerToRestore} /> : null}
      <TableComponent
        onClickDelete={onClickDelete}
        // onClickEdit={(id) => { setEditCustomerWithId(id) }}
        onClickRestore={(id) => { setRestoreCustomerWithId(id) }}
        countPerPage={countPerPage}
        page={page}
        sortingOn={sortingOn}
        descending={descending}
        setCountPerPage={setCountPerPage}
        setPage={setPage}
        setSortingOn={setSortingOn}
        setDescending={setDescending}
        columnNames={columnNames}
        onClickRow={onClickRow}
        rows={sortedCustomers?.list.map((c) => {
          return {
            'id': c.id,
            'hidden': c.hidden,
            'Name': c.name,
            'Number Of Machines': c.machineCount,
          }
        }) ?? []}
        total={sortedCustomers?.total ?? 0} />
    </Fragment>
  )
}

export default CustomersTable