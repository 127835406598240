import { createAsyncThunk } from "@reduxjs/toolkit"
import { FETCH_CUSTOMER_NODES, FETCH_CUSTOMER_PRICE_MODELS, REMOVE_CUSTOMER_PRICE_MODEL, SAVE_PRICE_MODEL } from "../actionTypes/customer"
import { FetchFromBackoffice, PostBackoffice } from "../../utils/request"
import { PriceModelEnvelope } from "../../pages/customers/pricemodel/types"
import { backofficeCommandURL } from "../../config"
import { postAsync } from "../../utils/ajax"
import { sec } from "../../utils/auth0Helper"




export const fetchCustomerPriceModels = createAsyncThunk(
    FETCH_CUSTOMER_PRICE_MODELS,
    async (customerId: {customerId: string | undefined}, thunkAPI) => {
      const response = await FetchFromBackoffice<PriceModelEnvelope[]>(`customers/${customerId.customerId}/price_models`)
    return response
    },
  )
  export const removePriceModel = createAsyncThunk(
    REMOVE_CUSTOMER_PRICE_MODEL,
    async (params: {params:{validFrom: string, id: string}}, thunkAPI) => {
       const response = await PostBackoffice(`cmd/node/price-model/delete`, params.params).then(  a => console.log(a))
    return response
    }
  )


  export const savePriceModel = createAsyncThunk(
    SAVE_PRICE_MODEL,
    async (command:{model:any} , thunkAPI) => {        
        const arg:AddInitialFeeWithPerExecutionThresholds =
        {
          id: command.model.id,
          validFrom: command.model.date.toFormat("yyyy-MM-dd"),
          currency: command.model.currency,
          initialFee: command.model.initialCost as number,
          thresholds: command.model.threshHolds?.map((t) => {
            return {from: t.trigger as number, perExecution:t.cost as number } 
          })
      
        }
        const url = `${backofficeCommandURL()}/${"cmd/node/price-model/add/initialfeewithperexecutionthresholds"}`;
        const token = await sec.getAccessTokenSilently()
        const response = await postAsync(url, JSON.stringify(arg), token)
        if(response.status === 202){
            return response
        }
        return thunkAPI.rejectWithValue(response.status)

    }
  )

  export const fetchCustomerNodes = createAsyncThunk(
    FETCH_CUSTOMER_NODES, 
    async (_ , thunkAPI) => {
      const response =  await FetchFromBackoffice("customer/nodes")
      return response
    }
  )

  type AddInitialFeeWithPerExecutionThresholds = {
    id:string,
    validFrom: string,
    currency: string,
    initialFee: number,
    thresholds:{from:number, perExecution:number}[]
  }
  




