import { TextField } from "@mui/material"
import React from "react"
import { Nullable, Undefined } from "../../../common-types"
import { InputStyle } from "./style"

export const TextInput = ({title, value, required, error, id, onChange, onBlur}:
    {
        title: string
        value:Nullable<string>
        required?: Undefined<boolean>
        error?: boolean
        id?:string
        onChange: (v: Nullable<string>) => void
        onBlur?: () => void
    }) => {

    return (
        <TextField 
            {...InputStyle}
            label={title} 
            fullWidth 
            id={id}
            error={error}
            required={required} 
            value={value ?? ''} 
            onChange={(e) => onChange(e.target.value.length > 0 ? e.target.value : undefined)} 
            onBlur={onBlur}
            />
    )
}