import { BackenAccess, CommandServicesType, QueryServiceType } from "./utils/requestHelpers";

export const isProd = () => {
    switch(window.location.host){
        case 'jolly-desert-01c2a6c03.5.azurestaticapps.net':
        case 'my.expro.technology':
            return true
    }
    return false
}

export const redirectUri = () => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return 'http://localhost:3001/login_success'
    }
    return window.location.pathname === '/' ? window.location.href : window.location.href.replace(window.location.pathname, '')
}

export const redirectUri_logout = () => {
  const logoutLandingPagePath = 'logout'

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return 'http://localhost:3001/' + logoutLandingPagePath
  }
  return window.location.pathname === '/' ? window.location.href + logoutLandingPagePath 
    : window.location.href.replace(window.location.pathname, '/' + logoutLandingPagePath)
}

export const auth0_params = {
    redirectUri: redirectUri(),
    audience: 'api.expro.com.backoffice',
}

export const auth0Domain = () => {
    if(isProd())
        return 'https://expro.eu.auth0.com'
    return 'https://expro-dev.eu.auth0.com'
}

export const auth0ClientId = () => {
    if(isProd())
        return 'Xof7OZnfJhopRkSt6WjB9Ib7s7kAGhP8'
    return 'KZGqNl6fVWLhQL0aLx8KCRfC51YqInm4'
}

export const backofficeQueryURL = () => {
    if(isProd())
        return 'https://expro-backoffice-query.azurewebsites.net'

    return 'https://expro-backoffice-query-poc.azurewebsites.net'
}

export const backofficeCommandURL = () => {
    if(isProd())
        return 'https://expro-backoffice-command.azurewebsites.net'

    return 'https://expro-backoffice-command-poc.azurewebsites.net'
}

export const utilsURL = () => {
    if(isProd())
        return 'https://expro-device-connect.azurewebsites.net'

    return 'https://expro-utils-poc.azurewebsites.net'
}

export const signalRConnectionUrl = () => {
    if(isProd())
        return 'https://expro-backoffice-database.azurewebsites.net/machines'

    return 'https://expro-backoffice-database-poc.azurewebsites.net/machines'
}

export const QueryServices = {
    Backoffice: (task) => new BackenAccess(backofficeQueryURL(), task),
} satisfies QueryServiceType

export const CommandServices = {
    Backoffice: (task) => new BackenAccess(backofficeCommandURL(), task)
} satisfies CommandServicesType
