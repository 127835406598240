import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { fetchMachines } from '../../state/actions/machine'
import { StoreState } from '../../state/configureStore'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import Page from '../../components/molecules/page'
import TableComponent from '../../components/molecules/table'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import { lastActivityToReadableTime } from '../../utils/text'
import { ConnectionStateTag } from '../../components/molecules/connectionStateTag'
import { sortListForTable, TableList } from '../../utils/lists'
import { MachineState } from '../../state/reducers/machine'

export const columnNames = [{
  displayName: 'Name',
  serverName: 'name'
}, {
  displayName: 'Serial Id',
  serverName: 'serialId'
},{
  displayName: 'Owner',
  serverName: 'rootParentName'
},{
  displayName: 'Total Run Cycles',
  serverName: 'cyclesCount'
}, {
  displayName: 'Last Activity',
  serverName: 'lastActivityTime'
}, {
  displayName: 'Connection State',
  serverName: null
}
// , {
//   displayName: 'Running State',
//   serverName: null
// }
]

type SortOn = 'lastActivityTime' | 'serialId'

function Machines(
  state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  const navigate = useNavigate()

  const [countPerPage, setCountPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [sortingOn, setSortingOn] = useState<SortOn>('lastActivityTime')
  const [descending, setDescending] = useState<boolean>(true)

  const [sortedMachines, setSortedMachines] = useState<TableList<MachineState>>({ list: state.machines, total: state.machines.length })

  useEffect(() => {
    setSortedMachines(sortListForTable(state.machines?.map(d => {return { ...d, rootParentName: d.nodeParents?.length ? d.nodeParents[0].name : '-' }}), countPerPage, page, sortingOn, descending))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.machines), countPerPage, page, sortingOn, descending])

  useEffect(() => {
    state.fetchMachines()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const MachinesTable = useCallback(() => {
    return (
      <TableComponent
        countPerPage={countPerPage}
        page={page}
        sortingOn={sortingOn}
        descending={descending}
        setCountPerPage={setCountPerPage}
        setPage={setPage}
        setSortingOn={setSortingOn}
        setDescending={setDescending}
        onClickRow={(id: string) => navigate(id)}
        columnNames={columnNames}
        rows={sortedMachines.list.map((machine) => {
          const now = DateTime.now()

          const timestamp = lastActivityToReadableTime(machine.connectionState, DateTime.fromISO(machine.lastActivityTime), now)

          return {
            'id': machine.serialId,
            'Name': machine.name,
            'Serial Id': machine.serialId,
            'Owner': machine.parents?.length ? machine.parents[0].name : '-',
            'Total Run Cycles': machine.cyclesCount,
            'Last Activity': timestamp,
            'Connection State': <ConnectionStateTag key={'tag_' + machine.serialId} connectionState={machine.connectionState} />,
            // 'Running State': <Tag key={'running_tag_' + machine.serialId} title={machine.runningState} color={machine.runningState === 'running' ? runningStateColors.running : runningStateColors.idle} />,
          }
        })}
        total={sortedMachines?.total ?? 0} />
    )
  }, [sortedMachines, countPerPage, page, sortingOn, descending, navigate])

  const MachineTableSection = useCallback(() => {
    return (
      state.fetchingState !== 'failed' ?
        (state.fetchingState === 'loading' ? <DataLoadingText showSpinner text={'Loading machines ...'} />
          : sortedMachines.total > 0
            ? <MachinesTable /> : <DataLoadingText text={'No machines available'} />)
        : <DataLoadingText text={'Failed fetching machines'} />
    )
  }, [state.fetchingState, sortedMachines, MachinesTable])

  return (
    <Page title={'Machines'} pageSections={[<MachineTableSection />]} />
  )
}

const mapStateToProps = (state: StoreState) => ({
  machines: state.machines,
  fetchingState: state.machine_fetching,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchMachines: () => dispatch(fetchMachines())
})

export default connect(mapStateToProps, mapDispatchToProps)(Machines)