import React, { Fragment, useEffect, useRef, useState } from 'react'
import { StoreState } from '../../state/configureStore'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import Page from '../../components/molecules/page'
import { fetchMachines } from '../../state/actions/machine'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import {OverviewMap} from './overviewMap'
import { Box, Divider, Paper } from '@mui/material'
import theme, { padding, styles } from '../../theme'
import { lastActivityToReadableTime } from '../../utils/text'
import { DateTime } from 'luxon'
import { MachineState } from '../../state/reducers/machine'
import FloatingWrapper from '../../components/molecules/FloatingWrapper'
import { SubpageTitle, TextTitle } from '../../components/atoms/text/titles'
import { useNavigate } from 'react-router-dom'
import { StoresDailySales } from '../../components/organisms/charts'
import { useQueryBackend } from '../../utils/requestHelpers'
import { KPIDisplay } from '../../components/molecules/kpiDisplay'
import {KpiText} from '../../components/atoms/text/text'

function DashboardPage(
  state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  useEffect(() => {
    state.fetchMachines()
  }, [])

  return (
    <Page hideBreadcrumbs fullView>
      { state.fetchingState === 'loading' ? 
        [
          <Box key={'loadingContainer'} sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: theme.palette.primary.light}}><DataLoadingText key={'loadingText'} showSpinner /></Box> 
        ] : 
        state.fetchingState === 'failed' ? 
        [ 
          <DataLoadingText key={'fetchingErrorText'} text={'There was an issue loading data from the server.'} /> 
        ] : 
        <CustomerDashBoard />
      }
      </Page>
  )
}


const CustomerDashBoard = () => {

  const dispatch = useDispatch()



  const machineForMap: MachineState[] = useSelector(
    (state: any) =>
      state?.machines
        .filter((d) => d.location)
        .map((d) => {
          return {
            lastActivityTimeStr: lastActivityToReadableTime(
              d.connectionState,
              DateTime.fromISO(d.lastActivityTime),
              DateTime.now()
            ),
            storeId: d.nodeId,
            id: d.serialId,
            ...d,
          };
        }) ?? []
  );
  const containerRef = useRef();
  const elementRef = useRef();
  const [height, setHeight] = useState();
  const [customerNodes, setCustomerNodes] = useState()
  //const customerId = "68f2cdaa-d6c1-4208-ab11-91bb6cdb7fe7";
  const navigator = useNavigate()
  // const [customer, setCustomer] = useState()

  useEffect(() => {
    if (elementRef?.current?.offsetHeight) {
      setTimeout(() => {
        setHeight(elementRef?.current?.clientHeight);
      }, 500);
    }
  },[]);

  const dateToday = DateTime.now().toFormat('cccc d LLLL')
  const queryApi = useQueryBackend("Backoffice");


  useEffect(() => {
    queryApi
      .Get<Array<object>>(
        `customer/nodes/runs-per-store/`
      )
      .Data((d) => {
        setCustomerNodes(d)
      });
  },[]);  

  return (
    <div style={{flex: 1}} ref={elementRef} >
      {/* <Page title='Dashboard'> */}
        <Box sx={{
          display: "flex",
          ...styles.ifMobile({flexDirection: "column"}),
          ...styles.ifNotMobile({flexDirection: "row"})
        }}>

          <Paper sx={{ flex: 2, padding: padding }}>
            <SalesDisplay dateToday={dateToday} />

            <Paper sx={{ flex: 1, height: '50vh', marginTop: padding, ...styles.showIfMobile}}>
              <OverviewMap machines={machineForMap} />
            </Paper>

            <Box sx={{marginTop: 5}}>
              <StoresDisplay customerNodes={customerNodes} />
              <CompaniesDisplay customerNodes={customerNodes}/>
            </Box>
          </Paper>

          {height ? (
              <Paper sx={{ flex: 1, height: height, ...styles.showIfNotMobile}}>
                <OverviewMap machines={machineForMap} />
              </Paper>
          ) : (
            <Box style={{ flex: 1 }}></Box>
          )}
        </Box>
      {/* </Page> */}
      </div>
  );
};

const CompaniesDisplay = ({ customerNodes }) => {
  return (
    <Fragment>
      { customerNodes?.filter(m => m?.level_name === "Companies")?.length > 1 ? (
          <div>
            <SubpageTitle title={'My Companies'}  />
            
            { customerNodes?.filter(m => m?.level_name === "Companies")?.map( (a) => 
              <FloatingWrapper onPress={() => null}>                   
                <TextTitle title={a?.name} /> 
                {/* <SoldRecipes
                  id={a.id }
                  queryString={"recipes-run-daily"}
                  showTitle={false}
                /> */}
              </FloatingWrapper> 
            )}
          </div>
        ) : null
        }
    </Fragment>
  )
}


const StoresDisplay = ({ customerNodes }) => {
  function calculateSalesTrend(previousWeekSales, currentWeekSales) {
    if (previousWeekSales === 0 && currentWeekSales === 0) {
        return 0; 
    } else if (previousWeekSales === 0) {
        return 100; 
    } else {
      const trend = ((currentWeekSales - previousWeekSales) / previousWeekSales) * 100;
      return parseFloat(trend.toFixed(1));
    }
}
  return (
    <Fragment>
      {customerNodes?.filter((m) => m?.level_name === "Stores")?.length > 0 && (
        <SubpageTitle title={"My Stores"} />
      )}

      {customerNodes
        ?.filter((m) => m?.level_name === "Stores")
        ?.map((a) => (
          <FloatingWrapper key={a.id} onPress={() => null}>
            <TextTitle title={a?.name} />

            <Box
              style={{ justifyContent: "center", flex: 1, padding: padding }}
            >
              <KpiText description>{"treatments"}</KpiText>
              <Box
                sx={{
                  ...styles.ifMobile({width: "100%"}),
                  ...styles.ifNotMobile(  {width: "50%"}),
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "space-between"
                }}
              >
                <KPIDisplay value={a?.count} description="Total" />
                <KPIDisplay value={a?.this_week} description="This week" />
                <KPIDisplay
                  value={calculateSalesTrend( a.last_week, a.this_week) + "%"}
                  description="Trend"
                />
              </Box>
            </Box>
          </FloatingWrapper>
        ))}
    </Fragment>
  );
}

const SalesDisplay = ({ dateToday }) => {
  return (
    <Fragment>
      <SubpageTitle title={dateToday} />
    <Divider orientation='vertical' variant="middle" flexItem />
    <Paper>
        <StoresDailySales  />
    </Paper>
  </Fragment>
  )
}

const mapStateToProps = (state: StoreState) => ({
  signalRLogs: state.signal_r_logs,
  machines: state.machines,
  fetchingState: state.machine_fetching
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchMachines: () => dispatch(fetchMachines()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)