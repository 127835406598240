import { AppActionTypes, AppSettingActionTypes, APP_ENDED, APP_STARTED, SignalRConnectionActionTypes, START_SIGNALR_CONNECTION, STOP_SIGNALR_CONNECTION } from '../actionTypes/app'

export const appStarted = (token: string, user: any): AppActionTypes => (
  {
    type: APP_STARTED,
    token,
    user
  }
)
export const appEnded = (): AppActionTypes => (
  {
    type: APP_ENDED,
  }
)

export const stopSignalRConnection = (connection): SignalRConnectionActionTypes => (
  {
    type: STOP_SIGNALR_CONNECTION,
    connection
  }
)

export const startSignalRConnection = (accessToken): SignalRConnectionActionTypes => (
  {
    type: START_SIGNALR_CONNECTION,
    accessToken
  }
)

export const setShowHidden = (value): AppSettingActionTypes => (
  {
    type: 'SET_SHOW_HIDDEN',
    value
  }
)