import { User } from '@auth0/auth0-react'
import { Action } from 'redux'
import { UserReducer } from '../reducers/user'
import { appStarted } from './app'

interface LoggedIn extends Action {
  type: 'LOGGED_IN',
  user: User
}

interface LoggedOut extends Action {
  type: 'LOGGED_OUT'
}

export const FETCH_USERS = 'FETCH_USERS'
export interface FetchUsers extends Action {
  type: typeof FETCH_USERS
  countPerPage?: number
  page?: number
  sortBy?: string
  nameFilter?: string
}

interface UsersFetched extends Action {
  type: 'USERS_FETCHED',
  data: UserReducer
}

interface FetchingUsersFailed extends Action {
  type: 'FETCHING_USERS_FAILED'
  status: number
  statusText: string
}

type Organisation = {
  id: string
}
export type AddUserType = {
  email: string,
  id: string,
  givenName: string,
  familyName: string,
  name: string,
  organisations?: Organisation[] 
}
export interface AddUser extends Action {
  type: 'ADD_USER',
  user: AddUserType
}

export type UserAddedType = {
  email: string,
  givenName: string,
  familyName: string,
  name: string,
  userId: string,
}
export const ADD_USER = 'ADD_USER'
export interface UserAdded extends Action {
  type: 'USER_ADDED',
  user: UserAddedType
}

export interface AddingUserFailed extends Action {
  type: 'ADDING_USER_FAILED',
  user: AddUserType
}

export const REMOVE_USER = 'REMOVE_USER'
export interface RemoveUser extends Action {
  type: typeof REMOVE_USER,
  userId: string,
}

export interface UserRemoved extends Action {
  type: 'USER_REMOVED',
  userId: string
}

export interface RemovingUserFailed extends Action {
  type: 'REMOVING_USER_FAILED',
  userId: string
}

export type UpdateUserType = {
  userId: string,
  email: string,
  givenName: string,
  familyName: string,
  name: string
}
export const UPDATE_USER = 'UPDATE_USER'
export interface UpdateUser extends Action {
  type: typeof UPDATE_USER,
  userData: UpdateUserType
}

export interface UserUpdated extends Action {
  type: 'USER_UPDATED',
  userData: UpdateUserType
}

export interface UpdatingUserFailed extends Action {
  type: 'UPDATING_USER_FAILED',
  userId: string
}

export const ASSIGN_ORGANISATIONS = "ASSIGN_ORGANISATIONS"
export interface AssignOrganisations extends Action {
  type: 'ASSIGN_ORGANISATION'
  organisations: string[],
  userId: string
}

export type UserActionTypes =
  | LoggedIn
  | LoggedOut
  | FetchUsers
  | UsersFetched
  | FetchingUsersFailed
  | AddUser
  | UserAdded
  | AddingUserFailed
  | RemoveUser
  | UserRemoved
  | RemovingUserFailed
  | UpdateUser
  | UserUpdated
  | UpdatingUserFailed
  | appStarted
  | AssignOrganisations