import React from "react";
import Page from "../components/molecules/page";
import {Text} from "../components/atoms/text/text"
import { Box } from "@material-ui/core";
import CustomButton from "../components/molecules/button";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutLandingPage = () => {
  const auth0 = useAuth0();

  return (
    <Page hideBreadcrumbs title={'Logout'} pageSections={[
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <Text>You are logged out. Please note that this may happen automatically if you stay logged in for a long time.</Text>
        <Box>
          <CustomButton onClick={() => { 
            auth0.loginWithRedirect().catch((e) => console.error('error logging in: ', e))
          }}>{'Login'}</CustomButton>
        </Box>
      </Box>
    ]}/>
  )
}