import { MapActionTypes } from "../actionTypes/map"

export type MapReducer = {
  openPopups: {[id: string]: any}
}
export const initialMapReducer = {
  openPopups: {}
}
export function map_state(state: MapReducer = initialMapReducer, action: MapActionTypes): MapReducer {
  switch (action.type) {
    case 'OPENED_MAP_POPUP':
      const newPopups = {...state.openPopups}
      newPopups[action.id] = action.popup

      return {
        ...state,
        openPopups: newPopups
      }
    case 'CLOSED_MAP_POPUP':
      let updatedPopups = {}
      Object.keys(state.openPopups).forEach((id) => {
        if (id !== action.id) updatedPopups[id] = state.openPopups[id]
      })

      return {
        ...state,
        openPopups: updatedPopups
      }
    case 'CLOSE_ALL_MAP_POPUPS':
      return {
        ...state,
        openPopups: {}
      }
    default:
      return state
  }
}