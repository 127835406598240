import { LoadingButton } from '@mui/lab'
import { Alert, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomButton from './button'
import CloseIcon from '@mui/icons-material/Close'
import { SavingReducer } from '../../state/reducers/general';
import { Delete } from '@mui/icons-material';

type ModalProps = ({
  title: string,
  savingFailedMessage: string,
  children?: React.ReactNode | null,
  open: boolean,
  closeModal: Function,
  onSave: React.MouseEventHandler<HTMLButtonElement>,
  saving: SavingReducer,
  savingDisabled?: boolean
})

export const DeleteModal = ({
  title,
  savingFailedMessage,
  children,
  open,
  closeModal,
  onSave,
  saving,
  savingDisabled=false
}: ModalProps) => {
  const [attemptedToSave, setAttemptedToSave] = useState(false)
  const [savingFailed, setSavingFailed] = useState(false)

  useEffect(() => {
    if (attemptedToSave && saving === 'failed') {
      setSavingFailed(true)
    }
  }, [saving, attemptedToSave])

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <SavingFailedAlert savingFailedMessage={savingFailedMessage} show={savingFailed} onClose={() => { setSavingFailed(false); }} />
        {children}
      </DialogContent>

      <DialogActions>
        <CustomButton outlined onClick={(event) => {
          setSavingFailed(false)
          setAttemptedToSave(false)
          closeModal()
        }}>Cancel</CustomButton>
        <LoadingButton
          color='error'
          disabled={savingDisabled}
          loading={saving === 'saving'}
          onClick={(event) => {
            setAttemptedToSave(true)
            onSave(event)
          }}
          loadingPosition="start"
          startIcon={<Delete />}
          variant="contained">
          Remove
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

function SavingFailedAlert({ show, onClose, savingFailedMessage }) {
  return (
    <Collapse in={show}>
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {savingFailedMessage}
      </Alert>
    </Collapse>
  )
}