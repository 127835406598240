import { VisibilityOff } from '@mui/icons-material'
import { Breadcrumbs, Typography, Link, Box } from '@mui/material'
import React from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import routes from '../../routes'
import { getStore } from '../../state/configureStore'
import { findNodeForId } from '../../utils/customerUtils'
import { VerticalSpacer } from '../atoms/spacer'
import { BreadcrumbNames } from './page'

function isHidden(str: string, page: string, breadcrumbNames?: BreadcrumbNames) {
  if (page === 'customers') {
    const customers = getStore()?.getState()?.customers?.customers
    const foundCustomer = breadcrumbNames?.find(bc => bc.id === str) ?? findNodeForId(customers, str)
    return foundCustomer?.hidden
  }
  else return false
}

function toTitleCase(str: string, page: string, breadcrumbNames?: BreadcrumbNames) {
  const preparedRoutes = routes.map(r => {
    return {
      ...r,
      path: r.url.slice(1)
    }
  })
  const paths = preparedRoutes.map(r => r.path)
  const index = paths.findIndex(p => p === str)
  if (index > -1) {
    return preparedRoutes[index].name
  }
  else {
    if (page === 'customers') {
      const customers = getStore()?.getState()?.customers?.customers
      const foundCustomer = findNodeForId(customers, str)

      const nameFromList = (!foundCustomer && breadcrumbNames && breadcrumbNames.length) ?
        breadcrumbNames.find(b => b.id === str)?.name : null
      return foundCustomer?.name ?? (nameFromList ?? str)
    }
    else if (page === 'machines') {
      const machines = getStore()?.getState()?.machines
      const foundMachine = machines.find(d => d.serialId === str)
      return foundMachine?.name ?? str
    }
    else return str
  }
}

type BreadcrumbProps = {
  breadcrumbNames?: BreadcrumbNames
}
export function SimpleBreadcrumbs({ breadcrumbNames }: BreadcrumbProps) {
  let location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  let pageName: string = ''

  return (
    <Breadcrumbs aria-label='Breadcrumb' separator='>'>
      <Link color='primary' component={RouterLink} to='/'>
        Dashboard
      </Link>
      {pathnames.map((value, index) => {
        if (index === 0) pageName = value
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`

        return last ? (
          <Box key={to} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {isHidden(value, pageName, breadcrumbNames) ? <VisibilityOff fontSize='inherit' /> : false}
            <VerticalSpacer />
            <Typography color='textPrimary'>
              {toTitleCase(value, pageName, breadcrumbNames)}
            </Typography>
          </Box>
        ) : (
          <Box key={to} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {isHidden(value, pageName, breadcrumbNames) ? <VisibilityOff fontSize='inherit' /> : false}
            <VerticalSpacer />
            <Link color='primary' component={RouterLink} to={to}>
              {toTitleCase(value, pageName, breadcrumbNames)}
            </Link>
          </Box>
        )
      })}
    </Breadcrumbs>
  )
}