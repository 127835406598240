import { Button } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import React from "react"

interface SubscriptionItem {
    email:string,
    eventType:string,
    alertId:string
}

const AlertItemInt = ({email, eventType, alertId, remove}:SubscriptionItem & {remove:() => void} ):JSX.Element => {
    return (
        <Grid container spacing={2}>
            <Grid xs={4}>
                <span>{email}</span>
            </Grid>
            <Grid xs={4}>
                <span>{eventType}</span>
            </Grid>
            <Grid xs={2}>
                <Button variant="outlined" onClick={remove}>Remove</Button>
            </Grid>
        </Grid>
    )
}


const SubscriptionList = ({
    Subscriptions,
    onRemove
} : {Subscriptions: SubscriptionItem[] | undefined, onRemove: (alertId:string) => void}) : JSX.Element => {
    return <div>
        {Subscriptions?.map((item, idx) => <AlertItemInt key={idx} email={item.email} eventType={item.eventType} alertId={item.alertId} remove={() => onRemove(item.alertId)} />)}
    </div>
}


export { SubscriptionList }