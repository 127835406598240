import { all } from 'redux-saga/effects'
import { appSagas } from './app'
import { customerSagas } from './customer'
import { machineSagas } from './machine'
import { userSagas } from './user'
import { dimensionsHandler } from '../../theme'

export function* rootSaga() {
    yield all([
        appSagas(),
        userSagas(),
        customerSagas(),
        machineSagas(),
        dimensionsHandler()
    ])
}