import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { connect } from "react-redux"
import { AnyAction, Dispatch } from "redux"
import { setShowHidden } from "../../state/actions/app"
import { StoreState } from "../../state/configureStore"

function ShowHiddenSwitch(
  state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
): React.ReactElement {
  return (
    <FormControlLabel
        control={<Switch checked={state.settings.showHidden} onChange={(event, checked) => state.setShowHidden(checked)} />}
        label="Show hidden" />
  )
}

const mapStateToProps = (state: StoreState) => ({
  settings: state.settings
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  setShowHidden: (value: boolean) => dispatch(setShowHidden(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShowHiddenSwitch)