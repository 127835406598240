import { AppSettingActionTypes, DimensionsActionTypes, SignalRConnectionActionTypes } from "../actionTypes/app"

export type SignalRConnectionReducer = any
export const initialSignalRConnectionState = null
export function signal_r_connection(state: SignalRConnectionReducer = initialSignalRConnectionState, action: SignalRConnectionActionTypes): SignalRConnectionReducer {
  switch (action.type) {
    case 'SIGNALR_CONNECTION_STARTED':
      return action.data
    case 'SIGNALR_CONNECTION_STOPPED':
      return null
    default:
      return state
  }
}


export type AppSettingsReducer = {
  showHidden: boolean
}
export const initialAppSettingsState: AppSettingsReducer = {
  showHidden: false
}
export function settings(state: AppSettingsReducer = initialAppSettingsState, action: AppSettingActionTypes): AppSettingsReducer {
  switch (action.type) {
    case 'SET_SHOW_HIDDEN' :
      return {
        ...state,
        showHidden: action.value
      }
    default:
      return state
  }
}

export type DimensionsReducer = {
  device: "tablet" | "mobile" | "desktop",
  orientation: "portrait" | "landscape"
}
export const initialDimensionsState: DimensionsReducer = {
  device: 'desktop',
  orientation: 'landscape'
}
export function dimensions(state: DimensionsReducer = initialDimensionsState, action: DimensionsActionTypes): DimensionsReducer {
  switch (action.type) {
    case 'VIEW_DIMENSIONS_UPDATED' :
      return {
        ...state,
        device: action.device,
        orientation: action.orientation
      }
    default:
      return state
  }
}