import React, { useEffect, useState } from "react"
import {TextInput} from '../../atoms/inputs/textInput'
import { Nullable } from "../../../common-types"
import { ValidatableInputProps } from "./types"
import { haveErrors, isRequired } from "./functions"
import { CommandBase } from "../../../common-types/commandingTypes"

export const ValidatableTextInput = <TCommand extends CommandBase, TKey extends keyof TCommand & string,>({
    property,
    command,
    update,
    schema,
    title
}: ValidatableInputProps<TCommand, TKey>
) => {

    const [validate, setValidate] = useState<boolean>(false)
    const [error, setError] = useState<boolean>()
    const value = command[property] as Nullable<string>
    const required = isRequired(schema, property)
    title = title ?? schema.properties[property]?.title ?? 'missing title'

    useEffect(() => {
        if(validate) {
            const errors = haveErrors(schema, command, property)
            // console.log('errors', errors)
            setError(errors.length > 0)
        }
    }, [value, required, validate])


    return (
        <TextInput 
            title={title} 
            required={required} 
            id={`validatable-text-input-${title}`}
            error={error}
            value={value ?? ''} 
            onChange={v => update(c => ({...c, [property]:v}))}
            onBlur={() => setValidate(true)}
            />
    )
}