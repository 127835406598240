import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AddModal, Subheader } from '../../../components/molecules/AddModal'
import { StoreState } from '../../../state/configureStore'
import { AnyAction, Dispatch } from 'redux'
import { addMachineToCustomer } from '../../../state/actions/customer'
import { AddMachineToCustomerType } from '../../../state/actionTypes/customer'
import { MachineState } from '../../../state/reducers/machine'
import { fetchOrphanedMachines } from '../../../state/actions/machine'
import TableComponent from '../../../components/molecules/table'
import { DateTime } from 'luxon'
import { lastActivityToReadableTime } from '../../../utils/text'
import Tag from '../../../components/atoms/tag'
import theme, { runningStateColors } from '../../../theme'
import { DataLoadingText } from '../../../components/atoms/text/dataLoadingText'
import { Box, FormHelperText } from '@mui/material'
import { FetchingReducer } from '../../../state/reducers/general'
import { sortListForTable, TableList } from '../../../utils/lists'

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  customerId: string;
  customerName: string;
  isCustomerRootNode: boolean
}

export const columnNames = [{
  displayName: 'Name',
  serverName: 'name'
}, {
  displayName: 'Serial Id',
  serverName: 'serialId'
},{
  displayName: 'Total Run Cycles',
  serverName: 'cyclesCount'
}, {
  displayName: 'Last Activity',
  serverName: 'lastActivityTime'
}, {
  displayName: 'Connection State',
  serverName: null
}, {
  displayName: 'Running State',
  serverName: null
}]

type SortOn = 'lastActivityTime' | 'serialId' | 'name' | 'cyclesCount'

function AddMachineToCustomerModal(props: IComponentProps): React.ReactElement {
  const [selectedMachine, setSelectedMachine] = React.useState<MachineState | null>(null)
  const [noMachineSelectedError, setNoMachineSelectedError] = React.useState(false)

  const onSave = () => {
    if (selectedMachine) {
      const toSave: AddMachineToCustomerType = {
        customerId: props.customerId,
        machine: selectedMachine!
      }

      props.addMachineToCustomer(toSave)
      setNoMachineSelectedError(false)
    }
    else {
      setNoMachineSelectedError(true)
    }
  }

  const resetModal = () => {
    setSelectedMachine(null)
    setNoMachineSelectedError(false)
  }

  const open = props.open
  const fetchOrphanedMachines = props.fetchOrphanedMachines
  useEffect(() => {
    if (open) fetchOrphanedMachines()
  }, [open, fetchOrphanedMachines])

  return (
    <AddModal
      savingDisabled={(props.fetchingState === 'failed' || props.fetchingState === 'loading') || !props.machines?.length}
      title={'Add Machine to ' + props.customerName}
      saving={props.customer_saving}
      open={props.open}
      fullScreen
      savingFailedMessage={'The machine could not be saved to the customer. Please check your internet connection and try again.'}
      closeModal={() => { resetModal(); props.closeModal() }} onSave={onSave}>

      <SelectMachineSection
        noMachineSelectedError={noMachineSelectedError}
        fetchingState={props.fetchingState}
        setSelectedMachine={setSelectedMachine}
        machines={props.machines}
        selectedMachine={selectedMachine}
        fetchMachines={props.fetchOrphanedMachines} />

      {/* {props.isCustomerRootNode ? <SelectStoreSection /> : null} */}

    </AddModal>
  )
}

/* const SelectStoreSection = () => {
  return <Box>
    <Subheader noPadding title={'Select a store'} />
  </Box>
} */

type SelectMachineSectionProps = {
  noMachineSelectedError: boolean,
  fetchingState: FetchingReducer,
  setSelectedMachine: Function,
  machines: MachineState[],
  selectedMachine: MachineState | null,
  fetchMachines: Function,
}
const SelectMachineSection = ({
  noMachineSelectedError,
  fetchingState,
  setSelectedMachine,
  machines,
  selectedMachine,
}: SelectMachineSectionProps) => {
  const [countPerPage, setCountPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [sortingOn, setSortingOn] = useState<SortOn>('lastActivityTime')
  const [descending, setDescending] = useState<boolean>(true)

  const [sortedMachines, setSortedMachines] = useState<TableList<MachineState>>({ list: machines, total: machines?.length })

  useEffect(() => {
    setSortedMachines(sortListForTable(machines, countPerPage, page, sortingOn, descending))
  }, [machines, countPerPage, page, sortingOn, descending])

  return <Box>
    {noMachineSelectedError ? <FormHelperText id="no-machine-selected-helper-text" error={noMachineSelectedError}>{"Please make sure you have selected a machine to add."}</FormHelperText> : null}
    {
      fetchingState !== 'failed' ?
        (fetchingState === 'loading' ? <DataLoadingText showSpinner text={'Loading Machines ...'} />
          : sortedMachines?.total > 0
            ? <TableComponent
              countPerPage={countPerPage}
              page={page}
              sortingOn={sortingOn}
              descending={descending}
              setCountPerPage={setCountPerPage}
              setPage={setPage}
              setSortingOn={setSortingOn}
              setDescending={setDescending}
              selectedRows={selectedMachine ? [selectedMachine?.serialId] : []}
              onClickRow={(id: string) => {
                if (id !== selectedMachine?.serialId) {
                  const selected = sortedMachines?.list.find(d => d.serialId === id) ?? null
                  setSelectedMachine(selected)
                } else {
                  setSelectedMachine(null)
                }
              }}
              columnNames={columnNames}
              rows={sortedMachines.list.map((machine) => {
                const now = DateTime.now()

                const isConnected = machine.connectionState !== 'Disconnected'
                const timestamp = lastActivityToReadableTime(machine.connectionState, DateTime.fromISO(machine.lastActivityTime), now)

                return {
                  'id': machine.serialId,
                  'Name': machine.name ?? machine.serialId,
                  'Serial Id': machine.serialId,
                  'Total Cycles': machine.cyclesCount ?? 0,
                  'Last Activity': timestamp,
                  'Connection State': <Tag
                    color={isConnected ? theme.palette.success.main : theme.palette.error.main}
                    title={machine.connectionState} />,
                  'Running State': <Tag key={'running_tag_' + machine.serialId} title={machine.runningState} color={machine.runningState === 'running' ? runningStateColors.running : runningStateColors.idle} />,
                }
              })}
              total={sortedMachines?.total ?? 0} />
            : <DataLoadingText text={'No machines available. This might be because all machines are already assigned to a store. If you want to reassign them, you will first have to disconnect them from that store.'} />)
        : <DataLoadingText text={'Failed fetching machines.'} />
    }
  </Box>
}

const mapStateToProps = (state: StoreState) => ({
  machines: state.orphaned_machines,
  customer_saving: state.customer_saving,
  fetchingState: state.orphaned_machines_fetching,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  addMachineToCustomer: (data: AddMachineToCustomerType) => dispatch(addMachineToCustomer(data)),
  fetchOrphanedMachines: () => dispatch(fetchOrphanedMachines())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddMachineToCustomerModal)