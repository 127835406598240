import React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { InputStyle } from "./style"

export const SelectInput = ({title, items, value, required, onChange, onBlur = () => {}}:{
    title: string
    items: Array<string> 
    value: string | undefined | null
    required?: boolean
    onChange: (val: string | undefined | null) => void
    onBlur?: () => void
}) => {
    const labelId = `select-${title}-label`
    return (
        <FormControl 
            fullWidth
            {...InputStyle}
            required={required}
            >
            <InputLabel 
                id={labelId}
                >{title}</InputLabel>
            <Select 
                labelId={labelId}
                label={title}
                id={`select-${title}-control`}
                type="text"
                value={value ?? ''}
                onChange={e => onChange(e.target.value)}
                onOpen={onBlur}
                >
                    {items.map((i, idx) => <MenuItem key={idx} value={i}>{i}</MenuItem>)}
            </Select>
        </FormControl>
    )
}