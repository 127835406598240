import { CLOSED_MAP_POPUP, CLOSE_ALL_MAP_POPUPS, MapActionTypes, OPENED_MAP_POPUP } from "../actionTypes/map";

export const closedMapPopup = (id: string): MapActionTypes => (
  {
    type: CLOSED_MAP_POPUP,
    id: id,
  }
)

export const openedMapPopup = (id: string, popup: any): MapActionTypes => (
  {
    type: OPENED_MAP_POPUP,
    id: id,
    popup: popup
  }
)

export const closeAllMapPopups = (): MapActionTypes => (
  {
    type: CLOSE_ALL_MAP_POPUPS
  }
)