import { LoadingButton } from '@mui/lab'
import { Alert, Box, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomButton from './button'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close'
import { SavingReducer } from '../../state/reducers/general';
import { padding } from '../../theme';
import { getStore } from '../../state/configureStore';

type ModalProps = ({
  title: string,
  savingFailedMessage: string,
  children?: React.ReactNode | null,
  open: boolean,
  fullScreen?: boolean,
  closeModal: Function,
  onSave: React.MouseEventHandler<HTMLButtonElement>,
  saving: SavingReducer | string ,
  savingDisabled?: boolean
})

export const AddModal = ({
  title,
  savingFailedMessage,
  children,
  open,
  closeModal,
  fullScreen,
  onSave,
  saving,
  savingDisabled=false
}: ModalProps) => {
  const [attemptedToSave, setAttemptedToSave] = useState(false)
  const [savingFailed, setSavingFailed] = useState(false)

  useEffect(() => {

    if (attemptedToSave && saving === 'failed') {
      setSavingFailed(true)
    }
    if(attemptedToSave &&  saving === 'rejected') {
      setSavingFailed(true)
    }
  }, [saving, attemptedToSave])

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <SavingFailedAlert savingFailedMessage={savingFailedMessage} show={savingFailed} onClose={() => { setSavingFailed(false); }} />
        {children}
      </DialogContent>

      <DialogActions>
        <CustomButton outlined onClick={(event) => {
          setSavingFailed(false)
          setAttemptedToSave(false)
          closeModal()
        }}>Cancel</CustomButton>
        <LoadingButton
          disabled={savingDisabled}
          loading={saving === 'saving'  }
          onClick={(event) => {
            setAttemptedToSave(true)
            onSave(event)
          }}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="contained">
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export function SavingFailedAlert({ show, onClose, savingFailedMessage }) {
  return (
    <Collapse in={show}>
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {savingFailedMessage}
      </Alert>
    </Collapse>
  )
}

export const Subheader = ({ title, noPadding = false }) => {
  return (
    <Box style={{
      paddingTop: noPadding ? 'auto' : padding * 3
    }}>
      <DialogContentText>{title}</DialogContentText>
    </Box>
  )
}