import React from 'react'
import { padding } from '../../theme'

export const HorizontalSpacer = ({ size = 1 }) => {

  return (
    <div
      style={
        { 
          width: '100%',
          height: size * padding,
        }
      }
    />
  )
}

export const VerticalSpacer = ({ size = 1 }) => {

  return (
    <div
      style={
        { 
          width: size * padding,
        }
      }
    />
  )
}