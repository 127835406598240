import React from "react"
import { Edit } from "@mui/icons-material"
import { ButtonProperties } from "./types"
import { IconButtonBase } from "./button"

export const EditButton = ({title, enabled, color = "primary", onClick = () => {}}: ButtonProperties) => {
    return (
        <IconButtonBase
            enabled={enabled}
            title={title}
            onClick={onClick}
            >
            <Edit color={color} />
        </IconButtonBase>
    )
}