import { DateTime } from "luxon"

export const dateFormat = (date:Date, format: string) => DateTime.fromJSDate(date).toFormat(format)

export function toAgo(date: Date):string { return luxonToAgo(DateTime.fromJSDate(date))};

export function luxonToAgo(dateTime: DateTime, referenceTime?: DateTime | undefined):string {
    if (!dateTime.isValid) return '-'
  
    const difference = referenceTime ? referenceTime.diff(dateTime) : DateTime.now().diff(dateTime)
    if (difference.as('minutes') < 1) return 'Now'
    if (difference.as('minutes') < 60) return `${difference.as('minutes').toFixed(0)} minutes ago`
    if (difference.as('days') < 1) return `${difference.toFormat('HH:mm')}`
  
    return `${dateTime.toFormat('d LLL HH:mm')}`
  }
  
export const resolveOptionalBoolen = (bool: boolean | undefined, fallback?:boolean) => {
  if(bool !== undefined) {
    return bool
  }
  return fallback ? fallback : false
}


export const toReadableTime = (dateTime: DateTime, now: DateTime) => {
  const difference = now.diff(dateTime)

  if (difference.as('minutes')! < 1) return 'now'
  if (difference.as('days')! < 5) return dateTime.toRelativeCalendar() + " at " + dateTime.toFormat('HH:mm')

  return dateTime.toFormat('dd MMM HH:mm')
}

export const toReadableDuration = (from: DateTime, to: DateTime) => {
  const difference = from.diff(to)

  if (difference.as('days')! > 1) return difference.as('days').toFixed(0) + " days"
  if (difference.as('hours')! < 1) return difference.as('minutes').toFixed(0) + " min"
  // if (difference.as('days')! < 5) return dateTime.toRelativeCalendar() + " at " + dateTime.toFormat('HH:mm')

  return difference.as('hours').toFixed(0) + " hrs"
}

export * from './arrayFunctions'