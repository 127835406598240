import React, { useState } from "react"
import { AddModal } from "../../../components/molecules/AddModal"
import { SavingReducer } from "../../../state/reducers/general"
import { TextField } from "@mui/material"

export const ExcludeFromInvoice = (
    {
        Open,
        ReasonText,
        SavingState,
        onClose,
        onSave
    }:{
        Open:boolean,
        ReasonText: string | undefined,
        SavingState: SavingReducer
        onClose: () => void,
        onSave: (text:string) => void,
    }) => {

    const [text, setText] = useState<string>('')

    return (
        <AddModal
            title="Exclude from invoicing"
            open={Open}
            saving={SavingState}
            closeModal={onClose}
            savingFailedMessage="Failed to save."
            onSave={() => onSave(text)}
            >
          <TextField
            autoFocus
            margin="normal"
            id="name_input_field"
            label='Reason'
            defaultValue={ReasonText}
            type="text"
            fullWidth
            onChange={(e) => setText(e.target.value)}
            // error={nameError}
            // helperText={nameError ? "Please enter a name." : null}
          />
        </AddModal>
    )
}