import React, { Fragment } from "react"
import { TextTitle } from "../../../components/atoms/text/titles"
import { AddressType } from "./types"
import { AddressView } from "../address"

export const InvoiceHeader = ({nodeName, invoiceNumber, address, billingPeriod}:{invoiceNumber?:string, nodeName?:string, address?: AddressType, billingPeriod:string|undefined}) => {
    let title = nodeName && `Invoice for ${nodeName}`
    if(invoiceNumber)
        title += ` (${invoiceNumber})`


    return (
        <>
        <div className="right">{billingPeriod}</div>
        { title && <TextTitle title={title} />}
        { address && <AddressView address={{...address, streetAddress:address.addressLine1, extraAddress:address.addressLine2}} />}
        </>
    )
}