import { Box } from '@mui/material'
import { DateTime } from 'luxon'
import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import DashboardMap from '../../components/molecules/maps/interactive/dashboardMap'
import { MapSidebar } from '../../components/molecules/maps/interactive/mapSidebar'
import { StoreState } from "../../state/configureStore"
import { lastActivityToReadableTime } from '../../utils/text'
import { Location } from '../../utils/map'
import MapSideBarDisplay from './mapSideBarDisplay'
import { MachineState } from '../../state/reducers/machine'

// export type MachineMapInfo = {
//   cyclesCountToday: number,
//   name: string,
//   location: Location,
//   cyclesCount: number,
//   runningState: 'running' | 'idle',
//   storeId: string,
//   lastActivityTimeStr: string,
//   id: string,
//   connected: boolean
// }

export function OverviewMap({ machines }): React.ReactElement {
  const machineForMap: MachineState[] =
    machines?.map((d) => {
        return {
          lastActivityTimeStr: lastActivityToReadableTime(
            d.connectionState,
            DateTime.fromISO(d.lastActivityTime),
            DateTime.now()
          ),
          storeId: d.nodeId,
          id: d.serialId,
          ...d,
        };
      }) ?? [];

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const containerRef = useRef();

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        boxShadow: "-7px 4px 15px #ccc",
      }}
    >
      {/* <MapSidebar containerRef={containerRef} width={state.signalRLogs && Object.keys(state.signalRLogs)?.length ? 450 : 450} open={sidebarOpen} onClose={() => setSidebarOpen(false)} onOpen={() => setSidebarOpen(true)}>
          <MapSideBarDisplay machines={machineForMap}/>
        </MapSidebar>  */}
      <DashboardMap machines={machineForMap} />
    </Box>
  );
}
