import React, { useState } from "react";
import { TextField } from "@mui/material";
import { AddModal, Subheader } from "../../../components/molecules/AddModal";
import { SavingReducer } from "../../../state/reducers/general";

export const EditInvoiceCustomerNumber = (
{
    Open,
    InvoiceNumber,
    SavingState,
    onClose,
    onSave
}:{
    Open:boolean,
    InvoiceNumber: string | undefined,
    SavingState: SavingReducer
    onClose: () => void,
    onSave: (text:string | undefined) => void,
}) => {
    const [currentText, setCurrentText] = useState<string | undefined>(InvoiceNumber)

    return (
        <AddModal
          title='Set customer number for invoicing'
          saving={SavingState}
          open={Open}
          savingFailedMessage={'Saving failed. Please check your inputs below and your internet connection and try again.'}
          closeModal={onClose} 
          onSave={() => onSave(currentText)}>
    
          {/* <Subheader title='Customer number for invoicing'/> */}
          <TextField
            autoFocus
            margin="normal"
            id="name_input_field"
            label='Customer number'
            defaultValue={currentText}
            type="text"
            fullWidth
            onChange={(e) => setCurrentText(e.target.value)}
            // error={nameError}
            // helperText={nameError ? "Please enter a name." : null}
          />
        </AddModal>
      )
    
}