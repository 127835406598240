import { User } from '@auth0/auth0-react'
import { DateTime } from 'luxon'
import { UserActionTypes } from '../actionTypes/user'
import { SavingReducer } from './general'
import { jwtDecode } from "jwt-decode";

export type UserReducer = {
  users: UserState[]
  total: number
}

export type UserState = {
  created: string
  email: string
  familyName: string
  givenName: string
  hidden: boolean
  name: string
  nickName: string | null
  removed: string | null
  streamId: string
  updated: string
  userId: string
}

export function authenticated_user(state: User | null = null, action: UserActionTypes): User | null {
  switch (action.type) {
    case 'LOGGED_IN':
      return action.user
    case 'LOGGED_OUT':
      return null
    case 'APP_STARTED':
      return {
        ...state,
        globalRoles:  jwtDecode(action.token)["https://expro.seerefine.se/globalRoles"] || [],
        organisationRoles:  jwtDecode(action.token)["https://expro.seerefine.se/organizationRoles"] || [],

      }
    default:
      return state
  }
}

export const initialUserState: UserReducer = {
  users: [],
  total: 0
}

export function users(state: UserReducer = initialUserState, action: UserActionTypes): UserReducer {
  switch (action.type) {
    case 'USERS_FETCHED':
      return action.data
    case 'USER_ADDED':
      const addedDate = DateTime.local().toISOTime() // should come from server instead, once implemented
      return {
        total: state.total + 1,
        users: [
          {
            ...action.user,
            created: addedDate,
            hidden: false,
            nickName: null,
            removed: null,
            updated: addedDate,
            streamId: ''
          },
          ...state.users
        ]
      }
    case 'USER_REMOVED':
      return {
        total: state.total - 1,
        users: state.users.filter(u => u.userId !== action.userId)
      }
    case 'USER_UPDATED':
      return {
        ...state,
        users: state.users.map(u => {
          if (u.userId === action.userData.userId) {
            return {
              ...u,
              email: action.userData.email,
              name: action.userData.name,
              givenName: action.userData.givenName,
              familyName: action.userData.familyName
            }
          }
          else return u
        })
      }
    default:
      return state
  }
}

export type UserFetchingReducer = 'ready' | 'loading' | 'failed'

export const initialUserFetchingState: UserFetchingReducer = 'ready'

export function user_fetching(state: UserFetchingReducer = initialUserFetchingState, action: UserActionTypes): UserFetchingReducer {
  switch (action.type) {
    case 'FETCH_USERS':
      return 'loading'
    case 'FETCHING_USERS_FAILED':
      return 'failed'
    case 'USERS_FETCHED':
      return 'ready'
    default:
      return state
  }
}



export const initialUserSavingState: SavingReducer = 'ready'

export function user_saving(state: SavingReducer = initialUserSavingState, action: UserActionTypes): SavingReducer {
  switch (action.type) {
    case 'ADD_USER':
    case 'REMOVE_USER':
    case 'UPDATE_USER':
      return 'saving'
    case 'ADDING_USER_FAILED':
    case 'REMOVING_USER_FAILED':
    case 'UPDATING_USER_FAILED':
      return 'failed'
    case 'USER_ADDED':
    case 'USER_REMOVED':
    case 'USER_UPDATED':
      return 'ready'
    default:
      return state
  }
}