import React from "react"
import { Button, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material"
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ContentMenyProps } from "."
import { resolveOptionalBoolen } from "../../../functions";

const MenuButton = ({Layout, Id, ButtonTitle, Open, handleClick}:{Layout: 'Page' | 'Row', Open:boolean, Id:string, ButtonTitle:string | undefined, handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void}) => {
  switch(Layout){
    case 'Row':
      return (
              <Button 
                id={Id}
                aria-controls={Open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Open ? 'true' : undefined}
                onClick={handleClick}
                variant="text"
                >...</Button>
      )
    case 'Page':
      return (
              <Button 
                id={Id + 'control-button'}
                aria-controls={Open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
                endIcon={<SettingsOutlinedIcon/>}
                >{ButtonTitle}</Button>
        )
  }
}

export const ContentMenu = ({
    Id,
    ButtonTitle,
    MenuItems,
    Layout = 'Page'
  } : ContentMenyProps ) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = (outerClick: () => void) => {
      setAnchorEl(null)
      if(typeof outerClick === "function") {
        outerClick?.()
      }
    };

    const anyIcon = MenuItems.findIndex(x => x.Icon) > -1

    return (
        <div className="print-invisible">
          <MenuButton Id={Id} Layout={Layout} ButtonTitle={ButtonTitle} Open={open} handleClick={handleClick} key={'kalle'} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {MenuItems.map((i, idx) => 
                    <MenuItem key={idx} disabled={!resolveOptionalBoolen(i.Enabled, true)} onClick={() => handleClose(i.onClick)}>
                        {anyIcon && <ListItemIcon>{i.Icon}</ListItemIcon>}
                        <Typography noWrap variant="inherit">{i.Label}</Typography>
                    </MenuItem>)}
            </Menu>
        </div>
    )
  }
