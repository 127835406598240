import React, { useEffect, useState } from "react"
import { AddModal } from "../../../../components/molecules/AddModal";
import { AddressTypes, ChangeAddressCommand, } from "./types";
import { ValidatableSelectInput, ValidatableTextInput } from "../../../../components/molecules/validatable-inputs";
import { isValid } from "../../../../components/molecules/validatable-inputs/functions";
import { CommandWithSchema } from "../../../../common-types/commandingTypes";

export const EditAddressForm = ({addressType, command, onClose, onSave}:{addressType: AddressTypes, command: CommandWithSchema<ChangeAddressCommand>, onClose: () => void, onSave: (command: ChangeAddressCommand) => void}) => {
    const title = `${addressType} Address`
    const [editCommand, setEditCommand] = useState<ChangeAddressCommand>(command.command)
    const [validationError, setValidationError] = useState<boolean>(true)
    const schema = {
            required: ["addressType", "country", "id", "locality", "postalCode", "streetAddress"],
            type: "object",
            properties: {
                id: {
                    minLength: 1,
                    type: "string"
                },
                addressType: {
                    enum: ["Invoice", "Visit"],
                    type: "string"
                },
                streetAddress: {
                    minLength: 1,
                    type: "string"
                },
                extraAddress: {
                    type: "string",
                    nullable: true
                },
                postalCode: {
                    minLength: 1,
                    pattern: "^(\\d+\\s?\\d+)$",
                    type: "string"
                },
                locality: {
                    minLength: 1,
                    type: "string"
                },
                country: {
                    enum: ["SWEDEN", "NORWAY", "FINLAND"],
                    type: "string"
                }
            },
        } 
    
    useEffect(()=>{
        if(editCommand) {
            setValidationError(!isValid(schema, editCommand))
        }
    }, [editCommand])

    return (
        <AddModal
            title={title}
            saving={'Saving'}
            savingFailedMessage="Failed"
            savingDisabled={validationError}
            open={true}
            closeModal={onClose}
            onSave={() => onSave(editCommand)}
            >
            
            <ValidatableTextInput title="Street Address" command={editCommand} property="streetAddress" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="Extra Address" command={editCommand} property="extraAddress" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="Postal Code" command={editCommand} property="postalCode" schema={schema} update={setEditCommand} />
            <ValidatableTextInput title="City" command={editCommand} property="locality" schema={schema} update={setEditCommand} />
            <ValidatableSelectInput title="Country" command={editCommand} property="country" schema={schema} update={setEditCommand} />

        </AddModal>
      )
}