import React from "react"
import { InitialFeeWithPerExecutionThresholds } from "../types"

export const InitialFeeWithPerExecutionThresholdsView = ({model, validFrom}:{model: InitialFeeWithPerExecutionThresholds| undefined, validFrom: string}) => {

    if(model){
        return (
            <table>
                <tbody>
                    <tr>
                        <th>Valid From:</th>
                        <td>{validFrom}</td>
                    </tr>
                    <tr>
                        <th>Currency:</th>
                        <td>{model.currency}</td>
                    </tr>
                    <tr>
                        <th>Initial fee:</th>
                        <td>{model.initialFee}</td>
                    </tr>
                    <tr>
                        <th>Breakpoints</th>
                        <td>
                            <table>
                                <tr>
                                    <th>Breakpoint</th>
                                    <th>Price per execution</th>
                                </tr>
                                {model?.thresholds?.map((t, idx) => (<tr key={idx}><td>{t.from}</td><td>{t.perExecution}</td></tr>))}
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        )
    }
}