import { TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { DeleteModal } from '../../../components/molecules/DeleteModal';
import { removeCustomer } from '../../../state/actions/customer';
import { StoreState } from '../../../state/configureStore';
import { CustomerNode } from '../../../state/reducers/customer';

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  customer: CustomerNode
  nodeTypeName: string
}

function DeleteCustomerDialog(props: IComponentProps): React.ReactElement | null {
  const [reason, setReason] = useState<string>('')

  const resetModal = () => {
    setReason('')
  }

  return props.customer ? (
    <DeleteModal
      title={`Remove "${props.customer.name}"`}
      savingFailedMessage={'The ' + props.nodeTypeName + ' could not be removed. Please refresh the page and try again.'}
      open={props.open}
      closeModal={() => { resetModal(); props.closeModal() }}
      onSave={() => props.removeCustomer(props.customer.id, reason ? reason : undefined)}
      saving={props.customer_saving}
    >
      <Typography variant='body1'>{'Are you sure you want to remove this ' + props.nodeTypeName + '? It will still be accessible and can be restored using the "Show hidden" toggle.'}</Typography>
      <TextField
        autoFocus
        margin="normal"
        id="delete_reason_input_field"
        label="Reason (optional)"
        type="text"
        fullWidth
        onChange={(event) => setReason(event.target.value)}
      />
    </DeleteModal>
  ) : null
}

const mapStateToProps = (state: StoreState) => ({
  customer_saving: state.customer_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  removeCustomer: (nodeId: string, reason?: string) => dispatch(removeCustomer(nodeId, reason))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCustomerDialog)