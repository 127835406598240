import * as React from 'react'
import { createRoot } from 'react-dom/client';
// import reportWebVitals from './reportWebVitals'
import { createTheme, ThemeProvider } from '@mui/material/styles'
// import webPreset from 'fela-preset-web'
// import { RendererProvider, ThemeProvider } from 'react-fela'
import CssBaseline from '@mui/material/CssBaseline'
// import { createRenderer } from 'fela'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import theme from './theme'
import './index.css'
import App from './App'
import configureStore from './state/configureStore'
import { Auth0Provider } from '@auth0/auth0-react'
import { auth0Domain, auth0ClientId, auth0_params } from './config'
import { RendererProvider } from 'react-fela'
import { createRenderer } from 'fela';
import webPreset from "fela-preset-web";
import 'mapbox-gl/dist/mapbox-gl.css';
import { baseStyles } from './baseStyles';
import './fonts/Vogie-Bold.ttf'

const renderer = createRenderer({
  plugins: [
    ...webPreset
    // other plugins
  ]
})

export const reduxStore = configureStore()

const exproTheme = createTheme(theme)

renderer.renderStatic(baseStyles)

const Root = () => {
  const {
    store,
    persistor
  } = reduxStore

  return (
    <RendererProvider renderer={renderer}>
    <ThemeProvider theme={exproTheme}>
      <CssBaseline />
      {/* <ThemeProvider theme={theme}> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Auth0Provider
              domain={auth0Domain()}
              clientId={auth0ClientId()}
              redirectUri={auth0_params.redirectUri}
              audience={auth0_params.audience}>
              <App />
            </Auth0Provider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
    </RendererProvider>
  )
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);