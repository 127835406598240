import React, { useState } from "react"
import { DeleteButton } from "../../../../components/atoms/buttons/deleteButton"
import { AddressTypes, RemoveAddressCommand } from "../editAddress/types"
import { DeleteAddressForm } from "./deleteAddressForm"
import { useCommandBackend } from "../../../../utils/requestHelpers"


export const DeleteAddressView = ({ addressType, command, onDeleted }:{addressType: AddressTypes, command:RemoveAddressCommand | undefined, onDeleted: (command: RemoveAddressCommand) => void}) => {
    const commandApi = useCommandBackend("Backoffice")
    const title = `Edit ${addressType} address`
    const [showDialog, setShowDialog] = useState<boolean>()

    const handleSave = (command: RemoveAddressCommand) => {
        commandApi.PostCommand('/cmd/node/address/remove', command)
            .Data(result => {
                onDeleted(result.command)
                setShowDialog(false)
            })
    }

    if(command)
        return(
            <>
                <DeleteButton
                    title={title}
                    enabled={command !== undefined}
                    onClick={() => setShowDialog(command !== undefined)}
                    />
                {showDialog && <DeleteAddressForm command={command} addressType={addressType} onClose={() => setShowDialog(false)} onSave={handleSave} />}
            </>
        )
}