import { fork, put, select, take, takeEvery } from 'redux-saga/effects'
import { signalRConnectionUrl } from '../../config'
import { AppActionTypes, APP_ENDED, APP_STARTED, START_SIGNALR_CONNECTION, STOP_SIGNALR_CONNECTION } from '../actionTypes/app'
import * as signalR from '@microsoft/signalr';
import { eventChannel, END } from 'redux-saga';
import {
  startSignalRConnection as startSignalRConnectionActionType,
  stopSignalRConnection as stopSignalRConnectionActionType
} from '../actionTypes/app'
import {
  startSignalRConnection as startSignalRConnectionAction,
  stopSignalRConnection as stopSignalRConnectionAction
} from '../actions/app'
import { sec } from '../../utils/auth0Helper'
import { MACHINE_ERROR_REMOVED, MACHINE_ERROR_REPORTED } from '../actionTypes/machine-errors';

export function* appSagas() {
  yield takeEvery(APP_STARTED, startApp)
  yield takeEvery(APP_ENDED, endApp)
  yield takeEvery(START_SIGNALR_CONNECTION, startSignalRConnection)
  yield takeEvery(STOP_SIGNALR_CONNECTION, stopSignalRConnection)
}

function* startApp(action: AppActionTypes) {
  const accessToken = yield sec.getAccessTokenSilently()
  yield put(startSignalRConnectionAction(accessToken))
}

function* endApp(action: AppActionTypes) {
  const signalRConnection = yield select(state => state.signal_r_connection)
  yield put(stopSignalRConnectionAction(signalRConnection))
}


// SignalR
function* startSignalRConnection({ accessToken }: startSignalRConnectionActionType) {
  const existing = yield select(state => state.signalRConnection)
  if (existing != null) {
    yield put({ type: 'SIGNALR_CONNECTION_ALREADY_ACTIVE' })
    return
  }

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(signalRConnectionUrl()/* , { accessTokenFactory: () => accessToken } */)
    .configureLogging(signalR.LogLevel.None)
    .build()

  try {
    yield connection.start()
    yield fork(setupSignalRListeners, connection)
    yield put({ type: 'SIGNALR_CONNECTION_STARTED', data: connection })
  } catch (error) {
    yield put({ type: 'SIGNALR_CONNECTION_FAILED', data: error })
  }
}

export class RecipeActionMessage {
  machineId: string; action: string; nodeId: string; recipeType: string; timestamp: string
  constructor(machineId: string, action: string, nodeId: string, recipeType: string, timestamp: string) {
    this.machineId = machineId; this.action = action; this.nodeId = nodeId; this.recipeType = recipeType; this.timestamp = timestamp
  }
}
export class LogItemMessage {
  machineId: string; timestamp: string; level: string; message: string; source: string
  constructor(machineId: string, timestamp: string, level: string, message: string, source: string) {
    this.machineId = machineId; this.timestamp = timestamp; this.level = level; this.message = message; this.source = source
  }
}
export class MachineStateChanged {
  machineId: string; currentState: string; timestamp: string
  constructor(machineId: string, currentState: string, timestamp: string) {
    this.machineId = machineId; this.currentState = currentState; this.timestamp = timestamp;
  }
}

class MachineErrorReported {
  machineId:string;
  name:string;
  text:string;
  reportedAt:string;
  code:number;
  constructor(machineId:string, name:string, text:string, reportedAt:string, code:number){
    this.machineId = machineId
    this.name = name
    this.text = text
    this.reportedAt = reportedAt
    this.code = code
  }
}

class MachineErrorRemoved {
  machineId:string;
  name:string;
  text:string;
  reportedAt:string;
  code:number;
  constructor(machineId:string, name:string, text:string, reportedAt:string, code:number){
    this.machineId = machineId
    this.name = name
    this.text = text
    this.reportedAt = reportedAt
    this.code = code
  }
}


function* setupSignalRListeners(connection) {
  if (connection != null) {
    try {
      const channel = eventChannel(listener => {
        connection.on('RecipeAction', (machineId, action, nodeId, recipeType, timestamp) => {
          let message: RecipeActionMessage = new RecipeActionMessage(machineId, action, nodeId, recipeType, timestamp)
          return listener(message)
        })
        connection.on('logitems', ({machineId, timestamp, level, message, source}) => {
          let signalR_message: LogItemMessage = new LogItemMessage(machineId, timestamp, level, message, source)
          return listener(signalR_message)
        })
        connection.on('MachineErrorReported', (...errors) => {
          errors.forEach(error => listener(new MachineErrorReported(error.machineId, error.name, error.text, error.reportedAt, error.code)))
          return
        })
        connection.on('MachineErrorRemoved', (removed) => {
          console.log('MachineErrorRemoved', removed)
          return listener(new MachineErrorRemoved(removed.machineId, removed.name, removed.text, removed.reportedAt, removed.code))
        })
        connection.on('MachineStateChanged', (machineId, currentState, timestamp) => {
          console.log('SignalR - MachineStateChanged: ', machineId, currentState, timestamp)
          let signalR_message: MachineStateChanged = new MachineStateChanged(machineId, currentState, timestamp)
          return listener(signalR_message)
        })
        connection.invoke('listenForMachineErrors').then(e => console.log('listenForMachineErrors', e))
        connection.onclose(() => listener(END))

        return () => { }
      })
      while (connection.state === signalR.HubConnectionState.Connected) {
        const status = yield take(channel)
        if (status instanceof RecipeActionMessage) {
          yield put({ type: 'RECIPE_ACTION_MESSAGE_RECEIVED', message: status })
        } else if (status instanceof LogItemMessage) {
          yield put({ type: 'DEVICE_LOG_MESSAGE_RECEIVED', message: status })
        } else if (status instanceof MachineStateChanged) {
          yield put({ type: 'MACHINE_STATE_CHANGED_RECEIVED', message: status })
        } else if (status instanceof MachineErrorReported) {
          yield put({ type: MACHINE_ERROR_REPORTED, message: status})
        } else if (status instanceof MachineErrorRemoved) {
          yield put({ type: MACHINE_ERROR_REMOVED, message: status})
        } else {
          yield put({ type: 'RECEIVED_UNKNOWN_SIGNAL_R_MESSAGE' })
        }
      }
    } catch (error) {
      console.warn('SignalR connection error: ', error)
      connection.stop()
    }
  }
}

function* stopSignalRConnection({ connection }: stopSignalRConnectionActionType) {
  if (connection != null) {
    yield connection.stop()
    yield put({ type: 'SIGNALR_CONNECTION_STOPPED', data: null })
  } else {
    yield put({ type: 'NO_SIGNALR_CONNECTION_TO_STOP' })
  }
}