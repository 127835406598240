import { createReducer } from '@reduxjs/toolkit'
import { CustomerActionTypes } from '../actionTypes/customer'
import MachineActionTypes from '../actionTypes/machine'
import { ErrorReducer, FetchingReducer, SavingReducer } from './general'
import { PriceModelEnvelope } from '../../pages/customers/pricemodel/types'
import { DateTime } from 'luxon'
import { fetchCustomerPriceModels, removePriceModel, savePriceModel } from '../thunks/customer'

export type CustomerReducer = {
  customers: CustomerNode[]
  total: number
}

export type CustomerInvoiceReducer = Invoice | {}

export type CustomerState = {
  levelName: string,
  nodes: CustomerNode[] | undefined
}

export type Invoice = {
  nodeId: string,
  nodeName: string,
  invoiceNumber: string | undefined
  fromDate: string,
  toDate: string,
  invoiceAddress: Address,
  nodes: InvoiceNodeInfo[]
}
export type InvoiceNodeInfo = {
  nodeId: string,
  nodeName: string,
  invoiceNumber:string | undefined
  executions: {
    total: number,
    includedTotal:number,
    excludedTotal: number,
    recipeTotals: { [recipeType: string]: number },
    canceledTotal: number,
    doneTotal: number,
    startedTotal: number,
    recipeAt: [
      {
        recipe: string,
        startedAt: string,
        status: string,
        excluded:boolean,
        excludedReason:string|undefined,
      }
    ]
  }
}

export type Machine = {
  id: string,
  name: string,
  lastActivityTime: string
  connectionState: string,
  runningState: string,
  nodeId: string,
}

export type Address = {
  addressLine1: string,
  addressLine2?: string | null | undefined,
  postalCode: string,
  locality: string,
  administrativeArea: string,
  country: string
}

export type CustomerParent = {
  id: string
  levelIndex: number
  levelName: string
  name: string,
  hidden: boolean
}

export type CustomerNode = {
  id: string,
  hidden: boolean,
  machineCount: number,
  name: string,
  levelName: string,
  parentId?: string,
  parents?: CustomerParent[],
  location?: {
    longitude: number,
    latitude: number
  },
  invoiceAddress?: Address,
  visitingAddress?: Address,
  invoiceNumber: string | undefined 
  childs?: CustomerState,
  machines?: Machine[]
}


export const initialCustomersState: CustomerReducer = {
  customers: [],
  total: 0
}


// TODO: simplify these recursive functions a little, since they do very similar things and reuse a lot of code
// TODO: these recursive add and remove functions are very likely buggy and may cause problems when we have a deeper customer node structure
const addSubnodeToCustomer = (customers: CustomerNode[], subnode: CustomerNode, customerId: string) => {
  let foundIndex = customers.findIndex(c => c.id === customerId)
  if (foundIndex > -1) {
    customers[foundIndex] = {
      ...customers[foundIndex],
      childs: {
        levelName: customers[foundIndex].childs?.levelName ?? "Offices",
        nodes: [
          ...(customers[foundIndex].childs?.nodes ?? []),
          {
            ...subnode
          }
        ]
      }
    }
    return customers
  }
  else {
    let i = 0
    while (i < customers?.length && (foundIndex < 0)) {
      const children = customers[i].childs?.nodes
      if (children && children.length) {
        let updatedChildren = addSubnodeToCustomer(children, subnode, customerId)
        if (updatedChildren) {
          foundIndex = customers.findIndex(c => c.id === updatedChildren[0].parentId)
        }
      }
      i++
    }
  }

  return (foundIndex || foundIndex === 0) ? customers : false
}

const addMachinesToCustomer = (customers: CustomerNode[], newMachines: Machine[], customerId: string) => {
  let foundIndex = customers.findIndex(c => c.id === customerId)
  if (foundIndex > -1) {
    customers[foundIndex] = {
      ...customers[foundIndex],
      machines: [
        ...(customers[foundIndex].machines ?? []),
        ...newMachines
      ],
      machineCount: customers[foundIndex]?.machineCount + 1
    }
    return customers
  }
  else {
    let i = 0
    while (i < customers?.length && (foundIndex < 0)) {
      const children = customers[i].childs?.nodes
      if (children && children.length) {
        let updatedChildren = addMachinesToCustomer(children, newMachines, customerId)
        if (updatedChildren) {
          foundIndex = customers.findIndex(c => c.id === updatedChildren[0].parentId)
        }
      }
      i++
    }
  }

  return (foundIndex !== -1) ? customers : false
}

const updateNodeName = (customers: CustomerNode[], nodeId: string, newName: string) => {
  let foundIndex = customers.findIndex(c => c.id === nodeId)
  if (foundIndex > -1) {
    customers[foundIndex] = {
      ...customers[foundIndex],
      name: newName
    }
    return customers
  }
  else {
    let i = 0
    while (i < customers?.length && (foundIndex < 0)) {
      const children = customers[i].childs?.nodes
      if (children && children.length) {
        let updatedChildren = updateNodeName(children, nodeId, newName)
        foundIndex = customers.findIndex(c => c.childs?.nodes?.find(n => n.id === updatedChildren![0].id))
        customers[foundIndex] = {
          ...customers[foundIndex],
          childs: {
            ...customers[foundIndex].childs!,
            nodes: updatedChildren
          }
        }
      }
      i++
    }
  }
  return customers
}

const updateNodeAddress = (customers: CustomerNode[], nodeId: string, addressType: 'invoice' | 'visiting', newAddress: Address | undefined) => {
  let foundIndex = customers.findIndex(c => c.id === nodeId)
  if (foundIndex > -1) {
    customers[foundIndex] = {
      ...customers[foundIndex],
      invoiceAddress: (addressType === 'invoice') ?
        newAddress ? { ...customers[foundIndex].invoiceAddress, ...newAddress } : newAddress
        : customers[foundIndex].invoiceAddress,
      visitingAddress: (addressType === 'visiting') ?
        newAddress ? { ...customers[foundIndex].visitingAddress, ...newAddress } : newAddress
        : customers[foundIndex].visitingAddress,
    }
    return customers
  }
  else {
    let i = 0
    while (i < customers?.length && (foundIndex < 0)) {
      const children = customers[i].childs?.nodes
      if (children && children.length) {
        let updatedChildren = updateNodeAddress(children, nodeId, addressType, newAddress)
        foundIndex = customers.findIndex(c => c.childs?.nodes?.find(n => n.id === updatedChildren![0].id))
        customers[foundIndex] = {
          ...customers[foundIndex],
          childs: {
            ...customers[foundIndex].childs!,
            nodes: updatedChildren
          }
        }
      }
      i++
    }
  }
  return customers
}

const removeMachineFromCustomer = (customers: CustomerNode[], serialId: string, nodeId: string) => {
  let foundIndex = customers.findIndex(c => c.id === nodeId)
  if (foundIndex > -1) {
    customers[foundIndex] = {
      ...customers[foundIndex],
      machines: (customers[foundIndex].machines ?? []).filter(d => d.id !== serialId),
      machineCount: customers[foundIndex]?.machineCount - 1
    }
    return customers
  }
  else {
    let i = 0
    while (i < customers?.length && (foundIndex < 0)) {
      const children = customers[i].childs?.nodes
      if (children && children.length) {
        let updatedChildren = removeMachineFromCustomer(children, serialId, nodeId)
        if (updatedChildren) {
          foundIndex = customers.findIndex(c => c.childs?.nodes?.find(n => n.id === updatedChildren![0].id))
          customers[foundIndex] = {
            ...customers[foundIndex],
            machines: (customers[foundIndex].machines ?? []).filter(d => d.id !== serialId),
            machineCount: customers[foundIndex]?.machineCount - 1,
            childs: {
              ...customers[foundIndex].childs!,
              nodes: updatedChildren
            }
          }
        }
      }
      i++
    }
  }

  return (foundIndex !== -1) ? customers : false
}

const updateMachineRunningState = (customers: CustomerNode[], serialId: string, newRunningState: string) => {
  return customers.map(customer => {
    let newMachines: Machine[] = []
    if (customer.machines) {
      newMachines = customer.machines?.map(m => {
        if (m.id === serialId) {
          return {
            ...m,
            runningState: newRunningState
          }
        }
        else return m
      })
    }

    if (customer.childs?.nodes?.length) {
      let updatedNodes: CustomerNode[] = updateMachineRunningState(customer.childs.nodes, serialId, newRunningState)
      customer.childs.nodes = updatedNodes
    }

    if (customer.machines) return { ...customer, machines: newMachines }
    else return customer

  })
}

const updateMachineConnectedState = (customers: CustomerNode[], serialId: string, newConnectedState: string) => {
  return customers.map(customer => {
    let newMachines: Machine[] = []
    if (customer.machines) {
      newMachines = customer.machines?.map(m => {
        if (m.id === serialId) {
          return {
            ...m,
            connectionState: newConnectedState
          }
        }
        else return m
      })
    }

    if (customer.childs?.nodes?.length) {
      let updatedNodes: CustomerNode[] = updateMachineRunningState(customer.childs.nodes, serialId, newConnectedState)
      customer.childs.nodes = updatedNodes
    }

    if (customer.machines) return { ...customer, machines: newMachines }
    else return customer

  })
}

const updateNodeHiddenState = (customers: CustomerNode[], nodeId: string, newHiddenState: boolean) => {
  let foundNode = false
  let newCustomers = customers.map(customer => {
    if (customer.id === nodeId) {
      foundNode = true
      return {
        ...customer,
        hidden: newHiddenState
      }
    }
    else return customer
  })

  if (foundNode) return newCustomers

  let updatedNodes
  newCustomers = customers.map(customer => {
    if (customer.childs?.nodes?.length) {
      updatedNodes = updateNodeHiddenState(customer.childs?.nodes, nodeId, newHiddenState)
      if (updatedNodes) return { ...customer, childs: { ...customer.childs, nodes: updatedNodes } }
      else return customer
    }
    else return customer
  })
  if (updatedNodes) return newCustomers
  else return undefined
}

const injectCustomerInfo = (customers: CustomerNode[], customerInfo: CustomerNode) => {
  if (!customers?.length) return false

  let foundIndex = customers.findIndex(c => c.id === customerInfo.id)
  if (foundIndex > -1) {
    customers[foundIndex] = {
      ...customers[foundIndex],
      ...customerInfo
    }
  }
  else {
    let i = 0
    while (i < customers?.length && (foundIndex < 0)) {
      const children = customers[i].childs?.nodes
      if (children && children.length) {
        let updatedChildren = injectCustomerInfo(children, customerInfo)

        if (updatedChildren) {
          foundIndex = customers.findIndex(c => c.id === updatedChildren[0].parentId)
          if (foundIndex > -1) {
            customers[foundIndex] = {
              ...customers[foundIndex],
              childs: {
                ...customers[foundIndex].childs!,
                nodes: updatedChildren
              }
            }
          }
        }
      }
      i++
    }
  }

  return (foundIndex !== -1) ? customers : false
}

export function customers(state: CustomerReducer = initialCustomersState, action: CustomerActionTypes | MachineActionTypes): CustomerReducer {
  switch (action.type) {
    case 'CUSTOMERS_FETCHED':
      if (action.data.levelName === 'Companies') {
        const listOfCompanies = action.data.nodes
        return {
          customers: listOfCompanies ?? [],
          total: listOfCompanies?.length ?? 0
        }
      }
      return state
    case 'CUSTOMER_FETCHED':
      const newCustomers = injectCustomerInfo([...state.customers], action.data)
      if (newCustomers) {
        return {
          ...state,
          customers: (newCustomers.map(c => {
            return {
              ...c,
              machineCount: c.machines?.length ?? 0
            }
          })) as CustomerNode[]
        }
      }
      // handle case that customers have not been fetched yet
      else { // will be overwritten with next fetch by proper data, but that is OK
        return {
          customers: [action.data].map(c => {
            return {
              ...c,
              machineCount: c.machines?.length ?? 0
            }
          }),
          total: 1
        }
      }
    case 'CUSTOMER_ADDED':
      return {
        total: state.total + 1,
        customers: [
          {
            ...action.customer,
            id: action.id,
            machineCount: 0,
            hidden: false,
            levelName: 'Companies'
          },
          ...state.customers
        ]
      }
    case 'CUSTOMER_REMOVED':
      const updated = updateNodeHiddenState([...state.customers], action.nodeId, true)

      return {
        ...state,
        customers: updated ?? state.customers
      }
    case 'CUSTOMER_UNHIDDEN':
      const updatedAfterUnhiding = updateNodeHiddenState([...state.customers], action.nodeId, false)

      return {
        ...state,
        customers: updatedAfterUnhiding ?? state.customers
      }
    case 'CUSTOMER_SUBNODE_ADDED':
      const parentId = action.customerSubnode.parentId
      const updatedCustomers = addSubnodeToCustomer(
        [...state.customers],
        {
          id: action.id,
          machineCount: 0,
          name: action.customerSubnode.name,
          hidden: false,
          location: {
            longitude: action.customerSubnode.location_Longitude,
            latitude: action.customerSubnode.location_Latitude
          },
          levelName: 'Stores' // might not work well when we have more org. levels
        },
        parentId)
      return {
        ...state,
        customers: updatedCustomers ? updatedCustomers : state.customers
      }
    case 'MACHINE_ADDED_TO_CUSTOMER': {
      const customersWithNewMachine = addMachinesToCustomer(
        [...state.customers],
        [{
          ...action.data.machine,
          id: action.data.machine.serialId,
          name: action.data.machine.name ?? action.data.machine.serialId,
          nodeId: action.data.customerId
        }],
        action.data.customerId
      )
      return {
        ...state,
        customers: customersWithNewMachine ? customersWithNewMachine : state.customers
      }
    }
    case 'MACHINE_REMOVED_FROM_NODE': {
      const customersWithoutMachine = removeMachineFromCustomer(
        [...state.customers],
        action.serialId,
        action.nodeId,
      )
      return {
        ...state,
        customers: customersWithoutMachine ? customersWithoutMachine : state.customers
      }
    }
    case 'RECIPE_ACTION_MESSAGE_RECEIVED': {
      return {
        ...state,
        customers: updateMachineRunningState(state.customers, action.message.machineId, action.message.action === "started" ? 'running' : 'idle')
      }
    }
    // case 'MACHINE_CONNECTED_TO_IOT_MESSAGE_RECEIVED': {
    //   return {
    //     ...state,
    //     customers: updateMachineConnectedState(state.customers, action.message.machineId, 'Connected')
    //   }
    // }
    case 'ADDRESS_REMOVED_FROM_NODE': {
      return {
        ...state,
        customers: updateNodeAddress(state.customers, action.nodeId, action.addressType, undefined)
      }
    }
    case 'NODE_ADDRESS_CHANGED': {
      return {
        ...state,
        customers: updateNodeAddress(state.customers, action.id, action.newAddress.addressType, {
          addressLine1: action.newAddress.addressLine1,
          addressLine2: action.newAddress.addressLine2,
          postalCode: action.newAddress.postalCode,
          locality: action.newAddress.locality,
        } as Address)
      }
    }
    case 'NODE_RENAMED': {
      return {
        ...state,
        customers: updateNodeName(state.customers, action.id, action.newName)
      }
    }
    default:
      return state
  }
}

export const initialCustomerInvoiceReducer: CustomerInvoiceReducer = {}
export function customer_invoice(state: CustomerInvoiceReducer = initialCustomerInvoiceReducer, action: CustomerActionTypes): CustomerInvoiceReducer {
  switch (action.type) {
    case 'CUSTOMER_INVOICE_FETCHED': {
      return action.data
    }
    default: return state
  }
}


export const initialCustomersFetchingState: FetchingReducer = 'ready'

export function customers_fetching(state: FetchingReducer = initialCustomersFetchingState, action: CustomerActionTypes): FetchingReducer {
  switch (action.type) {
    case 'FETCH_CUSTOMERS':
      return 'loading'
    case 'FETCHING_CUSTOMERS_FAILED':
      return 'failed'
    case 'CUSTOMERS_FETCHED':
      return 'ready'
    default:
      return state
  }
}

export const initialCustomerFetchingState: FetchingReducer = 'ready'
export function customer_fetching(state: FetchingReducer = initialCustomerFetchingState, action: CustomerActionTypes): FetchingReducer {
  switch (action.type) {
    case 'FETCH_CUSTOMER':
      return 'loading'
    case 'FETCHING_CUSTOMER_FAILED':
      return 'failed'
    case 'CUSTOMER_FETCHED':
      return 'ready'
    default:
      return state
  }
}

export const initialCustomerInvoiceFetchingState: FetchingReducer = 'ready'
export function customer_invoice_fetching(state: FetchingReducer = initialCustomerInvoiceFetchingState, action: CustomerActionTypes): FetchingReducer {
  switch (action.type) {
    case 'FETCH_CUSTOMER_INVOICE':
      return 'loading'
    case 'FETCHING_CUSTOMER_INVOICE_FAILED':
      return 'failed'
    case 'CUSTOMER_INVOICE_FETCHED':
      return 'ready'
    default:
      return state
  }
}

export const initialCustomerSavingState: SavingReducer = 'ready'

export function customer_saving(state: SavingReducer = initialCustomerSavingState, action: CustomerActionTypes): SavingReducer {
  switch (action.type) {
    case 'ADD_CUSTOMER':
    case 'ADD_CUSTOMER_SUBNODE':
    case 'ADD_MACHINE_TO_CUSTOMER':
    case 'REMOVE_MACHINE_FROM_NODE':
    case 'REMOVE_CUSTOMER':
    case 'UNHIDE_CUSTOMER':
    case 'REMOVE_ADDRESS_FROM_NODE':
    case 'CHANGE_NODE_ADDRESS':
    case 'RENAME_NODE':
    case "SAVE_PRICE_MODEL":
      return 'saving'
    case 'ADDING_CUSTOMER_FAILED':
    case 'ADDING_CUSTOMER_SUBNODE_FAILED':
    case 'ADDING_MACHINE_TO_CUSTOMER_FAILED':
    case 'REMOVING_MACHINE_FROM_NODE_FAILED':
    case 'REMOVING_CUSTOMER_FAILED':
    case 'UNHIDING_CUSTOMER_FAILED':
    case 'REMOVING_ADDRESS_FROM_NODE_FAILED':
    case 'CHANGING_NODE_ADDRESS_FAILED':
    case 'RENAMING_NODE_FAILED':
    case "SAVING_PRICE_MODEL_FAILED" :
      return 'failed'
    case 'CUSTOMER_ADDED':
    case 'CUSTOMER_SUBNODE_ADDED':
    case 'MACHINE_ADDED_TO_CUSTOMER':
    case 'MACHINE_REMOVED_FROM_NODE':
    case 'CUSTOMER_REMOVED':
    case 'CUSTOMER_UNHIDDEN':
    case 'ADDRESS_REMOVED_FROM_NODE':
    case 'NODE_ADDRESS_CHANGED':
    case 'NODE_RENAMED':
    case 'SET_CUSTOMER_SAVE_REDUCER_READY':
      return 'ready'
    default:
      return state
  }
}
//export type SavingReducer = 'ready' | 'saving' | 'failed' | 'saved'

type Status =  'rejected' | 'pending' | 'fullfilled' 
const initialustomerPriceModel:{
  status: Status | undefined
  model: PriceModelEnvelope[]} = {status: undefined, model:[] }

const sortByDate = (a:PriceModelEnvelope, b:PriceModelEnvelope) => {
  return (DateTime.fromFormat(b?.validFrom, "yyyy-MM").millisecond - DateTime.fromFormat(a?.validFrom, "yyyy-MM").millisecond )
}
export const customers_price_models = createReducer(initialustomerPriceModel, (builder) =>{
  builder.addCase( fetchCustomerPriceModels.fulfilled,  (state, action) => {
    return {status: 'fullfilled', model: action.payload?.items?.sort(sortByDate)}
  }).addCase(removePriceModel.fulfilled, (state, action) =>{
    return {status: 'fullfilled', 
          model: state.model.filter(m => m.validFrom + "-01" !== action.meta.arg.params.validFrom)
        }
  }  ).addCase(savePriceModel.fulfilled, (state, action) =>{
    const newModel = action.meta.arg.model
    state.model.unshift(
      {
        name: "InitialFeeWithPerExecutionThresholds",
        validFrom: newModel.date.toFormat("yyyy-MM"),
        model: JSON.stringify({currency: newModel.currency,  initialFee: newModel.initialCost as number,  
                 thresholds: newModel.threshHolds?.map((t) => {
          return {from: t.trigger as number, perExecution:t.cost as number } 
        }) }) 
      })
    state.status ='fullfilled'
  }).addCase(savePriceModel.rejected, (state, action) =>{
    const newModel = action.meta.arg.model
    state.status = 'rejected'
  })
} )


// TODO: might want to listen to more server messages than we currently do, and display them, rather than generic error messages.
export const initialCustomerSavingErrorState: ErrorReducer = null
export function customer_saving_error(state: ErrorReducer = initialCustomerSavingErrorState, action: CustomerActionTypes): ErrorReducer {
  switch (action.type) {
    case 'ADD_CUSTOMER':
    case 'ADD_CUSTOMER_SUBNODE':
    case 'ADD_MACHINE_TO_CUSTOMER':
    case 'REMOVE_MACHINE_FROM_NODE':
    case 'REMOVE_CUSTOMER':
    case 'UNHIDE_CUSTOMER':
    case 'REMOVE_ADDRESS_FROM_NODE':
    case 'CUSTOMER_ADDED':
    case 'CUSTOMER_SUBNODE_ADDED':
    case 'MACHINE_ADDED_TO_CUSTOMER':
    case 'MACHINE_REMOVED_FROM_NODE':
    case 'CUSTOMER_REMOVED':
    case 'CUSTOMER_UNHIDDEN':
    case 'ADDRESS_REMOVED_FROM_NODE':
    case 'CHANGE_NODE_ADDRESS':
    case "SAVE_PRICE_MODEL":

    case 'ADDING_CUSTOMER_FAILED':
    case 'ADDING_CUSTOMER_SUBNODE_FAILED':
    case 'ADDING_MACHINE_TO_CUSTOMER_FAILED':
    case 'REMOVING_MACHINE_FROM_NODE_FAILED':
    case 'REMOVING_CUSTOMER_FAILED':
    case 'UNHIDING_CUSTOMER_FAILED':
    case "SAVING_PRICE_MODEL_FAILED":
    case 'RENAME_NODE':
    case 'NODE_RENAMED':
      return null
    case 'REMOVING_ADDRESS_FROM_NODE_FAILED':
    case 'CHANGING_NODE_ADDRESS_FAILED':
    case 'RENAMING_NODE_FAILED':
      return action.message
    default:
      return state
  }
}