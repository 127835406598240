import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { DataLoadingText } from '../../components/atoms/text/dataLoadingText'
import LogText, { LogTextObject } from '../../components/molecules/logText'
import TableComponent from '../../components/molecules/table'
import { StoreState } from '../../state/configureStore'
import { fetchMachineLogs } from '../../state/actions/machine'
import { translateColorCode } from '../../utils/text'
import LogLevelDisplay from '../../components/molecules/logs/logLevelDisplay'
import { toReadableTime } from '../../functions'

const columnNames = [
  {
    displayName: 'Level',
    serverName: null//'level'
  },
  {
    displayName: 'Timestamp',
    serverName: 'timestamp'
  },
  {
    displayName: 'Log',
    serverName: null //'record'
  },
  {
    displayName: 'Source',
    serverName: null //'source'
  }
]

function extractTimestamp(text: string): string | null {
  const now = DateTime.now()
  let firstSection = text?.split(']')[0]
  if (!firstSection) return null
  firstSection = firstSection.replace('[', '')
  let dateTime = DateTime.fromFormat(firstSection, 'EEE MMMM  d TT yyyy')
  if (!dateTime || !dateTime.isValid) {
    dateTime = DateTime.fromFormat(firstSection, 'EEE MM  d TT yyyy')
    if (!dateTime || !dateTime.isValid) return null
  }

  const timestamp = toReadableTime(dateTime, now)

  return timestamp
}

function processLogText(text = ''): LogTextObject[] {
  // eslint-disable-next-line
  const regexColorCode = /\x1B\[\d+m/g;

  const colorsIterator = text.matchAll(regexColorCode)
  let textStyles: Object[] = []
  let textPieces = text.split(regexColorCode)

  let nextColor = colorsIterator.next()
  while (nextColor?.value && nextColor?.value[0]) {
    const colorCode = nextColor.value[0].replace('\x1B[', '')

    textStyles.push(translateColorCode(colorCode))
    nextColor = colorsIterator.next()
  }

  if (!textStyles?.length || textStyles.length === 0) {
    return [{
      text: text,
      style: undefined
    }]
  }

  // if we have as many colour codes as split texts -> all of them have their own colour code
  // if we have one less -> first one does not have a colour code -> should render black
  if (textStyles?.length === textPieces?.length - 1) {
    textStyles = [translateColorCode('')].concat(textStyles)
  }
  else if (textStyles?.length !== textPieces?.length) {
    console.error("Something went wrong trying to parse log text:", text)
    return [{
      text: text,
      style: undefined
    }]
  }

  let toReturn: LogTextObject[] = []
  textPieces?.forEach((piece, index) => {
    toReturn.push({
      text: piece,
      style: textStyles[index]
    })
  })

  return toReturn
}

export type SortOn = 'timestamp' // | 'level' | 'record' | 'source'

const LogTable = ({
  fetchMachineLogs,
  serialId,
  machine_logs,
  fetchingState
}) => {
  const now = DateTime.now()

  const [countPerPage, setCountPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [descending, setDescending] = useState(true)
  const [sortingOn, setSortingOn] = useState<SortOn>('timestamp')

  useEffect(() => {
    if (serialId) fetchMachineLogs(serialId, sortingOn, descending, countPerPage, page)
  }, [serialId, countPerPage, page, descending, sortingOn, fetchMachineLogs])

  return (fetchingState !== 'failed' ?
    (fetchingState === 'loading' ? <DataLoadingText showSpinner text={'Loading logs ...'} />
      : (machine_logs && Object.keys(machine_logs)?.find(d => d === serialId))
        ? <TableComponent
          animateTable
          countPerPage={countPerPage}
          page={page}
          descending={descending}
          sortingOn={sortingOn}
          setCountPerPage={setCountPerPage}
          setPage={setPage}
          setDescending={setDescending}
          setSortingOn={setSortingOn}
          columnNames={columnNames}
          rows={(machine_logs[serialId]?.records ?? [])?.map((log, index) => {
            const timestamp = toReadableTime(DateTime.fromISO(log.timestamp), now)
            const timestampFromLog = extractTimestamp(log.record)
            return {
              'id': index,
              'Level': <LogLevelDisplay level={log.level}/>,
              'Timestamp': timestampFromLog ?? timestamp,
              'Log': <LogText textPieces={processLogText(log.record)} />,
              'Source': log.source
            }
          })}
          total={machine_logs[serialId]?.total ?? 0} /> : <DataLoadingText text={'No logs available.'} />)
    : <DataLoadingText text={'Failed to fetch logs from the server.'} />
  )
}
const mapStateToProps = (state: StoreState) => ({
  fetchingState: state.machine_logs_fetching,
  machine_logs: state.machine_logs,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchMachineLogs: (
    serialId: string,
    sortBy: SortOn,
    desc?: boolean,
    countPerPage?: number,
    page?: number,
    nameFilter?: string,
    start?: number,
    end?: number) => dispatch(fetchMachineLogs(serialId, sortBy, desc, countPerPage, page, nameFilter, start, end))
})

export default connect(mapStateToProps, mapDispatchToProps)(LogTable)