import React from "react"
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { IDataItem } from "./types"
import { getAllKeys } from "../../../functions"


const colors: Array<string> = [
    "#01204E",
    "#028391",
    "#F6DCAC",
    "#FEAE6F",
    "#f9b4ab",
    "#264e70",
    "#679186",
    "#bbd4ce",
]

export const BarChartView = ({items, formatX = (s) => s, mobileStyling}:{
    items: Array<IDataItem>
    formatX?: (name:string) => string,
    mobileStyling?: boolean
}) => {
    const allStacks = getAllKeys(items, ['name']).sort()

    return (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={items}
            margin={{top: 20, right: 30, left: 20, bottom: 5}}
          >
            <CartesianGrid stroke={ "#ccc" } strokeDasharray="3" />
            <XAxis dataKey="name" tickFormatter={formatX} />
            <YAxis />
            <Tooltip />
            
            <Legend
              layout={mobileStyling ? "horizontal" : "vertical"}
              verticalAlign="top" align={mobileStyling ? "left" : "right"}
              wrapperStyle={{ paddingLeft: 20, paddingBottom: mobileStyling ? 20 : "auto" }}
            />

            { allStacks.map((r, index) => (
                <Bar
                    key={index}
                    dataKey={r}
                    stackId="a"
                    fill={colors[index] || '#000000'} 
                    />
                ))
                }
          </BarChart>
        </ResponsiveContainer>
    )
}
