import Ajv, { ErrorObject} from 'ajv'
import { CommandBase, Schema } from '../../../common-types/commandingTypes'


const ajv = new Ajv({
    allErrors: true,
})

const schemaUri = (commandName:string) => `${commandName}`
const getOrCreateValidator = <TCommand extends CommandBase>(schema: Schema<TCommand>) => ajv.getSchema(schemaUri(schema.$id)) || ajv.compile(schema)



export const isValid = <TCommand extends CommandBase>(schema: Schema<TCommand>, command: TCommand): boolean => {
    const validator = getOrCreateValidator(schema)
    const valid = validator(command)
    if(!valid)
        console.warn('isValid', validator.errors);

    return !valid ? false : true
}

const haveErrorForProperty = (e: ErrorObject<string, Record<string,any>>, propertyName: string):boolean => {
    if(e.instancePath.endsWith(propertyName))
        return true

    if(e.keyword === 'required' && e.params.missingProperty === propertyName)
        return true

    return false
}

export const isRequired = <T extends CommandBase>(schema: Schema<T>, property: keyof T & string):boolean => {
    const types = schema.properties[property]?.type
    if(types) {
        if(Array.isArray(types)){
            return types.indexOf("null") < 0
        } 
    }
    return true
} 

export const haveErrors = <TCommand extends CommandBase, TKey extends keyof TCommand & string>(schema: Schema<TCommand>, command: TCommand, property: TKey): Array<string> => {
    const validator = getOrCreateValidator(schema)
    if(!validator(command)) {
        return validator.errors?.filter(e => haveErrorForProperty(e, property) && e.message).map(e => e.message!) ?? new Array<string>()
    }
    return new Array<string>()
}


export const doValidateString = (value: any, required: boolean, pattern?: string) => {
    let haveError: boolean = false

    if(required && isNull(value))
        haveError = haveError || true

    if(pattern && !isNull(value) && typeof value === 'string'){
        const regex = new RegExp(pattern)
        haveError = haveError || !regex.test(value)
    }


    return haveError
}


export const isNull = (val: any):boolean => {
    if (val == null)
        return true

    if (val === null)
        return true

    if (typeof val === 'undefined')
        return true

    return false
}

