

export const getAllKeys = <T extends object, K extends keyof T & string>(items: Array<T>, except?: Array<K>) => {
    const keys = Array.from(new Set(items.flatMap( obj => Object.keys(obj))))
    const validateExcepts: Array<string> = except?.map(e => e as string) ?? []

    if(except)
        return keys.filter(k => validateExcepts.indexOf(k) === -1)

    return keys
}

export const getDistinctPropertyValues = <T extends object, K extends keyof T>(array: Array<T>, property: K): Array<T[K]>  => {
    const distinctValues = Array.from(new Set(array.map(item => item[property])))
    return distinctValues as Array<T[K]>
  }
  
