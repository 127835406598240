import React, { Dispatch, useEffect, useState } from "react";

import { ErrorListSmall, IErrorItem } from "../../components/organisms/machine-error";
import { StoreState } from "../../state/configureStore";
import { AnyAction } from "redux";
import { connect } from "react-redux";

const MachineErrorList = ({SerialId, Errors}:{SerialId:string, Errors: Array<IErrorItem>}) => {
    const [currentErrors, setCurrentErrors] = useState<Array<IErrorItem>>([])

    useEffect(() =>{
        if(Errors) {
            const filtered = [...Errors.filter(e => e.serialId === SerialId)]
            console.log('Find for this machine', SerialId, Errors, filtered);
            setCurrentErrors(c => filtered)
        }
    }, [Errors, SerialId])

    return (
        <ErrorListSmall Errors={currentErrors} />
    )
}

const mapStateToProps = (state: StoreState, ownProps: {SerialId:string}) => ({
    Errors: state.machine_errors,
  })
  
  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(MachineErrorList)
