import { CustomerNode } from "../state/reducers/customer"

export const findNodeForId = (customers: CustomerNode[], id: string): CustomerNode | undefined => {
  let found = customers.find(c => c.id === id)
  if (found) {
    return found
  }
  else {
    let i = 0
    while (i < customers?.length && !found) {
      const children = customers[i].childs?.nodes
      if (children && children.length) {
        found = findNodeForId(children, id)
      }
      i++
    }
  }
  return found
}