import React from 'react';
import { Box, Typography } from '@mui/material';
import { padding } from '../../../theme';
import { ContentMenu, ContentMenyProps } from '../menu';

export interface Props {
  title: string,
  titleIcon?: any,
  noPadding?: boolean
  Menu?: ContentMenyProps,
}

export const TextTitle: React.FC<Props> = ({ title, noPadding }) => {
  return (
    <Box sx={{
      display: 'flex',
      paddingBottom: noPadding ? 0 : padding * 0.25
    }}>
      <Typography variant="h6" >{title}</Typography>
    </Box>
  );
}


export const SubpageTitle: React.FC<Props> = ({ title, noPadding, Menu }) => {

  return (
    <Box sx={{
      display: 'flex',
      paddingBottom: noPadding ? 0 : padding * 0.5,
    }}>
      <Typography  sx={{fontFamily: 'VogieBold', display: "block"}} variant="h4" >{title}</Typography>
      { Menu &&
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <ContentMenu Id={Menu.Id} MenuItems={Menu.MenuItems} ButtonTitle={Menu.ButtonTitle} />
        </Box>
      }
    </Box>
  );
}

export const PageHeader: React.FC<Props> = ({ title, titleIcon, noPadding, Menu }) => {
  return (
    <Box sx={{
      display: 'flex',
      marginTop: 1,
      marginBottom: 4,//noPadding ? 0 : padding * 0.5,
      flexDirection: 'row',
      justifyContent: "space-between",
      width: '100%',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {titleIcon}
        {/* <VerticalSpacer size={2}/> */}
        <Typography sx={{fontFamily: 'VogieBold'}} variant="h3" >{title}</Typography>
      </Box>
      { Menu &&
        <Box style={{ display: 'flex', alignItems: 'center', opacity: 1 }}>
          <ContentMenu Id={Menu.Id} MenuItems={Menu.MenuItems} ButtonTitle={Menu.ButtonTitle} />
        </Box>
      }
    </Box>
  );
}