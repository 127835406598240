import { TextField } from '@mui/material'
import React, {  useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AddModal } from '../../components/molecules/AddModal'
import { AddUserType } from '../../state/actionTypes/user'
import { StoreState } from '../../state/configureStore'
import { AnyAction, Dispatch } from 'redux'
import { addUser } from '../../state/actions/user'
import { isValidEmail } from '../../utils/text'
import { fetchCustomers } from '../../state/actions/customer';
import { CheckedItem, CheckedList } from '../../components/molecules/CheckBox';
import {  adminRoles } from '../../utils/auth0Helper';

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
}


function AddUserModal(props: IComponentProps): React.ReactElement {
  useEffect(() => {
    props.fetchCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  const [preparedOrganisations, setPreparedOrganisations] = useState<CheckedItem []>()
  useEffect(() => {
    setPreparedOrganisations(() => props.authenticated_user?.organisationRoles?.filter(o => adminRoles.includes(o.role)  ).map(o => (
      {
        isChecked: false,
        name: props?.customers?.customers?.find(c => c.id === o.orgId)?.name ?? "", 
        id: o.orgId
        })))

    }, [props.customers.customers])

  const [emailError, setEmailError] = useState(false)
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [organisationWarning, setOrganisationWarning] = useState(false)
  
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [organisations, setList]=  useState<CheckedItem[] | undefined>(preparedOrganisations)

  useEffect(() => {
    setList(preparedOrganisations)
  }, [preparedOrganisations])
  
  const onSave = () => {
    const emailValid = email && isValidEmail(email) ? true : false
    if (!emailValid){
       setEmailError(true)
      }
    else {
      setEmailError(false)
    }

    if (!firstName) {
      setFirstNameError(true)
    }
    else {
      setFirstNameError(false)}

    if (!lastName) {
      setLastNameError(true)}
    else {
      setLastNameError(false)}
    if(!organisations?.filter(o => o.isChecked === true).length){
      setOrganisationWarning(true)
    }else {
      setOrganisationWarning(false)
    }
    if (emailValid && firstName && lastName) {
      const name = firstName + ' ' + lastName
      const toSave: AddUserType = {
        id: crypto.randomUUID(),
        email: email,
        givenName: firstName,
        familyName: lastName,
        name: name,
        organisations: organisations?.filter(o => o.isChecked === true).map(o => (
          {
            id: o.id
            }))
    
      }

      props.addUser(toSave)
    }
  }

  const resetModal = () => {
    setEmailError(false)
    setFirstNameError(false)
    setLastNameError(false)

    setEmail('')
    setFirstName('')
    setLastName('')
  }

  return (
    <AddModal
      title={'Add User'}
      saving={props.user_saving}
      open={props.open}
      savingFailedMessage={'The user could not be saved. Please check your inputs below and your internet connection and try again.'}
      closeModal={() => { resetModal(); props.closeModal() }} onSave={onSave}>
      <TextField
        autoFocus
        margin="normal"
        id="email_input_field"
        label="Email Address"
        type="email"
        fullWidth
        onChange={(event) => setEmail(event.target.value)}
        error={emailError}
        helperText={emailError ? "Please enter a valid email address." : null}
      />
      <TextField
        margin="normal"
        id="first_name_input_field"
        label="First Name"
        type="text"
        fullWidth
        onChange={(event) => setFirstName(event.target.value)}
        error={firstNameError}
        helperText={firstNameError ? "Please enter a first name." : null}
      />
      <TextField
        margin="normal"
        id="last_name_input_field"
        label="Last Name"
        type="text"
        fullWidth
        onChange={(event) => setLastName(event.target.value)}
        error={lastNameError}
        helperText={lastNameError ? "Please enter a last name." : null}
      />
      {organisations?.length > 0 ?
      <>
      <h4 style={organisationWarning ? {color: "red", fontWeight: 400}: {}}>{organisationWarning ? "No organisation selected *" : organisations?.length > 1 ? "Organisations" : "Organisation"}</h4>
      { organisations &&
          <CheckedList list={organisations} setList={setList} />
      }
      </>
      : <h4>No organisations to assign</h4>
    }
    </AddModal>
  )
}



const mapStateToProps = (state: StoreState) => ({
  user_saving: state.user_saving,
  authenticated_user: state.authenticated_user,
  customers: state.customers
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  addUser: (user: AddUserType) => dispatch(addUser(user)),
  fetchCustomers: () => dispatch(fetchCustomers()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal)