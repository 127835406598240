import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function LoginCallback() {
  const navigate = useNavigate()

  useEffect(() => {
    // don't go back to logout or empty login page, but go back to any other page we may have come from
    const goToDefaultPage = window.location.pathname === "/logout" || window.location.pathname === "/login_success"

    if (goToDefaultPage) navigate('/')
    else navigate(window.location.pathname)
  }, [navigate])

  return <></>
}
