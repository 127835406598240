export enum KnownErrorCodes  {
    undefined,
    UnknownError,
    PumpLiquidEmpty,
    CanNotResetPlate,
} 

export const numberToKnownError = (code: number) : KnownErrorCodes => {
    switch(code) {
        case -1:
            return KnownErrorCodes.UnknownError
        case 201:
            return KnownErrorCodes.PumpLiquidEmpty
        case 301:
            return KnownErrorCodes.CanNotResetPlate
        default:
            return KnownErrorCodes.undefined
    }
} 



