import React, { useEffect, useState } from "react"
import { Box, Button, TextField } from "@mui/material"

import { EventTypeSelector } from "./eventTypeSelector";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

type Properties = {
    EventTypes: string[],
    onAdd: (eventType:string, email:string) => void,
}

export const AddSubscription:React.FC<Properties> =
({
    EventTypes,
    onAdd
}) => {
    const [email, setEmail] = useState<string | undefined>();
    const [eventType, setEventType] = useState<string | undefined>();
    const [clickEnabled, setClickEnabled] = useState<boolean>(false)

    useEffect(() =>{
        if(email && eventType){
            setClickEnabled(true)
        } else {
            setClickEnabled(false)
        }
    },[email, eventType])

    const add = () => {
        if(email && eventType) {
            onAdd(eventType, email)
            setEmail(undefined)
        }
    }

    return(
    <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid xs={4}>
                <TextField
                    autoFocus
                    id="email_input_field"
                    label="Email"
                    type="email"
                    fullWidth
                    onChange={(event) => setEmail(event.target.value)}
                />
            </Grid>
            <Grid xs={4}>
                <EventTypeSelector AvailableTypes={EventTypes} onSelect={setEventType} />
            </Grid>
            <Grid xs={2}>
                <Button variant="contained" disabled={!clickEnabled} onClick={() => add()}>Add</Button>
            </Grid>
        </Grid>
    </Box>
    )
}

