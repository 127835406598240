import { IconButton } from "@mui/material"
import RequestQuote from '@mui/icons-material/RequestQuote';
import React, { useState } from "react"
import { useCommandBackend } from "../../../../utils/requestHelpers";
import { InitialFeeWithPerExecutionThresholdsCommand, PriceModelEnvelope } from "../types";
import { InitalFeeWithPerExecutionEditCommmandModal } from "./commandModal";
import { CommandWithSchema } from "../../../../common-types/commandingTypes";


export const InitialFeeWithPerExecutionThresholdsAddButton = ({command, onAdd = () => {}}:{command: CommandWithSchema<InitialFeeWithPerExecutionThresholdsCommand> | undefined, onAdd?: (model: PriceModelEnvelope) => void}) => {
    const commandApi = useCommandBackend("Backoffice")
    const [showDialog, setShowDialog] = useState<boolean>(false)


    const savePriceModel = (command:InitialFeeWithPerExecutionThresholdsCommand) => {
        commandApi.PostCommand(`/cmd/node/price-model/add/initialfeewithperexecutionthresholds`, command)
            .Data(result => {
                setShowDialog(false)
                onAdd({...result.command,
                    nodeId: result.command.id,
                    validFrom: monthOnly(result.command.validFrom), 
                    name: 'InitialFeeWithPerExecutionThresholds', 
                    model: result.command})
            })
    }

    return (
        <>
            <IconButton
                title="New prices"
                disabled={!command}
                onClick={() => setShowDialog(command !== undefined)}
            >
                <RequestQuote />
            </IconButton>
            {showDialog && <InitalFeeWithPerExecutionEditCommmandModal command={command!} onClose={() => setShowDialog(false)} onSave={savePriceModel} />}
        </>
    )
}

const monthOnly = (dateLike: string | undefined | null):string => {
    if(dateLike) {
        const dateNumber = Date.parse(dateLike)
        if(dateNumber) {
            const date = new Date(dateNumber)
            const month = date.getMonth() + 1
            const monthString = month.toString().padStart(2, "0")
            return `${date.getFullYear()}-${monthString}`
        }   
    }
    return ""
}