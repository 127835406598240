import { takeEvery } from 'redux-saga/effects'
import { fetchUsers } from '../../utils/request'
import { postRemoveUser, postUser, postUpdatedUser, assignOrganisations } from '../../utils/commands'
import { FETCH_USERS, ADD_USER, REMOVE_USER, UPDATE_USER, ASSIGN_ORGANISATIONS } from '../actionTypes/user'


export function* userSagas() {
  yield takeEvery(FETCH_USERS, fetchUsers)
  yield takeEvery(ADD_USER, postUser)
  yield takeEvery(REMOVE_USER, postRemoveUser)
  yield takeEvery(UPDATE_USER, postUpdatedUser)
  yield takeEvery(ASSIGN_ORGANISATIONS, assignOrganisations)
}