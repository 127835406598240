import { CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'

export interface Props {
  text?: string,
  showSpinner?: boolean,
}

export const DataLoadingText: React.FC<Props> = ({ text, showSpinner = false }) => {
  return (
    <Box style={{ 
      paddingLeft: '1%', 
      paddingBottom: '2%', 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'left',
    }} >
      {showSpinner ? <CircularProgress style={{ marginRight: 10 }} size={25} color='primary' /> : null}
      <Typography variant="body1">{text}</Typography>
    </Box>
  )
}