import React, { ReactNode } from "react"


export const FlexContainerRow = ({children}:{children?: ReactNode}) => {

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            {children}
        </div>
    )
}