import { DateTime } from 'luxon'
import { Action } from 'redux'
import { CustomerState, CustomerNode, Invoice } from '../reducers/customer'
import { MachineState } from '../reducers/machine'

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS'
export interface FetchCustomers extends Action {
  type: typeof FETCH_CUSTOMERS
}

interface CustomersFetched extends Action {
  type: 'CUSTOMERS_FETCHED',
  data: CustomerState
}

interface FetchingCustomersFailed extends Action {
  type: 'FETCHING_CUSTOMERS_FAILED'
  status: number
  statusText: string
}

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER'
export interface FetchCustomer extends Action {
  type: typeof FETCH_CUSTOMER
  id: string
}

interface CustomerFetched extends Action {
  type: 'CUSTOMER_FETCHED',
  data: CustomerNode
}

interface FetchingCustomerFailed extends Action {
  type: 'FETCHING_CUSTOMER_FAILED'
  status: number
  statusText: string
}



export type AddCustomerType = {
  id: string,
  name: string,
  invoice_AddressLine1: string,
  invoice_AddressLine2: string | null,
  invoice_PostalCode: string,
  invoice_locality: string,
  visiting_AddressLine1: string | null,
  visiting_AddressLine2: string | null,
  visiting_PostalCode: string | null,
  visiting_Locality: string | null,
  // locationLongitude: number,
  // locationLatitude: number

}

export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export interface AddCustomer extends Action {
  type: typeof ADD_CUSTOMER,
  customer: AddCustomerType
}

interface CustomerAdded extends Action {
  type: 'CUSTOMER_ADDED',
  customer: AddCustomerType,
  id: string
}

interface AddingCustomerFailed extends Action {
  type: 'ADDING_CUSTOMER_FAILED',
  customer: AddCustomerType
}

export type AddCustomerSubnodeType = {
  parentId: string,
  name: string,
  invoice_AddressLine1: string | null,
  invoice_AddressLine2: string | null,
  invoice_PostalCode: string | null,
  invoice_Locality: string | null,
  visiting_AddressLine1: string,
  visiting_AddressLine2: string | null,
  visiting_PostalCode: string,
  visiting_Locality: string,
//   location_Longitude: number,
//   location_Latitude: number,
}

export const ADD_CUSTOMER_SUBNODE = 'ADD_CUSTOMER_SUBNODE'
export interface AddCustomerSubnode extends Action {
  type: typeof ADD_CUSTOMER_SUBNODE,
  customerSubnode: AddCustomerSubnodeType
}

interface CustomerSubnodeAdded extends Action {
  type: 'CUSTOMER_SUBNODE_ADDED',
  customerSubnode: AddCustomerSubnodeType,
  id: string
}

interface AddingCustomerSubnodeFailed extends Action {
  type: 'ADDING_CUSTOMER_SUBNODE_FAILED',
  customerSubnode: AddCustomerSubnodeType
}

export type AddMachineToCustomerType = {
  customerId: string,
  machine: MachineState
}

export const ADD_MACHINE_TO_CUSTOMER = 'ADD_MACHINE_TO_CUSTOMER'
export interface AddMachineToCustomer extends Action {
  type: typeof ADD_MACHINE_TO_CUSTOMER,
  data: AddMachineToCustomerType
}

interface MachineAddedToCustomer extends Action {
  type: 'MACHINE_ADDED_TO_CUSTOMER',
  data: AddMachineToCustomerType,
}

interface AddingMachineToCustomerFailed extends Action {
  type: 'ADDING_MACHINE_TO_CUSTOMER_FAILED',
  data: AddMachineToCustomerType
}

export const REMOVE_MACHINE_FROM_NODE = 'REMOVE_MACHINE_FROM_NODE'
export interface RemoveMachineFromNode extends Action {
  type: typeof REMOVE_MACHINE_FROM_NODE,
  serialId: string,
  nodeId: string
}

interface MachineRemovedFromNode extends Action {
  type: 'MACHINE_REMOVED_FROM_NODE'
  serialId: string,
  nodeId: string
}

interface RemovingMachineFromNodeFailed extends Action {
  type: 'REMOVING_MACHINE_FROM_NODE_FAILED',
  serialId: string,
  nodeId: string
}

export const FETCH_CUSTOMER_INVOICE = 'FETCH_CUSTOMER_INVOICE'
export interface FetchCustomerInvoice extends Action {
  type: typeof FETCH_CUSTOMER_INVOICE,
  nodeId: string,
  from: DateTime,
  to: DateTime
}

interface CustomerInvoiceFetched extends Action {
  type: 'CUSTOMER_INVOICE_FETCHED',
  data: Invoice
}

interface FetchingCustomerInvoiceFailed extends Action {
  type: 'FETCHING_CUSTOMER_INVOICE_FAILED'
  nodeId: string,
  from: DateTime,
  to: DateTime
}

export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER'
export interface RemoveCustomer extends Action {
  type: typeof REMOVE_CUSTOMER,
  nodeId: string,
  reason?: string
}

interface CustomerRemoved extends Action {
  type: 'CUSTOMER_REMOVED',
  reason?: string,
  nodeId: string
}

interface RemovingCustomerFailed extends Action {
  type: 'REMOVING_CUSTOMER_FAILED'
  nodeId: string,
}

export const UNHIDE_CUSTOMER = 'UNHIDE_CUSTOMER'
export interface UnhideCustomer extends Action {
  type: typeof UNHIDE_CUSTOMER,
  nodeId: string,
  reason?: string
}

interface CustomerUnhidden extends Action {
  type: 'CUSTOMER_UNHIDDEN',
  reason?: string,
  nodeId: string
}

interface UnhidingCustomerFailed extends Action {
  type: 'UNHIDING_CUSTOMER_FAILED'
  nodeId: string,
}

export const REMOVE_ADDRESS_FROM_NODE = 'REMOVE_ADDRESS_FROM_NODE'
export interface RemoveAddressFromNode extends Action {
  type: typeof REMOVE_ADDRESS_FROM_NODE,
  nodeId: string,
  addressType?: 'invoice' | 'visiting'
}

interface AddressRemovedFromNode extends Action {
  type: 'ADDRESS_REMOVED_FROM_NODE',
  nodeId: string,
  addressType: 'invoice' | 'visiting'
}

interface RemovingAddressFromNodeFailed extends Action {
  type: 'REMOVING_ADDRESS_FROM_NODE_FAILED'
  nodeId: string,
  addressType?: 'invoice' | 'visiting',
  message: string
}


export type NodeAddressType = {
  addressType: 'invoice' | 'visiting',
  addressLine1: string,
  addressLine2?: string,
  postalCode: string,
  locality?: string

}
export const CHANGE_NODE_ADDRESS = 'CHANGE_NODE_ADDRESS'
export interface ChangeNodeAddress extends Action {
  type: typeof CHANGE_NODE_ADDRESS,
  id: string,
  newAddress: NodeAddressType
}

interface NodeAddressChanged extends Action {
  type: 'NODE_ADDRESS_CHANGED',
  id: string,
  newAddress: NodeAddressType
}

interface ChangingNodeAddressFailed extends Action {
  type: 'CHANGING_NODE_ADDRESS_FAILED'
  id: string,
  newAddress: NodeAddressType
  message: string | null
}

export const RENAME_NODE = 'RENAME_NODE'
export interface RenameNode extends Action {
  type: typeof RENAME_NODE,
  id: string,
  newName: string
}

interface NodeRenamed extends Action {
  type: 'NODE_RENAMED',
  id: string,
  newName: string
}

interface RenamingNodeFailed extends Action {
  type: 'RENAMING_NODE_FAILED'
  id: string,
  newName: string
  message: string | null
}
export const SAVE_PRICE_MODEL = "SAVE_PRICE_MODEL"
interface SavePriceModel extends Action{
  type: "SAVE_PRICE_MODEL",
}

export const SAVING_PRICE_MODEL_FAILED = 'SAVING_PRICE_MODEL_FAILED'
interface SavingPriceModelFailed extends Action{
  type: 'SAVING_PRICE_MODEL_FAILED'
}
export const  SET_CUSTOMER_SAVE_REDUCER_READY = 'SET_CUSTOMER_SAVE_REDUCER_READY'
interface SetSavingCustomerReducerReady extends Action {
  type: 'SET_CUSTOMER_SAVE_REDUCER_READY'
}

export const CUSTOMER_UP_TO_DATE = 'CUSTOMER_UP_TO_DATE'
interface CustomerUpToDate extends Action {
  type: 'CUSTOMER_UP_TO_DATE'
}


export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED'
interface CustomerUdated extends Action {
  type: 'CUSTOMER_UPDATED'
}
export const PRICE_MODELS_FETCHED = 'PRICE_MODELS_FETCHED'
export const FETCH_CUSTOMER_PRICE_MODELS = 'FETCH_CUSTOMER_PRICE_MODELS'
interface FetchCustomerPriceModels extends Action {
  type: "FETCH_CUSTOMER_PRICE_MODELS",
  customerId: string
}

export const REMOVE_CUSTOMER_PRICE_MODEL = 'REMOVE_CUSTOMER_PRICE_MODEL'
interface RemoveCustomerPriceModel extends Action {
  type: "REMOVE_CUSTOMER_PRICE_MODEL",
  params: {
    validFrom: string, 
    customerId: string
  } 
}

export const FETCH_CUSTOMER_NODES = "FETCH_CUSTOMER_NODES"
interface FetchCustomerNodes extends Action {
  type:  "FETCH_CUSTOMER_NODES"
}
export type CustomerActionTypes =
  | FetchCustomers        | CustomersFetched              | FetchingCustomersFailed
  | FetchCustomer         | CustomerFetched               | FetchingCustomerFailed
  | AddCustomer           | CustomerAdded                 | AddingCustomerFailed
  | AddCustomerSubnode    | CustomerSubnodeAdded          | AddingCustomerSubnodeFailed
  | AddMachineToCustomer   | MachineAddedToCustomer         | AddingMachineToCustomerFailed
  | RemoveMachineFromNode  | RemovingMachineFromNodeFailed  | MachineRemovedFromNode
  | FetchCustomerInvoice  | CustomerInvoiceFetched        | FetchingCustomerInvoiceFailed
  | RemoveCustomer        | CustomerRemoved               | RemovingCustomerFailed
  | UnhideCustomer        | CustomerUnhidden              | UnhidingCustomerFailed
  | RemoveAddressFromNode | AddressRemovedFromNode        | RemovingAddressFromNodeFailed
  | ChangeNodeAddress     | NodeAddressChanged            | ChangingNodeAddressFailed
  | RenameNode            | NodeRenamed                   | RenamingNodeFailed
  | SavePriceModel        | SavingPriceModelFailed        | SetSavingCustomerReducerReady 
  | CustomerUpToDate      | CustomerUdated                | FetchCustomerPriceModels 
  | RemoveCustomerPriceModel | FetchCustomerNodes