import { TextField } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { AddModal, Subheader } from '../../components/molecules/AddModal'
import { StoreState } from '../../state/configureStore'
import { AnyAction, Dispatch } from 'redux'
import { MachineState } from '../../state/reducers/machine'
import { renameMachine } from '../../state/actions/machine'

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  machine: MachineState
}

function EditMachineModal(props: IComponentProps): React.ReactElement {
  const [nameError, setNameError] = useState(false)

  const [name, setName] = useState(props.machine.name)

  const onSave = () => {
    if (!name) setNameError(true)
    else setNameError(false)

    if (name) {      
      props.renameMachine(props.machine.serialId, name)
    }
  }

  const resetModal = () => {
    setNameError(false)

    setName('')
  }

  return (
    <AddModal
      title={'Edit machine'}
      saving={props.machine_saving}
      open={props.open}
      savingFailedMessage={'Saving failed. Please check your inputs below and your internet connection and try again.'}
      closeModal={() => { resetModal(); props.closeModal() }} onSave={onSave}>

      <Subheader title={'Machine Name'}/>
      <TextField
        autoFocus
        margin="normal"
        id="name_input_field"
        label="Machine Name"
        defaultValue={props.machine.name}
        //placeholder={props.machine.name}
        type="text"
        fullWidth
        onChange={(event) => setName(event.target.value)}
        error={nameError}
        helperText={nameError ? "Please enter a name." : null}
      />
    </AddModal>
  )
}

const mapStateToProps = (state: StoreState) => ({
  machine_saving: state.machine_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  renameMachine: (id: string, name: string) => dispatch(renameMachine(id, name))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditMachineModal)