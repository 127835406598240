import React, { useState } from "react"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"


type Properties = {
    AvailableTypes: string[],
    Value?: string | undefined,
    onSelect: (value:string | undefined) => void
}

export const EventTypeSelector = ({AvailableTypes, Value, onSelect}:Properties): JSX.Element => {
    const [value, setValue] = useState<string>(Value ?? '')
    
    const selectValue = (event: SelectChangeEvent) => {
        const val = event.target.value as string
        console.log(val)
        setValue(val)
        onSelect(val)
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="event-label">Event</InputLabel>
            <Select 
                labelId="event-label"
                type="text"
                onChange={selectValue}
                value={value}
                >
                {AvailableTypes.map((e, idx) => <MenuItem key={idx} value={e}>{e}</MenuItem>)}
            </Select>
        </FormControl>
        )
}