
import React, { Fragment } from 'react'
import { Box } from '@mui/system';
import { Paper, Tab, Tabs } from '@mui/material';
import { PageHeader } from '../atoms/text/titles';
import { HorizontalSpacer, VerticalSpacer } from '../atoms/spacer';
import { SimpleBreadcrumbs } from './breadcrumbs';
import { padding } from '../../theme';
import { ContentMenyProps } from '../atoms/menu';

export type BreadcrumbNames = { id: string, name: string, hidden: boolean }[]
export type TabPage = {
  name: string,
  pageSections: React.ReactElement[],
  icon: React.ReactElement,
}

export type PageProps = {
  greyOut?: boolean,
  fixedHeight?: boolean,
  fullView?: boolean,
  title?: string,
  titleIcon?: any,
  pageSections?: React.ReactElement[],
  children?: React.ReactNode,
  breadcrumbNames?: BreadcrumbNames,
  hideBreadcrumbs?: boolean,
  Menu?: ContentMenyProps,
  actionTitle?: string,
  actionIcon?: any,
  onActionClick?: (element:HTMLElement) => void,
  tabs?: TabPage[],
  horizontal?: boolean,
  pageSectionRatio?: number[]
}

function Page({
  greyOut=false,
  fixedHeight=false,
  fullView,
  title,
  titleIcon,
  pageSections,
  children,
  breadcrumbNames,
  hideBreadcrumbs = false,
  tabs,
  horizontal = false,
  Menu,
  pageSectionRatio // only implemented for horizontal layout for now
}: PageProps): React.ReactElement {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const styles = {
    pageSection: {
      padding: padding * 0.5,
      paddingTop: padding * 0.75,
      paddingBottom: padding * 0.5,
      marginBottom: padding * 0.2,
      // overflow: fullView ? 'hidden' : 'scroll',
    }
  }

  const pagePadding = '90px 4% 0% 4%'
  return (
    <Box component="main" sx={{
      ...{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: fullView ? 0 : pagePadding,
       // paddingTop: 10,
        opacity: greyOut ? 0.75 : 1,
        overflow: fullView ? 'clip' : 'visible',
      }, ...(fixedHeight ? { maxHeight: '90%' } : {})
    }}>
      {!hideBreadcrumbs ? <SimpleBreadcrumbs breadcrumbNames={breadcrumbNames} /> : null}
      {(title) ? <PageHeader titleIcon={titleIcon} title={title} Menu={Menu} /> : null}
      {
        tabs ?
          <React.Fragment>
            <Paper elevation={1} sx={styles.pageSection}>
              <Tabs sx={{ paddingBottom: padding }} value={tabValue} onChange={handleTabChange} aria-label="icon label tabs">
                {tabs?.map((t, index) => { return <Tab key={'tab_' + index} label={t.name} /> })}
              </Tabs>
              {buildPageSections(styles.pageSection, tabs[tabValue].pageSections ?? [])}
            </Paper>
          </React.Fragment>
          : buildPageSections(styles.pageSection, pageSections ?? [], title, horizontal, pageSectionRatio)
      }
      {children}
    </Box>
  )
}


const pageSectionStyle = {
  display: 'flex',
  flexDirection: 'column',
}
const pageSectionStyle_horizontal = {
  height: '100%',
  maxHeight: '100%',
  maxWidth: '100%',
  flexDirection: 'row'
}
function buildPageSections(style: object, pageSections: any[], pageTitle: string = '', horizontal?: boolean, pageSectionRatio?: number[]) {
  return <Box sx={{ ...pageSectionStyle, ...(horizontal ? pageSectionStyle_horizontal : {}) }}>
    {pageSections?.length ? pageSections.map((section, index) => {
      return (
        <Fragment key={'pageSection' + index}>
          {/* {(index === 0 && pageTitle) ? <PageHeader title={pageTitle} actionTitle={actionTitle} actionIcon={actionIcon} onActionClick={onActionClick} /> : null} */}
          <Paper elevation={1} sx={horizontal ? {
            ...style,
            flex: pageSectionRatio ? pageSectionRatio[index] : 1,
            paddingTop: 'auto',
            maxHeight: '100%',
          } : style}>
            <Fragment key={index}>
              {section}
            </Fragment>
          </Paper>
          {(index < pageSections.length - 1) ?
            (horizontal ? <VerticalSpacer key={'spacer' + index} size={1} /> : <HorizontalSpacer key={'spacer' + index} size={0.5} />)
            : null
          }
        </Fragment>
      )
    }) : null}
    {pageSections?.length && !horizontal ? <HorizontalSpacer size={5} /> : null}
  </Box>
}

export default Page